@import '../../../base';
.modal-container.colleagues {
  position: relative !important;
  max-height: 90vh;
  .modal-colleagues-container {
    height: 100%;
    max-height: calc(90vh - 40px);
    width: 100%;
    overflow: hidden;
  }
  .modal-colleagues-header {
    justify-content: space-between;
    h2 {
      font-size: 1.37rem;
      line-height: 1.625rem;
      font-weight: 500;
    }
    .icon-container {
      transform: rotate(45deg);
    }
  }
  .text-input-container,
  .text-input {
    flex: none;
    width: 60%;
  }
  .modal-colleagues-content {
    max-height: calc(90vh - 160px);
    margin-top: 20px;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .single-user-container {
      margin-bottom: 25px;
      align-items: stretch;
      > div {
        padding: 10px 20px;
        justify-content: center;
        min-height: 100px;
        height: 100%;
        flex: 2;
        p {
          margin: 4px;
        }

        .icon {
          height: 24px;
          width: 24px;
          margin-right: 5px;
        }

        .email,
        .phoneNumber,
        .birthday,
        .description {
          flex: 1;
          padding: 5px 0;
        }
        .description {
          font-size: 0.875rem;
          line-height: 1.375rem;
        }
        &:first-child {
          padding-left: 0;
        }
        &:not(:last-child) {
          flex: 1;
          border-right: 1px solid $color-gray;
        }
        &.col-main {
          .profile-image-container {
            position: relative;
            width: 65px;
            height: 65px;
            margin-right: 20px;
            .profile-status {
              position: absolute;
              bottom: 0px;
              right: 0px;
              width: 20px;
              height: 20px;
              background-color: white;
              border-radius: 50%;
            }
          }
          .fullName {
            font-size: 1.12rem;
            line-height: 1.5rem;
            font-weight: 700;
          }
        }
      }
    }
  }
}
