@import "../../../base";
.inputs-comments-container {
  // height: 100%;
  height: calc(100% - 5px);
  justify-content: flex-end;
  .comments-scroll-container {
    height: 90%;
    padding-bottom: 10px;
    overflow: scroll;
    margin-top: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .single-comment-container {
      .profile-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 30px;
        width: 30px;
        border-radius: 50%;
      }
      .member-fullName {
        margin-left: 10px;
        font-weight: 500;
      }
      p {
        font-size: 0.875rem;
        line-height: 1.125rem;
        white-space: pre-line;
      }
      .icon.icon-delete {
        margin-left: 5px;
        height: 20px;
        width: 20px;
      }
      margin-top: 20px;
      &:last-child {
        margin-bottom: 20px;
      }
      .row-details {
        justify-content: space-between;
        padding: 0 24px;
      }
      .row-text {
        margin: 0 24px;
        padding: 5px 10px;
        @include shadow-small;
        border-radius: 6px;
      }
    }
  }

  .comment-input {
    height: 10%;
    border-top: 1px solid grey;
    margin-top: 10px;
    min-height: 50px;
    .text-input-outer-container {
      position: relative;
      flex: 1;
      .icon {
        position: absolute;
        top: 10px;
        right: 0px;
        height: 38px;
        width: 38px;
        background-color: $color-orange;
        border-radius: 6px;
        background-size: 70%;
        transform-origin: center;
      }
    }
  }
}
