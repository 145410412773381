@import '../../base';

.screen-employee-container {
  height: calc(100% - 53px);
  justify-content: space-between;
  align-items: flex-start;
  padding: 1vw 2vw;
  > div {
    width: calc(92vw / 3);
    height: 100%;
  }
  .screen-employee-middle-content {
    height: calc(100% - 42px);
  }

  .screen-employee-left,
  .screen-employee-right,
  .screen-employee-middle-content {
    @include card;
  }

  .screen-employee-middle {
    .screen-employee-middle-navigation {
      overflow-y: hidden;
      .single-tab {
        @include card($border-radius: 6px 6px 0 0);
        cursor: pointer;
        padding: 10px 15px;
        opacity: 0.6;
        transition: 0.3s ease;
        &.selected {
          opacity: 1;
          z-index: 2;
          font-weight: 700;
        }
      }
    }
    .screen-employee-middle-content {
      padding: 10px 12px;
      overflow: scroll;
    }
  }
}
