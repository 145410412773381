@import "../../base";
.screen-mailer-container {
    height: calc(100% - 53px - 2vw);
    margin: 1vw 2vw;
    @include card;
    .screen-mailer-header {
        z-index: 2;
        @include card($background: $color-cream);
        padding: 0 24px;
        height: 60px;
        h2 {
            margin: 0;
            font-size: 1rem;
            line-height: 1.25rem;
            font-weight: 500;
            margin-right: 15px;
        }
        .row-buttons {
            flex: 1;
            justify-content: flex-end;
            .raised-button-container {
                margin-left: 15px;
                flex: none;
                &.btn-test {
                    background-color: $color-whiteish;
                    color: $color-text;
                }
            }
            .image-upload-input {
                margin-left: 15px;
                padding: 0;
                background-color: transparent;
                box-shadow: none;
            }
            .icon.icon-export {
                height: 38px;
                width: 38px;
                border: 2px solid $color-blue-dark;
                border-radius: 6px;
                background-size: 65%;
            }
            .icon.icon-export.export:first-child {
                transform: rotate(180deg);
            }
        }
    }
    .screen-mailer-content {
        height: calc(100% - 60px);
        .instructions {
            z-index: 3;
            @include card;
            padding: 15px 0;
            align-items: center;
        }
        .editor {
            padding: 10px 0;
            z-index: 2;
            height: 367px;
            @include card;
            padding-left: 24px;
        }
        .excel-preview {
            height: calc(100% - 367px - 200px);
        }
    }
}
