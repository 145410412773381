@import "base";

.screen-notifications-container {
    height: calc(100% - 53px);
    padding: 1vw 2vw;
    .screen-notifications-inner-container {
        @include card;
        height: 100%;
        .screen-notifications-header {
            padding: 0 24px;
            height: 72px;
            .row-buttons {
                margin-left: auto;
                .raised-button-container {
                    flex: none;
                    font-size: 0.81rem;
                    &.btn-cancel {
                        background: $color-white;
                        color: $color-text;
                        border: 1px solid $color-blue-dark;
                        margin-right: 15px;
                    }
                    &.btn-other-actions {
                        background-color: $color-cream;
                        color: $color-text;
                        margin-right: 15px;
                    }
                }
                .image-upload-input {
                    background-color: $color-orange;
                    color: $color-white;
                    font-size: 0.81rem;
                }
                .icon-export {
                    height: 34px;
                    width: 34px;
                    border: 2px solid $color-blue-dark;
                    background-color: $color-white;
                    border-radius: 6px;
                    background-size: 70%;
                    margin-right: 15px;
                }
                .inputs-datepicker-container {
                    margin: 0 15px;
                }
                .inputs-text-labeled-container {
                    margin: 0 15px;
                }
            }
        }
        .screen-notifications-content {
            height: calc(100% - 72px - 32px);
            width: 100%;
            .table-container {
                .table-row-cell.newProduct,
                .table-row-cell.base,
                .table-row-cell.market,
                .table-row-cell.night,
                .table-row-cell.period,
                .table-row-cell.difference {
                    overflow: hidden;
                    .inputs-text-edit-container,
                    .inputs-text-labeled-container,
                    .inputs-text-labeled-edit-container {
                        max-width: calc(100% - 15px);
                        input,
                        .react-popper-dropdown {
                            height: 25px;
                            width: 100%;
                        }
                    }
                    .inputs-text-labeled-container span,
                    .inputs-text-labeled-edit-container .row-buttons {
                        top: 14px;
                    }
                    .inputs-text-labeled-edit-container.edit .row-buttons {
                        top: 12px;
                    }
                }
                .table-row-cell.emailDate .row-invoiceSend {
                    width: 100%;
                    .icon.icon-info-custom {
                        margin-left: auto;
                        margin-right: 14px;
                        height: 20px;
                        width: 20px;
                    }
                }
                .table-row-inner-container .table-row-cell:last-child > div {
                    width: calc(100% - 45px);
                }
                .table-row-cell.responsibleForRegistration > div {
                    width: calc(100% - 15px);
                    justify-content: space-between;
                    .icon-edit {
                        height: 22px;
                        width: 22px;
                    }
                }
            }
        }
        .screen-notifications-footer {
            @include card($background: #f7f7f7);
            height: 32px;
            overflow: hidden;
            .screen-notifications-inner-footer {
                border-radius: 6px;
                background: $color-blue-dark;
                padding: 0 1vw;
                p {
                    color: $color-white;
                    font-size: 1.125rem;
                    line-height: 22px;
                    margin: 5px 1vw 5px 0;
                    &:not(:last-child) {
                        padding-right: 1vw;
                        border-right: 1px solid $color-white;
                    }
                }
            }
        }
    }
}
