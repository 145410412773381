@import "../../base";
.screen-producers-references-generate-container {
    height: calc(100% - 53px);
    padding: 1vw 2vw;
    .screen-producers-references-generate-header {
        @include card($border-radius: 6px);
        height: 52px;
        padding: 0 2vw;
        margin-bottom: 1vw;
        h2 {
            font-size: 1.37rem;
            line-height: 2rem;
            font-weight: 700;
            margin: 0;
            margin-right: 30px;
        }
        p {
            margin-right: 15px;
            span {
                font-weight: 700;
            }
        }
        .icon {
            display: block;
            height: 15px;
            width: 15px;
        }
        .row-buttons {
            margin-left: auto;
        }
    }
    .screen-producers-references-generate-content {
        height: calc(100% - 52px - 1vw - 52px);
        @include card;
        overflow: hidden;
        .table-container {
            .row.button {
                padding-right: 15px;
                .raised-button-container {
                    height: 25px;
                }
            }
        }
    }
    .screen-producers-references-generate-footer {
        @include card($background: #f7f7f7);
        height: 52px;
        overflow: hidden;
        > div {
            cursor: pointer;
            height: 100%;
            padding: 10px 15px;
            background-color: $color-blue-dark;
            &:not(:last-child) {
                border-right: 1px solid gray;
            }
            .icon-arrow-right-2-white {
                height: 20px;
                width: 20px;
                min-width: 0;
                margin-left: 5px;
                cursor: default;
            }
            p {
                font-size: 1.125rem;
                margin: 0;
                color: $color-white;
                line-height: 32px;
                span {
                    font-weight: 700;
                }
            }
        }
    }
}
