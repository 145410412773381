.task-management-form-container {
    padding: 15px;
    height: 100%;
    width: 100%;
    .task-management-form-header {
        height: 55px;
        margin-bottom: 1vw;
        padding: 0 16px;
        h2 {
            font-size: 22px;
            line-height: 26px;
            font-weight: 700;
        }
        .icon-delete {
            margin-left: 30px;
            height: 25px;
            width: 25px;
        }
        .raised-button-container {
            flex: none;
            margin-left: auto;
        }
    }
    .task-management-form-content {
        height: calc(100% - 1vw - 50px);
        h3 {
            margin: 0;
            font-size: 20px;
            line-height: 26px;
            font-weight: 700;
        }
        .task-management-form-general,
        .task-management-form-description {
            padding: 8px 16px;
        }
        .task-management-form-general {
            z-index: 2;
            > .row {
                margin: 10px 0;
                .col {
                    width: calc(100% / 7 - 15px);
                    margin-right: 15px;
                    &.col-full {
                        width: calc(100% - 15px);
                    }
                    &.col-medium {
                        width: calc(100% / 7 * 2 - 15px);
                    }
                    &.col-big {
                        width: calc(100% / 7 * 3 - 15px);
                    }
                    .required {
                        box-shadow: 0 0 3px #ff7655;
                        border-color: #ffffff;
                    }
                }
            }

            .row {
                .tasks-icon-plus {
                    margin: 15px 0;
                }
                .assignees-container {
                    overflow: auto;
                    margin-left: 15px;
                    .single-assignee {
                        margin-right: 15px;
                        .assignee-fullName {
                            margin-left: 5px;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
        .task-management-form-description {
            overflow: auto;
            h3 {
                margin-bottom: 10px;
            }
        }
    }
}

[role="tooltip"].popup-content.task-management-popup-assignees-outer-container-content {
    width: 300px;
}

.task-management-popup-assignees-container {
    padding: 4px 8px;
    .task-management-popup-assignees-header {
        h2 {
            font-size: 14px;
        }
        .tasks-icon-close {
            height: 15px;
            width: 15px;
            margin-left: auto;
        }
    }
    .task-management-popup-assignees-content {
        .scroll-container{
            max-height: 200px;
            overflow: auto;
        }
        .single-checkbox {
            .label-container {
                width: calc(100% - 22px);
                .tasks-icon {
                    min-width: 26px;
                    margin-right: 5px;
                }
                p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}
