@import "../../../base";

.popup-clients-filter-options-container {
  padding: 5px 10px;
  .icon-container {
    margin-left: auto;
    transform: rotate(45deg);
  }
  h4,
  p {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  > p {
    cursor: pointer;
    &:active {
      transform: scale(0.98);
    }
  }

  h4 {
    font-weight: 700;
    margin-bottom: 10px;
  }
  .label-container p {
    margin: 6px 0;
    line-height: 1.25rem;
  }

  .scroll-container {
    max-height: 160px;
    overflow: scroll;
  }

  .single-radio-button .label-container,
  .single-radio-button .label-container p {
    flex: 1;
  }

  .buttons {
    position: relative;
    padding-bottom: 10px;
    .save-filter {
      position: absolute;
      bottom: -10px;
      left: -10px;
      right: -10px;
      padding: 10px 10px 0;
      max-height: 0px;
      overflow: hidden;
      transition: 0.4s ease;
      transform-origin: bottom center;
      background: $color-white;
      &.show {
        max-height: 300px;
      }
      .row {
        justify-content: space-between;
        .raised-button-container {
          flex: none;
        }
      }
    }
  }

  .raised-button-container:not(.btn-save) {
    background: $color-white;
    color: $color-text;
    &.btn-show-save {
      margin-top: 40px;
      background-color: $color-cream;
    }
  }
}
