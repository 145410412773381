@import "../../../base";

.modal-container.editCommissions {
    position: relative !important;
    width: 40%;
    .modal-edit-commissions-container {
        .modal-edit-commissions-header {
            justify-content: space-between;
            h2 {
                font-size: 1.37rem;
                line-height: 1.625rem;
                font-weight: 500;
            }
            .icon-close {
                height: 20px;
                width: 20px;
            }
        }
        .modal-edit-commissions-content {
            margin: 15px 0;
            .col {
                width: calc(50% - 30px);
                margin-right: 30px;
                > p {
                    line-height: 38px;
                    margin: 10px 0;
                }
            }
        }
        .modal-edit-commissions-footer {
            .raised-button-container {
                flex: none;
                margin: 0 30px 0 auto;
            }
        }
    }
}
