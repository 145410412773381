@import "base";
.client-forms-annex-contract-container {
  @include card;
  padding: 10px 24px;
  h2 {
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 500;
    margin-bottom: 30px;
  }
  > .row {
    .drag-and-drop .drag-and-drop-overlay {
      border-radius: 6px;
      .drag-and-drop-overlay-inner {
        font-size: 8px;
        transform: translateY(-50%);
      }
    }
    width: 100%;
    margin: 15px 0;
    align-items: flex-end;
    > .col {
      width: calc(20% - 15px);
      margin-right: 15px;
      &.col-big {
        width: calc(30% - 15px);
      }
      &.col-double {
        width: calc(40% - 15px);
      }
      &.col-small {
        width: calc(10% - 15px);
      }
      &.disabled .particles-switch-button,
      &.disabled .image-upload-input,
      &.disabled .image-upload-input .icon {
        cursor: default;
        .ripple::after {
          display: none;
        }
      }
      &.col-dealers > .row {
        margin: 10px 0;
        height: 36px;
        .icon-add {
          margin-right: 6px;
        }
        > .row {
          flex: 1;
          overflow: auto;
          .single-person-container {
            margin-right: 12px;
            .profile-image-container {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              margin-right: 6px;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }
            p {
              white-space: nowrap;
              font-weight: 700;
              margin: 0;
            }
          }
        }
      }

      .radio-buttons-container {
        flex-wrap: wrap;
      }
      .text-input {
        max-width: 100%;
      }
      .row-auto-sign .inputs-text-labeled-container {
        margin-left: 30px;
        max-width: calc(100% - 90px);
        .text-input {
          padding-right: 35px;
        }
      }

      .row-file {
        a {
          line-height: 3.625rem;
          white-space: nowrap;
          overflow: scroll;
          @include remove-scrollbars;
          max-width: 50%;
        }
        .icon-container {
          transform: rotate(45deg);
          width: auto;
        }
      }
      .image-upload-input {
        box-shadow: none;
        color: grey;
        text-decoration: underline;
        font-size: 0.875rem;
        line-height: 1.125rem;
        padding: 0;
        margin: 8px 0;
        justify-content: flex-start;
        .icon {
          margin-left: 10px;
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  .particles-switch-button,
  .radio-buttons-container,
  .placeholder {
    height: 38px;
    margin: 10px 0;
    justify-content: flex-start;
    .switch-label {
      display: none;
    }
  }
}
