@import '../../../base';

.inputs-rounded-buttons-container {
  .btn-outlined {
    @include card($border-radius: 6px);
    @include shadow-small;
    justify-content: center;
    height: 40px;
    padding: 0px 15px;
    margin: 10px 15px 10px 0px;
    opacity: 0.5;
    transition: 0.4s ease;
    border: 2px solid transparent;
    cursor: pointer;
    font-size: 0.81rem;
    &:active {
      transform: scale(0.95);
    }
    &.active {
      opacity: 1;
      border: 2px solid $color-orange;
    }
  }
}
