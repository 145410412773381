@import "../../../base";

.card-consumption-graph-container {
  @include card;
  padding: 20px 24px;
  z-index: 3;
  &.expanded {
    .card-consumption-graph {
      z-index: 5;
      background-color: $color-white;
      position: absolute;
      margin: 0;
      height: 100vh;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10vh 1vw;
      .graph {
        height: 800px;
      }
    }
    .icon.icon-collapse {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 6;
    }
  }

  h2 {
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 500;
    margin: 0;
  }

  .card-consumption-graph-header {
    .row-buttons {
      flex: 1;
      justify-content: flex-end;
      .inputs-dropdown-container {
        width: 30%;
      }
      .icon {
        margin-left: 15px;
      }
      .icon.icon-export,
      .icon.icon-expand,
      .icon.icon-collapse {
        height: 36px;
        width: 36px;
        border: 1px solid $color-blue-dark;
        border-radius: 6px;
        background-size: 70%;
      }
    }
  }
  .card-consumption-graph {
    height: 400px;
    width: 100%;
    margin: 30px 0;
    .graph {
      max-height: 400px;
    }
  }
  .card-consumption-table {
    > .col {
      min-width: 80px;
      p {
        margin: 0;
        line-height: 53px;
        &:first-child {
          visibility: hidden;
        }
      }
    }
  }
}
