@import "../../base";

.screen-invoices-create-container {
  height: calc(100% - 53px - 2vw);
  margin: 1vw 2vw;
  @include card;
  .screen-invoices-create-header {
    @include card($background: $color-cream);
    padding: 0 24px;
    height: 60px;
    h2 {
      font-size: 1.25rem;
      line-height: 2rem;
      font-weight: 500;
      margin-right: 15px;
    }
    .row-buttons {
      margin-left: auto;
      .btn-clear {
        background-color: $color-white;
        border: 1px solid $color-blue-dark;
        color: $color-blue-dark;
        // margin: 0 15px;
      }
      .raised-button-container {
        margin-right: 15px;
        white-space: nowrap;
        flex: none;
      }
    }
  }
  .screen-invoices-create-content {
    height: calc(100% - 60px);
    overflow: auto;
    > div {
      border: 1px solid #00347036;
    }
  }
}
