@import '../../../base';

.popup-clients-options-container {
  padding: 5px 10px;
  .icon-container {
    margin-left: auto;
    transform: rotate(45deg);
  }
  .potential {
    border-bottom: 1px solid $color-text;
    .checkboxes-container .single-checkbox p {
      margin: 7px 0;
    }
    .particles-switch-button {
      padding: 14px 0;
      justify-content: space-between;
    }
  }
  h4,
  p {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  > p.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  p:not(.disabled) {
    cursor: pointer;
    &:active {
      transform: scale(0.98);
    }
  }

  h4 {
    font-weight: 700;
    margin-bottom: 10px;
  }

  .collapsable-erps {
    overflow: hidden;
    transition: all 0.4s ease-in;
    height: 0;
    & + p {
      margin-top: 0;
    }
    p {
      margin-left: 15px;
      &:first-child {
        margin-top: 0;
      }
    }
    &.active {
      height: auto;
    }
  }
}
