@import "base";

.invoices-forms-pricing-container {
    @include card;
    z-index: 1;
    .invoices-forms-pricing-content > .row {
        padding: 0 24px;
        p {
            font-weight: 700;
        }
        .inputs-text-labeled-container {
            width: calc(100% / 6 - 15px);
            flex: none;
            margin-left: auto;
            margin-right: 15px;
        }

        &:nth-child(odd) {
            background-color: #00347011;
        }
        &:last-child {
            background-color: $color-cream;
        }
    }
}
