@import "../../../base";

.modal-container.mailerTestEmail {
    position: relative !important;
    width: 40%;
    .modal-mailer-test-email-container {
        .modal-mailer-test-email-header {
            justify-content: space-between;
            h2 {
                font-size: 1.37rem;
                line-height: 1.625rem;
                font-weight: 500;
                text-align: center;
            }
        }
        .modal-mailer-test-email-content {
            margin: 30px 0;
        }
        .raised-button-container.no-flex {
            flex: none;
        }
    }
}
