@import "../../../../base";

.client-forms-objects-container {
  @include card;
  padding: 10px 0;
  .client-forms-objects-header,
  .row-buttons {
    padding: 0 24px;
  }

  .client-forms-object-container {
    padding: 0 2px;
    > div {
      padding: 15px 22px;
      margin: 0 0 2px;
    }
  }

  .client-forms-objects-header {
    justify-content: space-between;
    h2 {
      font-size: 1.25rem;
      line-height: 2rem;
      font-weight: 500;
    }
  }
  .row-remove-object {
    background-color: $color-cream;
    padding: 10px 1vw;

    span.invalid {
      display: block;
      text-align: center;
      color: $color-red;
      box-shadow: 0 0 3px $color-red;
      border-radius: 50%;
      line-height: 2vw;
      width: 2vw;
      font-size: 1.25rem;
      cursor: pointer;
      margin-left: 15px;
    }

    .particles-switch-button {
      margin-left: 15px;
    }
    .icon-container {
      transform: rotate(45deg);
      margin-left: auto;
    }
  }
  .required {
    border-color: $color-white;
    box-shadow: 0 0 3px $color-red;
  }
  .row-buttons {
    align-items: center;
    margin: 80px 0 0;
    .image-upload-input {
      margin-left: 30px;
      box-shadow: none;
      padding: 0;
    }
    .icon-add {
      margin-right: 5px;
    }
    .icon-add-mass {
      background-color: $color-green;
      border-radius: 6px;
    }
    .buttons {
      width: 50%;
      margin-left: auto;
      justify-content: flex-end;
      .raised-button-container {
        flex: none;
        margin-left: 15px;
        &.btn-cancel {
          background: $color-white;
          color: $color-text;
          border: 1px solid $color-text;
        }
      }
    }
  }
}
