@import "../../../base";

.modal-container.clientsGroupsForm {
    position: relative !important;
    width: 40%;
    .modal-clients-groups-form-container {
        .modal-clients-groups-form-header {
            justify-content: space-between;
            h2 {
                font-size: 1.37rem;
                line-height: 1.625rem;
                font-weight: 500;
                margin: 0;
            }
        }
    }
    .modal-clients-groups-form-content {
        margin: 30px 0;
    }
    .modal-clients-groups-form-footer .raised-button-container {
        flex: none;
        margin-left: auto;
    }
}
