@import '../../../base';

.employee-tasks-container {
  padding: 10px 0;
  .employee-tasks-header {
    h2 {
      font-size: 1.37rem;
      line-height: 1.8rem;
      font-weight: 500;
      margin: 0px;
      padding: 0 12px;
    }
    .underlined-main {
      padding: 0 12px;
      .underlined-container {
        justify-content: space-between;
      }
      .underlined-tab {
        padding: 10px 0px;
      }
      .active-underlined-selector {
        background-color: $color-orange;
        &.selector-add-height {
          height: 3px;
        }
      }
    }
  }
  .employee-tasks-content {
    height: calc(100% - 77px);
    overflow: scroll;
    padding-top: 15px;
    .tasks-task-container {
      .tasks-task-content {
        p:last-of-type {
          margin-bottom: 0;
        }
        > .row {
          display: none;
        }
      }
    }
  }
}
