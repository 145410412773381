.task-managemenat-inputs-radio-buttons-container {
    margin: 10px 0;
    .single-radio-button {
        margin-right: 15px;
        cursor: pointer;
        &.reversed {
            flex-direction: row-reverse;
        }
        &.disabled {
            cursor: default;
        }
        &.selected {
            .radio-button-outer .radio-button-inner {
                background-color: var(--main-color);
                width: 10px;
                height: 10px;
                border-radius: 50%;
                transition: all 150ms linear;
            }
            p {
                font-weight: 700;
                transition: all 150ms linear;
            }
        }

        p {
            white-space: nowrap;
            margin: 0;
            line-height: 35px;
        }

        .radio-button-outer {
            border: solid 1px var(--main-color);
            border-radius: 50%;
            width: 18px;
            height: 18px;
            margin-right: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            .radio-button-inner {
                width: 0px;
                height: 0px;
            }
        }
        .button-image {
            width: 26px;
            height: 26px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}
