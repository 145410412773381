@import "base";

.modal-container.responsibleForRegistration {
    position: relative !important;
    width: 40%;
    .modal-responsible-for-registration-container {
        .modal-responsible-for-registration-header {
            position: relative;
            h2 {
                font-size: 1.37rem;
                line-height: 1.625rem;
                font-weight: 500;
                text-align: center;
            }
            .icon-container {
                position: absolute;
                top: -10px;
                right: 0;
                height: auto;
                width: auto;
                transform: rotate(45deg);
            }
        }
        .modal-responsible-for-registration-content {
           justify-content: center;
           margin: 15px 0;
           .icon-add{
               margin-left: 15px;
           }
           .icon-container{
               transform: rotate(45deg);
           }
        }
    }
}
