@import "../../base";

.screen-client-container {
  height: calc(100% - 53px);
  padding: 1vw 2vw;
  .screen-client-header {
    @include card($border-radius: 6px);
    height: 52px;
    padding: 0 2vw;
    margin-bottom: 2vw;
    justify-content: space-between;
    h2 {
      font-size: 1.25rem;
      line-height: 2rem;
      font-weight: 700;
      margin: 0;
      margin-right: 15px;
    }
    p {
      max-width: 400px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    p span {
      font-weight: 700;
    }
    .icon-edit {
      height: 15px;
      width: 15px;
      margin-left: 5px;
    }
    .row-buttons {
      margin-left: 30px;
      justify-content: flex-end;
      .raised-button-container {
        flex: none;
        margin-left: 30px;
        &.btn-cancel {
          background: $color-white;
          color: $color-text;
          border: 1px solid $color-text;
        }
      }
    }
  }
  .screen-client-content {
    height: calc(100% - 52px - 2vw);
    justify-content: space-between;
    .screen-client-navigation {
      width: calc(95vw * 0.25);
      height: 100%;
      .single-tab {
        @include card($border-radius: 6px);
        line-height: 1.375rem;
        text-transform: uppercase;
        margin-bottom: 15px;
        padding: 15px;
        transition: 0.3s ease;
        transition: margin 0s ease;
        justify-content: space-between;
        .icon-arrow-right,
        .icon-arrow-right-white {
          transition: 0.3s ease;
          height: 20px;
          width: 20px;
        }
        &.selected {
          background-color: $color-blue-dark;
          color: $color-white;
          font-weight: 700;
        }
        &.no-margin {
          margin-bottom: 0px;
          &.selected .icon-arrow-right-white {
            transform: rotate(90deg);
          }
        }
      }
      .objects-container {
        @include card;
        margin-bottom: 15px;
        padding: 15px 0 5px;
        width: 100%;

        max-height: 400px;
        overflow: auto;

        > div:first-child {
          width: 100% !important;
          .add-object {
            border: 1px dashed $color-orange;
            border-radius: 6px;
            margin: 0 15px 15px;
            padding: 5px 0;
            text-align: center;
            cursor: pointer;
            &:active {
              transform: scale(0.98);
            }
          }
        }

        .group-header {
          margin: 0 0 15px;
          padding: 5px 15px;
          justify-content: space-between;
          .icon-emails {
            height: 20px;
            width: 20px;
            margin-left: 20px;
          }
          .icon-edit {
            height: 16px;
            width: 16px;
            margin-left: 8px;
          }
        }
        .group-divider {
          height: 1px;
          background-color: $color-blue-dark;
          width: calc(100% - 20px);
          margin: 20px 10px 10px;
        }
        span {
          padding: 0px 15px;
          color: $color-orange;
          font-weight: 700;
          text-transform: uppercase;
        }
        p.no-margin {
          margin: 0;
        }

        .single-object {
          line-height: 1.375rem;
          margin-bottom: 15px;
          justify-content: space-between;
          padding: 5px 15px;
          transition: 0.3s ease;
          cursor: pointer;
          .icon-arrow-right {
            transition: 0.3s ease;
            height: 20px;
            width: 20px;
          }
          &.producer {
            background-color: #f0fff0;
          }
          &.selected {
            background-color: $color-cream;
          }
          &.annex {
            &.upcoming {
              border-radius: 6px;
              margin: 0 1px 15px;
              box-shadow: 0 0 3px $color-orange;
            }
            p {
              white-space: pre;
              margin: 0;
            }
          }
        }
      }
    }
    .screen-client-inner-content {
      @include card;
      align-self: flex-start;
      width: calc(95vw * 0.75);
      max-height: 100%;
      overflow: auto;

      .col > span,
      .radio-buttons-container p,
      .radio-buttons-container {
        font-size: 0.875rem;
      }
      .row-annex-header {
        padding: 0 24px;
        p {
          margin-right: 45px;
        }
      }
      .row-mergedObjects {
        padding: 5px 24px;
        .icon.icon-edit {
          background-size: 70%;
          margin-left: 10px;
        }
      }
      .row-ignore {
        justify-content: space-between;
        padding: 0 24px;
        .row p {
          color: red;
          font-weight: 300;
        }
        .icon-container {
          transform: rotate(45deg);
          width: auto;
        }
      }

      p.empty-objects {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 500;
        text-align: center;
        padding: 40px;
      }
    }
  }
}
