@import "../../../base";

.modal-container.uploadFiles {
    position: relative !important;
    width: 800px;
}
.modal-upload-files-container {
    .modal-upload-files-header {
        justify-content: space-between;
        h2 {
            font-size: 1.37rem;
            line-height: 1.625rem;
            font-weight: 500;
            margin: 0;
        }
    }
    .modal-upload-files-content {
        margin: 15px 0;
        .radio-buttons-container {
            margin: 15px 0;
        }
        .row .image-upload-input {
            box-shadow: none;
            color: grey;
            text-decoration: underline;
            font-size: 0.875rem;
            line-height: 1.125rem;
            padding: 0;
            margin: 10px 0;
            justify-content: flex-start;
            margin-left: 15px;
            .icon {
                margin-left: 10px;
                width: 16px;
                height: 16px;
            }
        }
        .row-files {
            width: 100%;
            max-height: 600px;
            overflow: auto;
        }
        .row-file {
            > .row {
                .icon-container {
                    transform: rotate(45deg);
                    width: auto;
                }
                span {
                    white-space: nowrap;
                    text-decoration: underline;
                }
            }
            > span {
                margin-left: 28px;
            }
            &.exist span {
                color: red;
            }
        }
    }
    .modal-upload-files-footer .raised-button-container {
        flex: none;
        margin-left: auto;
        max-width: 20%;
    }
}
