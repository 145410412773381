@import '../../../base';
.modal-container.profile {
  position: relative !important;
  max-height: 90vh;
  width: 40%;
  .modal-profile-container {
    .modal-profile-header {
      justify-content: space-between;
      h2 {
        font-size: 1.37rem;
        line-height: 1.625rem;
        font-weight: 500;
        margin: 0;
      }
      .icon-container {
        transform: rotate(45deg);
      }
    }
    .modal-profile-content {
      justify-content: space-between;
      align-items: flex-start;
      .profile-image-container {
        flex: 1;
        align-items: center;
        margin-top: 20%;
        .profile-image.icon {
          height: 130px;
          width: 130px;
          position: relative;
          margin-bottom: 30px;
          background-size: cover;
          border-radius: 50%;
          .profile-status {
            position: absolute;
            border-radius: 50%;
            bottom: 0;
            right: 0;
          }
        }
      }
      .profile-data-container {
        flex: 1.8;
        p{
          margin-bottom: 0;
        }
        .btn-change-pass,
        .btn-cancel {
          flex: none;
          background: $color-white;
          color: $color-text;
        }
        .btn-save {
          margin-left: auto;
          flex: none;
        }
      }
    }
  }
}
