@import "../../../base";

.client-invoices-container {
  h2 {
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .client-invoices-details,
  .client-invoices-settings {
    padding: 0 24px;
    margin-bottom: 20px;
    > .row {
      align-items: flex-start;
      flex-wrap: wrap;
      margin-bottom: 20px;
      .col {
        width: calc(25% - 15px);
        margin-right: 15px;
        .required {
          border-color: $color-white;
          box-shadow: 0 0 3px $color-red;
        }
        .radio-buttons-container:not(.col) {
          height: 38px;
          margin: 10px 0;
        }
        .particles-switch-button {
          justify-content: flex-start;
          height: 38px;
          margin: 10px 0;
          > span {
            display: none;
          }
        }
        &.col-double {
          width: calc(75% - 15px);
        }
        &.col-switch.disabled .particles-switch-button {
          cursor: default;
        }
      }
    }
  }
  .client-invoices-consumer-container {
    @include card;
    z-index: 3;
  }
  .client-invoices-producer-container {
    @include card;
    z-index: 2;
  }
  .client-invoices-list {
    @include card;
    padding-bottom: 10px;
    h2 {
      padding: 0 24px;
    }
    .table-container .row.status .row {
      width: 100%;
      justify-content: space-between;
      padding-right: 15px;
    }
  }
}
