@import "base";

html {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 14px;
}
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

html,
body,
#root,
p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
li,
div,
a,
input,
textarea,
.raised-button-container {
  font-family: "CorsaGrotesk", sans-serif;
  color: $color-text;
  font-weight: 400;
}

input,
input::placeholder,
.text-input {
  font-size: 0.81rem;
}

.row {
  @extend %flex;
  flex-direction: row;
  align-items: center;
}
.col {
  @extend %flex;
  flex-direction: column;
}

.card {
  @include shadow-large;
  background: $color-white;
  border-radius: 0 0 6px 6px;
  &.rounded {
    border-radius: 6px;
  }
}

#root {
  background-color: $color-whiteish;
}

body .icon {
  height: 1.75rem;
  width: 1.75rem;
  min-width: 1.75rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  &.icon-back {
    background-image: url("./assets/icons/back.svg");
  }
  &.icon-logout {
    background-image: url("./assets/icons/logout.svg");
  }
  &.icon-phone-custom {
    background-image: url("./assets/icons/phone.svg");
  }
  &.icon-email {
    background-image: url("./assets/icons/email.svg");
  }
  &.icon-birthday-custom {
    background-image: url("./assets/icons/birthday.svg");
  }
  &.icon-folder-custom {
    background-image: url("./assets/icons/folder.svg");
  }
  &.icon-details {
    background-image: url("./assets/icons/details.svg");
  }
  &.icon-edit {
    background-image: url("./assets/icons/edit.svg");
  }
  &.icon-open {
    background-image: url("./assets/icons/open.svg");
  }
  &.icon-arrow-down {
    background-image: url("./assets/icons/arrow-down.svg");
  }
  &.icon-arrow-right {
    background-image: url("./assets/icons/arrow-right.svg");
  }
  &.icon-arrow-right-2 {
    background-image: url("./assets/icons/arrow-right-2.svg");
    transform: rotate(-90deg);
    &:active {
      transform: rotate(-90deg) scale(0.95);
    }
  }
  &.icon-arrow-right-white {
    background-image: url("./assets/icons/arrow-right-white.svg");
  }
  &.icon-refresh-white {
    background-image: url("./assets/icons/refresh.svg");
  }
  &.icon-arrow-right-2-white {
    background-image: url("./assets/icons/arrow-right-2-white.svg");
    transform: rotate(-90deg);
    &:active {
      transform: rotate(-90deg) scale(0.95);
    }
  }
  &.icon-filter {
    background-image: url("./assets/icons/filter.svg");
  }
  &.icon-attach {
    background-image: url("./assets/icons/attach.svg");
  }
  &.icon-eye-custom {
    background-image: url("./assets/icons/eye.svg");
  }
  &.icon-add {
    background-image: url("./assets/icons/plus.svg");
    background-color: $color-blue-dark;
    border-radius: 50%;
    background-size: 60%;
  }
  &.icon-profile {
    background-image: url("./assets/icons/profile.svg");
  }
  &.icon-arrows {
    background-image: url("./assets/icons/arrows.svg");
  }
  &.icon-calendar-custom {
    background-image: url("./assets/icons/calendar.svg");
  }
  &.icon-clock-custom {
    background-image: url("./assets/icons/clock.svg");
  }
  &.icon-check {
    background-image: url("./assets/icons/check.svg");
  }
  &.icon-export {
    background-image: url("./assets/icons/export.svg");
  }
  &.icon-info-custom {
    background-image: url("./assets/icons/info.svg");
  }
  &.icon-delete {
    background-image: url("./assets/icons/delete.svg");
  }
  &.icon-search-custom {
    background-image: url("./assets/icons/search.svg");
  }
  &.icon-chat-custom {
    background-image: url("./assets/icons/chat.svg");
  }
  &.icon-send {
    background-image: url("./assets/icons/send.svg");
  }
  &.icon-close {
    background-image: url("./assets/icons/close.svg");
  }
  &.icon-emails {
    background-image: url("./assets/icons/mention.svg");
  }
  &.icon-expand {
    background-image: url("./assets/icons/expand.svg");
  }
  &.icon-collapse {
    background-image: url("./assets/icons/collapse.svg");
  }
  &.icon-history {
    background-image: url("./assets/icons/history.svg");
  }
  &.icon-document {
    background-image: url("./assets/icons/document.svg");
  }
  &.icon-money {
    background-image: url("./assets/icons/money.svg");
  }
  &.icon-support-chat {
    background-image: url("./assets/icons/support.svg");
  }
  &:active {
    transform: scale(0.95);
  }
  &.no-active {
    &:active {
      transform: scale(1);
    }
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:active {
      transform: scale(1);
    }
  }
  &.no-pointer {
    cursor: default;
  }
}

body .dropdown-placeholder {
  color: grey;
}

body .input-datepicker-disabled,
body .Dropdown-disabled {
  height: 38px;
  border: 1px solid rgba(0, 52, 112, 0.3);
  background: #fafafa;
  .datepicker-text {
    color: $color-gray;
  }
}

body .raised-button-container {
  background-color: $color-orange;
  color: $color-white;
  &.cancel {
    background-color: $color-white;
    color: $color-blue-dark;
    border: 1px solid $color-blue-dark;
  }
  &:active {
    transform: scale(0.95);
  }
  &.raised-button-disabled {
    background-color: $color-orange;
    opacity: 0.5;
  }
  &.blue,
  &.raised-button-disabled.blue {
    background-color: $color-blue-dark;
  }
}

body .text-input-container .text-input {
  max-width: 100%;
}

body .input-datepicker,
body .drop-down-container {
  height: 38px;
}

body .input-datepicker {
  background-image: url("./assets/icons/calendar.svg");
}
body .input-timepicker {
  background-image: url("./assets/icons/clock.svg");
}
body .particles-switch-button.particles-switch-on .particles-switch-container,
body .react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
  background-color: $color-blue-dark;
}

body .particles-switch-button.disabled {
  cursor: default;
}

body .swal2-container .swal2-popup.swal2-icon-error {
  width: auto;
  max-width: 45em;
  // #swal2-content {
  //   white-space: pre;
  // }
}

body .inline-loader,
body .inline-loader:before,
body .inline-loader:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: inline-loader-animation 1.8s infinite ease-in-out;
  animation: inline-loader-animation 1.8s infinite ease-in-out;
}
body .inline-loader {
  display: block;
  color: $color-text;
  font-size: 3px;
  margin: 0 6em;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0) translateY(-100%);
  -ms-transform: translateZ(0) translateY(-100%);
  transform: translateZ(0) translateY(-100%);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

body .inline-loader:before,
body .inline-loader:after {
  content: "";
  position: absolute;
  top: 0;
}

body .inline-loader:before {
  left: -3em;

  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

body .inline-loader:after {
  left: 3em;
}

@keyframes inline-loader-animation {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

body .react-datepicker-popper {
  z-index: 11;
}
body .inputs-datepicker-container.year-picker .react-datepicker-popper {
  width: 220px;
}
body .react-datepicker__tab-loop .react-datepicker-popper,
body .inputs-datepicker-container.month-picker .react-datepicker-popper {
  width: 300px;
  .react-datepicker {
    width: 100%;
  }
  .react-datepicker__month-wrapper {
    display: flex;
    > div {
      flex: 1;
    }
  }
}

body .react-datepicker {
  display: block;
  .react-datepicker__header.react-datepicker__header--custom {
    background-color: $color-white;
    border-bottom: none;
    .react-datepicker__header__row {
      padding: 10px 0;
      .react-datepicker__navigation {
        padding: 20px 20px;
        border: none;
        .icon {
          height: 20px;
          width: 20px;
          min-width: 20px;
        }
        &.react-datepicker__navigation--previous .icon-arrow-right {
          transform: scaleX(-1);
        }
      }
      .react-datepicker__current-month {
        line-height: 24px;
      }
    }
  }
  .react-datepicker__triangle::after {
    border-bottom-color: $color-white;
  }

  .react-datepicker__month-container {
    float: none;
  }
  .react-datepicker__year-wrapper,
  .react-datepicker__month-wrapper {
    max-width: none;
    display: flex;
    justify-content: space-around;
  }
  .react-datepicker__year-text,
  .react-datepicker__month-text {
    height: 20px;
    padding: 0 5px;
    width: auto;
    flex: 1 0 6rem;
  }
  .react-datepicker__year-text:hover,
  .react-datepicker__year-text--keyboard-selected,
  .react-datepicker__month-text:hover,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__day-text:hover,
  .react-datepicker__day-text--keyboard-selected {
    background: $color-white;
    color: $color-orange;
    box-shadow: 0 0 2px $color-orange;
  }

  .react-datepicker__year-text--selected,
  .react-datepicker__month--in-range,
  .react-datepicker__month--selected,
  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__month--in-selecting-range:hover,
  .react-datepicker__day--selected {
    background: $color-orange;
    color: $color-white;
  }
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__month--in-selecting-range {
    background-color: lighten($color-orange, 45);
    color: $color-text;
    &:hover,
    &.react-datepicker__day--selecting-range-start,
    &.react-datepicker__month--selecting-range-start {
      background: $color-orange;
      color: $color-white;
    }
  }

  .radio-buttons-container {
    border-top: 1px solid $color-text;
    display: inline-flex;
    padding: 0 10px;
    width: 100%;
    justify-content: space-between;
  }
}
