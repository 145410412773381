@import "base";

.screen-commissions-upload-container {
    height: 100%;
    padding: 5vh 5vw;
    .screen-commissions-upload-header {
        justify-content: center;
        padding-bottom: 30px;
    }
    .screen-commissions-upload-content {
        flex: 1;
        > .col {
            height: 100%;
            flex: 1;
            align-items: center;
            justify-content: center;
        }
    }
}
