@import "../../base";

.screen-interests-form-container {
    height: calc(100% - 53px - 2vw);
    margin: 1vw 2vw;
    @include card;
    .screen-interests-form-header {
        @include card($background: $color-cream);
        padding: 0 24px;
        height: 60px;
        h2 {
            font-size: 1.25rem;
            line-height: 2rem;
            font-weight: 500;
            margin-right: 15px;
        }
        .raised-button-container {
            margin-left: auto;
            margin-right: 15px;
            flex: none;
        }
    }
    .screen-interests-form-content {
        height: calc(100% - 60px);
        overflow: auto;
        padding: 20px 24px 0;
        > .row > .col {
            width: calc(100% / 3 - 15px);
            margin-right: 15px;
            > div {
                min-width: 100%;
            }
            .particles-switch-button {
                height: 36px;
                margin: 10px 0;
                justify-content: flex-start;
                .switch-label {
                    display: none;
                }
            }
        }
    }
}
