@import '../../../base';
.card-files-container {
  .tab-files-upload {
    width: 100%;
    border-bottom: 1px solid grey;
    .image-upload-input {
      box-shadow: none;
      padding-left: 0;
    }
    span {
      color: grey;
      text-decoration: underline;
      font-size: 0.875rem;
      line-height: 1.125rem;
      cursor: pointer;
    }
    .icon-attach {
      height: 20px;
      width: 20px;
      margin-left: 10px;
    }
  }
  .single-file-tab {
    padding: 10px 0;
    .col-data {
      flex: 3;
      p {
        margin: 0;
        &.file-name {
          font-size: 1rem;
          line-height: 1.5rem;
          text-decoration: underline;
        }
        &.file-details {
          font-size: 0.875rem;
          line-height: 1.125rem;
          font-weight: 300;
          color: $color-gray;
        }
      }
    }
    .col-buttons {
      align-self: flex-start;
      height: 23px;
      flex: 1;
    }
  }
}
