.popup-clients-field-filter-container {
  min-width: 200px;
  padding: 5px 10px;
  position: relative;
  > .icon-container {
    position: absolute;
    top: 0;
    right: 0;
    transform: rotate(45deg);
  }

  .checkboxes-container {
    max-height: 200px;
    overflow-y: auto;
  }
  .raised-button-container {
    margin-left: 15px;
  }

  .label-container p {
    margin: 6px 0;
    line-height: 1.25rem;
  }

  h3 {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 700;
    margin: 10px 0;
  }
  p {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin: 10px 0;
    &.btn-clear {
      cursor: pointer;
      &:active {
        transform: scale(0.95);
      }
    }
  }
  > div:not(:last-child) {
    margin-bottom: 30px;
  }
}
