@import "base";

.modal-container.priceActiveFrom {
    position: relative !important;
    width: 40%;
    .modal-price-active-from-container {
        .modal-price-active-from-header {
            justify-content: space-between;
            h2 {
                font-size: 1.37rem;
                line-height: 1.625rem;
                font-weight: 500;
                text-align: center;
            }
        }
        .modal-price-active-from-content {
            margin: 30px 0;
        }
        .modal-price-active-from-footer {
            margin-left: auto;
            .raised-button-container {
                flex: none;
            }
        }
    }
}
