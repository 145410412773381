@import "../../../base";

.modal-container.generateInvoices {
    position: relative !important;
    width: 60%;
    .modal-generate-invoices-container {
        .modal-generate-invoices-header {
            justify-content: space-between;
            h2 {
                font-size: 1.37rem;
                line-height: 1.625rem;
                font-weight: 500;
            }
            .icon-close {
                height: 20px;
                width: 20px;
            }
        }
        .modal-generate-invoices-content {
            > div {
                width: calc(50% - 30px);
                margin-right: 30px;
            }
        }
    }
}
