.task-management-container,
.task-management-form-container,
.task-management-task-container,
.task-management-popup-column-container,
.task-management-popup-assignees-container,
.task-management-modal-edit-column-container,
.swal2-container {
    --main-color: #e87a1e;
    --light-color: #e8791e70;
    --selected-color: red;
    --text-color: black;
    --width-column: 28vw;
    --margin-column: 2vw;

    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    .row {
        display: flex;
        align-items: center;
    }
    .col {
        display: flex;
        flex-direction: column;
    }
    .card {
        box-shadow: 0px 5px 15px 0px #00347026;
        background-color: #ffffff;
        border-radius: 0 0 6px 6px;
    }
    .raised-button-container {
        display: flex;
        margin: 10px 0px;
        height: 35px;
        padding: 0px 25px;
        align-items: center;
        justify-content: center;
        background-color: var(--main-color);
        color: #ffffff;
        box-shadow: 0px 1px 4px rgba(0, 52, 112, 0.15);
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 700;
        white-space: nowrap;
        &.disabled {
            opacity: 0.7;
            pointer-events: none;
        }
        &:active {
            transform: scale(0.98);
        }
    }
    .input-text-container {
        display: block;
        width: 100%;
        border-radius: 5px;
        padding: 9px 11px;
        margin: 10px 0px;
        border: 1px solid rgba(0, 52, 112, 0.3);
        &:active,
        &:focus {
            outline: none;
        }
    }
    .tasks-icon {
        display: block;
        height: 26px;
        width: 26px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        cursor: pointer;

        &.tasks-icon-plus {
            background-image: url("../assets/plus.svg");
        }
        &.tasks-icon-plus-white {
            background-image: url("../assets/plus-white.svg");
        }
        &.tasks-icon-filter {
            background-image: url("../assets/filter.svg");
        }
        &.tasks-icon-pen {
            background-image: url("../assets/pen.svg");
        }
        &.tasks-icon-delete {
            background-image: url("../assets/delete.svg");
        }
        &.tasks-icon-settings {
            background-image: url("../assets/settings.svg");
        }
        &.tasks-icon-calendar {
            background-image: url("../assets/calendar.svg");
        }
        &.tasks-icon-download {
            background-image: url("../assets/download.svg");
        }
        &.tasks-icon-arrow {
            background-image: url("../assets/arrow.svg");
        }
        &.tasks-icon-arrow-down {
            background-image: url("../assets/arrow-down.svg");
        }
        &.tasks-icon-close {
            background-image: url("../assets/close.svg");
        }
        &:active {
            transform: scale(0.98);
        }
    }
}

.task-management-container {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    &.row {
        display: flex;
        align-items: flex-start;
    }

    .row-droppable {
        display: flex;
        height: 100%;
    }

    .new-column-container {
        width: var(--width-column);
        .btn-add-column {
            text-align: center;
            padding: 8px 0;
            border: 2px dashed var(--main-color);
            border-radius: 6px;
            cursor: pointer;
            &:active {
                transform: scale(0.98);
            }
            span {
                font-size: 18px;
                line-height: 26px;
                font-weight: 500;
            }
        }
        .new-column-inner-container .row-buttons {
            justify-content: space-between;
            .raised-button-container {
                flex: none;
                width: 30%;
                &.cancel {
                    background-color: #ffffff;
                    color: var(--text-color);
                    border: 1px solid var(--text-color);
                }
            }
        }
    }

    .task-management-column-container {
        border-radius: 6px;
        max-height: 100%;
        overflow: hidden;
        width: var(--width-column);
        margin-right: var(--margin-column);
        .task-management-column-header {
            padding: 0 16px;
            height: 55px;
            h2 {
                font-size: 20px;
                line-height: 24px;
                font-weight: 500;
            }
            .row-buttons {
                margin-left: auto;
                .tasks-icon {
                    margin-left: 20px;
                    &.tasks-icon-settings {
                        width: 10px;
                        margin-left: 15px;
                    }
                }
            }
        }

        .task-management-tasks-list-container {
            height: calc(100% - 55px);
            overflow: auto;
            padding: 0 16px;
            .task-management-tasks-list-drop-zone {
                height: 100%;
                padding-bottom: 20px;
                .task-management-task-outer-container {
                    padding: 5px 0;
                }

                .task-management-task-single-container {
                    border-radius: 6px;
                    border-left: 8px solid #ffffff;
                    // margin-bottom: 10px;
                    padding: 12px 8px;
                    h2 {
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 22px;
                        margin: 0;
                    }
                    p {
                        font-weight: 300;
                        // line-height: 28px;
                        margin: 12px 0;
                        span {
                            font-weight: 700;
                            &.orange {
                                font-size: 14px;
                                color: var(--main-color);
                            }
                            &.green {
                                color: #00cb5e;
                            }
                        }
                    }
                    .row-assignees {
                        > p {
                            margin: 0 10px 0 0;
                        }
                        .additional-assignees {
                            font-weight: 700;
                            background-color: var(--main-color);
                            color: #ffffff;
                            height: 30px;
                            width: 30px;
                            border-radius: 50%;
                            margin-left: auto;
                            justify-content: center;
                            font-size: 14px;
                        }
                        .scroll-container {
                            overflow: auto;
                        }
                        .single-assignee {
                            margin-right: 15px;
                            span {
                                white-space: nowrap;
                            }
                            .tasks-icon {
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.task-management-popup-column-container {
    padding: 8px;
    .task-management-popup-column-header {
        margin-bottom: 5px;
        h2 {
            font-size: 14px;
            font-weight: 300;
        }
        .tasks-icon-close {
            margin-left: auto;
            height: 15px;
            width: 15px;
        }
    }
    .row-action {
        justify-content: space-between;
        cursor: pointer;
        font-weight: 500;
        .tasks-icon {
            height: 20px;
            width: 20px;
        }
        &:active {
            transform: scale(0.98);
        }
    }
    .line {
        height: 1px;
        background-color: gray;
    }
}

.task-management-modal-edit-column-container {
    padding: 8px 16px;
    .task-management-modal-edit-column-header {
        justify-content: space-between;
        h2 {
            font-size: 22px;
        }
    }
    .raised-button-container {
        margin-left: auto;
    }
}

.task-management-filter-overlay-container {
    position: absolute;
    background: transparentize(#000, 0.5);
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    right: 0;
    transition: 0.2s linear;
    opacity: 0;
    z-index: -1;
    overflow: hidden;
    .task-management-filter-overlay-inner-container {
        background: #ffffff;
        width: 30vw;
        height: 100vh;
        margin-left: auto;
        transform: translateX(20vw);
        transition: 0.3s ease-in;
    }
    &.open {
        opacity: 1;
        z-index: 100;
        .task-management-filter-overlay-inner-container {
            transform: translateX(0vw);
        }
    }
}

.task-management-filter-container {
    height: 100%;
    .task-management-filter-header {
        padding: 0 16px;
        justify-content: space-between;
        h2 {
            font-size: 20px;
            line-height: 26px;
            font-weight: 500;
        }
    }
    .task-management-filter-content {
        padding: 0 16px;
        flex: 1;
        overflow: auto;
        > div:not(:last-child) {
            border-bottom: 1px solid gray;
            padding-bottom: 25px;
        }
        h3 {
            margin-top: 25px;
            margin-bottom: 6px;
        }
        .row-collapse {
            overflow: hidden;
            transition: all 0.4s ease-in;
            height: 0;
            &.active {
                height: auto;
            }
        }
        .tasks-icon-arrow-down {
            margin-top: 25px;
            margin-left: auto;
            height: 20px;
            width: 20px;
            transition: 0.4s;
            &.rotate {
                transform: rotate(180deg);
            }
        }
        .task-managemenat-inputs-radio-buttons-container,
        .task-managemenat-inputs-checkboxes-container {
            max-height: 300px;
            overflow: auto;
            .single-radio-button p,
            .single-checkbox p {
                margin: 6px;
                line-height: inherit;
            }
        }

        .task-management-inputs-datepicker-container {
            > div {
                display: flex;
                justify-content: center;
                .react-datepicker {
                    border: none;
                    width: 100%;
                    .react-datepicker__navigation-icon::before {
                        border-color: var(--main-color);
                    }
                    .react-datepicker__header {
                        background-color: transparent;
                    }
                    .react-datepicker__month-container {
                        width: 100%;
                    }
                    .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-start) {
                        background-color: var(--main-color);
                        opacity: 0.5;
                    }
                    .react-datepicker__day--selected,
                    .react-datepicker__day--in-range {
                        background-color: var(--main-color);
                    }
                }
            }
            .tasks-icon-calendar {
                display: none;
            }
        }
    }
    .task-management-filter-footer {
        padding: 0 16px;
        .raised-button-container {
            flex: 1;
            &.clear {
                background-color: #ffffff;
                color: var(--text-color);
                border: 1px solid var(--text-color);
            }
        }
    }
}
