@import '../../../../base';

.client-cells-document-container {
  width: 100%;
  justify-content: space-between;
  padding-right: 15px;
  .raised-button-container {
    background-color: $color-white;
    color: $color-text;
    border: 1px solid $color-text;
  }
}
