@import "../../../base";

.card-consumption-objects-container {
  @include card;
  padding: 20px 24px;
  h2 {
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 500;
    margin: 0;
  }

  .card-consumption-table {
    > .col {
      min-width: 150px;
      p {
        margin: 0;
        line-height: 53px;
        overflow: hidden;
        text-overflow: ellipsis;
        &:first-child {
          visibility: hidden;
        }
      }
    }
  }
}
