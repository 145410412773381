@import "../../base";

.screen-consumption-analysis-container {
  height: calc(100% - 53px);
  padding: 1vw 2vw;
  .screen-consumption-analysis-content {
    height: 100%;
    justify-content: space-between;
    align-items: flex-start;
    .screen-consumption-analysis-filter,
    .screen-consumption-analysis-inner-content {
      @include card;
      max-height: 100%;
      overflow: auto;
      @include remove-scrollbars;
    }
    .screen-consumption-analysis-filter {
      width: calc(95vw * 0.25);
    }
    .screen-consumption-analysis-inner-content {
      width: calc(95vw * 0.75);
    }
  }
  .empty-state{
    justify-content: center;
    h2{
      margin: 40px 0;
    }
  }
}
