@import "base";
.popup-intermediate-invoices-options-container {
    padding: 5px 10px;
    .icon-container {
        margin-left: auto;
        transform: rotate(45deg);
    }
    p {
        font-size: 0.875rem;
        line-height: 1.25rem;
        cursor: pointer;
        &:active {
            transform: scale(0.98);
        }
    }

    h4 {
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 700;
        margin-bottom: 10px;
    }
    .collapsable-rows {
        overflow: hidden;
        transition: all 0.4s ease-in;
        height: 0;
        & + p {
            margin-top: 0;
        }
        p {
            margin-left: 15px;
            &:first-child {
                margin-top: 0;
            }
        }
        &.active {
            height: auto;
        }
    }
}
