@import "base";

.modal-container.redistributeDealers {
    position: relative !important;
    width: 40%;
    .modal-redistribute-dealers-container {
        .modal-redistribute-dealers-header {
            justify-content: space-between;
            h2 {
                font-size: 1.37rem;
                line-height: 1.625rem;
                font-weight: 500;
                text-align: center;
            }
        }
        .modal-redistribute-dealers-content {
            margin: 30px 0;
            > .col {
                width: 50%;
                > div {
                    width: 100% !important;
                    .inputs-dropdown-container > div .react-popper-dropdown.react-popper-dropdown--disabled {
                        background: $color-white;
                        .react-popper-dropdown__select__open-button::after {
                            border-top-color: #666;
                        }
                    }
                }
            }
        }
        .modal-redistribute-dealers-footer {
            .raised-button-container {
                margin-left: auto;
                flex: none;
            }
        }
    }
}
