@import "../../../../base";

.client-forms-main-container {
  @include card;
  padding: 10px 24px;
  z-index: 5;
  .client-forms-main-header {
    justify-content: space-between;
    h2 {
      font-size: 1.25rem;
      line-height: 2rem;
      font-weight: 500;
    }
    .particles-switch-button {
      align-self: center;
    }
  }
  .required {
    border-color: $color-white;
    box-shadow: 0 0 3px $color-red;
  }
  > .row {
    width: 100%;
    margin: 15px 0;
    align-items: flex-end;
    > .col {
      width: calc(20% - 15px);
      margin-right: 15px;
      &.col-double {
        width: calc(40% - 15px);
      }
      .radio-buttons-container {
        flex-wrap: wrap;
      }
      &.col-mol {
        position: relative;
        > div {
          width: auto !important;
        }
        .business-details {
          position: absolute;
          right: 0;
          color: $color-blue-dark;
          line-height: 17px;
          > .icon-info-custom {
            height: 17px;
            width: 17px;
            margin-left: 10px;
          }
        }
      }
      .raised-button-container {
        flex: none;
      }
      .clien-info {
        line-height: 2.375rem;
        font-weight: 500;
        margin: 10px 0;
      }
      .icon-open {
        height: 15px;
        width: 15px;
        margin-left: 5px;
      }
      &.col-additional-client {
        &.disabled .particles-switch-button {
          cursor: default;
        }
        .particles-switch-button {
          height: 38px;
          margin: 10px 0;
          justify-content: flex-start;
          .switch-label {
            display: none;
          }
        }
      }
      &.col-connected-client {
        .add-icon-container {
          height: 38px;
          margin: 10px 0;
        }
        .row-client .icon-container {
          transform: rotate(45deg);
        }
      }
      &.col-merchant {
        width: 100%;
      }
      &.col-merchant.disabled .icon {
        cursor: default;
        .icon-add:active {
          transform: none;
        }
      }
      &.col-merchant > .row {
        margin: 10px 0;
        height: 36px;
        .icon-add {
          margin-right: 6px;
        }
        > .row {
          flex: 1;
          overflow: auto;
          .single-person-container {
            margin-right: 12px;
            .profile-image-container {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              margin-right: 6px;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }
            p {
              white-space: nowrap;
              font-weight: 700;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
.client-forms-main-footer {
  @include card;
  padding: 20px 24px;
  &.row {
    align-items: flex-end;
  }
  justify-content: space-between;
  .col {
    width: calc(60% - 15px);
  }
  .raised-button-container {
    flex: none;
    margin-right: 15px;
    width: calc(20% - 15px);
  }
}
