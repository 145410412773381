@import '../../../base';

.popup-client-add-object-container {
  padding: 5px 10px;
  .icon-container {
    margin-left: auto;
    transform: rotate(45deg);
  }
  h4,
  p {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 700;
  }

  > p {
    font-weight: 400;
    cursor: pointer;
    &:active {
      transform: scale(0.98);
    }
  }
}
