@import '../../../base';

.references-empoyees-container {
  height: 100%;
  width: 100%;

  .table-container {
    .fullName .icon {
      margin-right: 15px;
    }
  }
}
