@import '../../../base';

.popup-task-options-container {
  padding: 5px 40px 5px 10px;
  .icon-container {
    transform: rotate(45deg);
    position: absolute;
    top: 10px;
    right: 0;
  }
  p {
    font-size: 0.875rem;
    line-height: 1.25rem;
    cursor: pointer;
    margin: 6px 0;
    &:active {
      transform: scale(0.98);
    }
  }
}
