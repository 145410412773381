@import '../../../base';

.popup-filter-shortcuts-container {
  padding: 5px 10px;
  .icon-container {
    margin-left: auto;
    transform: rotate(45deg);
  }
  h4,
  p {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  h4 {
    font-weight: 700;
    margin-bottom: 10px;
  }

  .label-container p {
    margin: 6px 0;
  }
}
