@import "base";

.client-forms-annex-main-container {
  @include card;
  padding: 10px 24px;
  h2 {
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 500;
    margin-bottom: 30px;
  }
  > .row {
    width: 100%;
    margin: 15px 0;
    align-items: flex-end;
    > .col {
      width: calc(20% - 15px);
      margin-right: 15px;
    }
    .raised-button-container {
      flex: none;
    }
    .client-forms-intermediate-payments-container {
      width: 100%;
      > .col {
        width: calc(40% - 15px);
        margin-right: 15px;
      }
    }
    .particles-switch-button {
      height: 38px;
    }
    .particles-switch-button,
    .radio-buttons-container {
      margin: 10px 0;
      justify-content: flex-start;
      .switch-label {
        display: none;
      }
    }
  }
}
