@import "../../../base";

.analysis-filter-container {
  padding: 10px 0;
  > *:not(.single-client-container) {
    padding: 0 10px;
  }
  .raised-button-container {
    margin: 10px;
  }
  h4 {
    font-size: 1rem;
    line-height: 2rem;
    font-weight: 700;
    text-transform: uppercase;
    margin: 12px 0;
    &.add-margin {
      margin-top: 40px;
    }
  }
  p {
    font-size: 0.875rem;
    margin: 5px 0;
    &.client {
      font-weight: 700;
    }
  }
  > .row-period {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 40px;
    > div {
      width: 48%;
    }
  }

  .single-client-container {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
    .single-checkbox {
      margin-right: 0;
    }
    .single-client-header {
      background-color: $color-cream;
      padding: 0 10px;
      .icon {
        margin-left: auto;
        height: 20px;
        width: 20px;
        transition: 0.4s ease;
        transition-delay: 0.1s;
        transform: rotate(90deg);
        &.show {
          transition-delay: 0s;
          transform: rotate(270deg);
        }
      }
    }
    .single-client-content {
      padding: 0 10px;
      max-height: 0px;
      transition: 0.5s ease;
      transform-origin: top center;
      overflow: auto;
      @include remove-scrollbars;
      &.show {
        max-height: 100vh;
        display: block;
      }
    }
  }
}
