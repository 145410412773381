@import '../../../base';

.inputs-text-edit-container {
  width: 100%;
  .inputs-dropdown-container {
    width: 300px;
  }
  .row-buttons {
    margin-left: auto;
    padding-left: 10px;
  }
  p {
    flex: 1;
    margin: 0;
  }
  .icon-edit {
    height: 22px;
    width: 22px;
    margin-right: 15px;
  }
  .icon-container {
    transform: rotate(45deg);
  }
  .icon-check {
    height: 26px;
    width: 26px;
    margin-right: 15px;
  }
}
