@import "../../base";

.screen-clients-container {
  height: calc(100% - 53px);
  padding: 1vw 2vw;
  .screen-clients-inner-container {
    @include card;
    height: 100%;
    .screen-clients-header {
      padding: 0 24px;
      height: 72px;
      .row-right {
        margin-left: auto;
      }
      span.selectedFilterPreset{
        margin-right: 5px;
        font-weight: 700;
      }
      .btn-other-filters {
        border: 1px solid rgba(0, 52, 112, 0.3);
        height: 40px;
        width: 40px;
        background-size: 55%;
        border-radius: 6px;
        margin-right: 15px;
      }
      .raised-button-container {
        flex: none;
        font-size: 0.81rem;
        &.btn-cancel {
          background: $color-white;
          color: $color-text;
          border: 1px solid $color-blue-dark;
          margin-right: 15px;
        }
        &.btn-other-actions {
          background-color: $color-cream;
          color: $color-text;
          margin-right: 15px;
        }
      }
    }
    .screen-clients-content {
      height: calc(100% - 72px - 32px);
      width: 100%;
      .row-locked {
        position: relative;
        align-items: flex-start;
        max-height: 100%;
        overflow-y: hidden;
        > .table-container {
          height: calc(100vh - 2vw - 53px - 72px - 32px) !important;
        }
      }
      .table-container {
        .table-row-cell {
          overflow-y: hidden;
          .inputs-text-edit-container {
            max-width: 100%;
            .text-input-container {
              max-width: calc(100% - 91px);
            }
            .text-input {
              max-height: 25px;
            }
          }
        }
        .table-row-inner-container .table-row-cell:last-child > div {
          width: calc(100% - 30px);
        }
        .table-row-cell.responsibleForRegistration > div {
          width: calc(100% - 15px);
          justify-content: space-between;
          .icon-edit {
            height: 22px;
            width: 22px;
          }
        }
      }
    }
    .screen-clients-footer {
      @include card($background: #f7f7f7);
      height: 32px;
      overflow: hidden;
      .screen-clients-inner-footer {
        border-radius: 6px;
        background: $color-blue-dark;
        padding: 0 1vw;
        p {
          color: $color-white;
          font-size: 1.125rem;
          line-height: 22px;
          margin: 5px 1vw 5px 0;
          &:not(:last-child) {
            padding-right: 1vw;
            border-right: 1px solid $color-white;
          }
        }
      }
    }
  }
}
