@import '../../../base';

.popup-select-container {
  padding: 5px 30px 5px 10px;
  min-width: calc(100% + 30px);
  .row > .icon-container {
    margin-left: auto;
    transform: rotate(45deg);
  }
  h4,
  p {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  h4 {
    font-weight: 700;
    margin-bottom: 10px;
    &.add-margin {
      margin-top: 20px;
    }
  }
  .search-container {
    position: relative;
    input {
      padding-right: 40px;
    }
    .icon-container {
      position: absolute;
      top: 45%;
      transform: translateY(-50%);
      right: 5px;
      padding-left: 5px;
      &.ripple:after {
        display: none;
      }
      &:hover {
        &:active {
          transform: translateY(-50%) scale(0.93);
        }
      }
    }
  }
  .scroll-container {
    max-height: 160px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .text-input-container .text-input {
    margin: 0;
  }

  .label-container p {
    line-height: 1.25rem;
    margin: 6px 0;
  }
  .raised-button-container {
    margin-top: 20px;
  }
}
