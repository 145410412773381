@import '../../../base';

.modal-container.newTask {
  position: relative !important;
  max-height: 95%;
  padding: 20px 40px;
  .modal-new-task-container {
    width: 100%;
    position: relative;
    align-items: flex-start;
    .required .text-input,
    .required {
      box-shadow: 0 0 3px $color-red;
      border: none;
    }
    h2 {
      font-size: 1.37rem;
      line-height: 1.625rem;
      font-weight: 500;
    }
    .col > span,
    .row > span {
      font-size: 0.875rem;
      line-height: 1.25rem;
      &.subtitle {
        font-size: 1rem;
      }
      & + .icon-container {
        margin-right: 30px;
      }
    }
    > .icon-container {
      position: absolute;
      top: 0;
      right: -20px;
      transform: rotate(45deg);
    }
    .modal-new-task-inner-left-container {
      justify-content: space-between;
      width: 60%;
      height: 100%;
      padding-right: 40px;
      border-right: 1px solid $color-text;
      .icon-add {
        margin: 0 15px;
      }
      > div:not(:last-child) {
        margin-bottom: 15px;
      }
      .form-container {
        > .row,
        > .col {
          width: 100%;
          justify-content: space-between;
          &.row-client > div {
            width: calc(50% - 30px);
          }
          &.row-deadline > div {
            width: calc(25% - 15px);
          }
        }
        .row-deadline {
          align-items: flex-end;
          .input-timepicker {
            height: 38px;
          }
        }
      }
      .row-assignees {
        .assignees-container {
          max-width: calc(100% - 180px);
          overflow: scroll;
          -ms-overflow-style: none;
          scrollbar-width: none;
          &::-webkit-scrollbar {
            display: none;
          }
          .single-assignee {
            margin-right: 20px;
            .profile-image {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              margin-right: 5px;
            }
          }
        }
      }
      .row-priority > span {
        margin-right: 30px;
      }
      .row-files {
        .image-upload-input {
          box-shadow: none;
          color: grey;
          text-decoration: underline;
          font-size: 0.875rem;
          line-height: 1.125rem;
          .icon {
            margin-left: 10px;
            width: 16px;
            height: 16px;
          }
        }
        .files-scroll {
          width: calc(100% - 250px);
          overflow: scroll;
          @include remove-scrollbars;
          min-height: 60px;
          .single-file {
            margin-right: 20px;
            text-decoration: none;
            white-space: nowrap;
            .icon-container {
              transform: rotate(45deg);
              width: auto;
              height: auto;
              margin-left: 5px;
            }
          }
        }
      }
      .raised-button-container {
        flex: none;
      }
    }
    .modal-new-task-inner-right-container {
      width: 40%;
      padding-left: 40px;
      height: 100%;
      max-height: 825px;
      .checklist-container {
        flex: 1;
        height: 100%;
        overflow: scroll;
        max-height: calc(825px - 63px);

        .checklist-items-container {
          overflow: scroll;
          -ms-overflow-style: none;
          scrollbar-width: none;
          &::-webkit-scrollbar {
            display: none;
          }
        }
        .btn-add-item {
          justify-content: center;
          border: 1px dashed $color-orange;
          border-radius: 6px;
          height: 40px;
          margin-top: 15px;
          cursor: pointer;
          text-align: center;
          font-size: 0.875rem;
          line-height: 1.25rem;
          color: gray;
        }
      }
    }
  }
}
