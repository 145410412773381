.client-forms-product-prices {
    &.col-switch.disabled .particles-switch-button {
        cursor: default;
    }
    .particles-switch-button {
        height: 38px;
        margin: 10px 0;
        justify-content: flex-start;
        .switch-label {
            display: none;
        }
    }
}
