@import '../../../base.scss';

.input-autocomplete-container {
  position: relative;
  &.active {
    .text-input-container .text-input {
      background-color: transparent;
      z-index: 3;
    }
    .suggestions-container {
      z-index: 2;
    }
  }
  .text-input-container .text-input {
    padding-right: 35px;
  }

  .suggestions-container {
    position: absolute;
    top: 20px;
    height: 18px;
    left: 0;
    right: 0;
    z-index: -1;
    overflow: hidden;
    .single-suggestion {
      padding: 0 12px;
      font-size: 0.81rem;
      line-height: 1.125rem;
      opacity: 0.5;
    }
  }

  .icon-arrows {
    position: absolute;
    top: 22px;
    right: 5px;
    height: 15px;
  }
}
