@import "base";

.references-commissions-container {
    height: 100%;
    width: 100%;
    .references-commissions-content {
        height: calc(100% - 42px);
        .table-container {
            .row-emailSent {
                width: calc(100% - 14px);
                .icon.icon-info-custom {
                    margin-left: auto;
                    margin-right: 14px;
                    height: 20px;
                    width: 20px;
                }
                .raised-button-container {
                    margin-left: 10px;
                }
            }
            .image-upload-input,
            .raised-button-container {
                flex: 1;
                margin: 0;
                background-color: $color-white;
                color: $color-text;
                border: 1px solid $color-text;
                height: 24px;
                padding: 0 5px;
            }
        }
    }
    .references-commissions-footer {
        @include card($background: #f7f7f7);
        height: 42px;
        overflow: hidden;
        .references-commissions-inner-footer {
            border-radius: 6px;
            background: $color-blue-dark;
            padding: 0 1vw;
            p {
                color: $color-white;
                font-size: 1.125rem;
                line-height: 32px;
                margin: 5px 1vw 5px 0;
                &:not(:last-child) {
                    padding-right: 1vw;
                    border-right: 1px solid $color-white;
                }
            }
        }
    }
    .table-container .table-row-container .table-row-cell.monthYear .raised-button-container {
        height: 25px;
        margin-right: 15px;
    }
}
