@import "../../../base";

.references-invoices-container {
  width: 100%;
  height: 100%;
  align-self: flex-start;
  overflow: hidden;
  .references-invoices-header {
    .inputs-rounded-buttons-container {
      padding: 0 24px;
    }
    .text-input-container {
      max-width: 30%;
      margin-right: 30px;
    }
  }
  &.differences .references-invoices-content {
    height: calc(100% - 60px);
  }

  &.generated .references-invoices-content,
  &.not-generated .references-invoices-content,
  &.ready .references-invoices-content {
    height: calc(100% - 42px);
  }
  .references-invoices-content {
    height: 100%;
    .table-container {
      .row.ignore {
        padding-right: 15px;
        .raised-button-container {
          height: 25px;
        }
      }
      &.ready .table-row-children-container {
        overflow: auto;
        .table-container.objects {
          overflow: hidden;
        }
      }
      &.progress .table-row-container:last-child .table-row-inner-container {
        background: $color-cream;
      }
      &.differences {
        max-height: calc(100% - 60px);
        .table-row-inner-container {
          padding: 0;
        }
        .row.valueKey .single-radio-button.disabled {
          opacity: 0.5;
        }
        span.orange {
          // position: absolute;
          height: 100%;
          width: calc(100% + 14px);
          margin-left: -14px;
          padding-left: 14px;
          background: $color-orange;
          color: $color-white;
          overflow: hidden;
        }
      }
    }
  }

  .references-invoices-footer {
    @include card($background: #f7f7f7);
    height: 42px;
    overflow: hidden;
    > .row {
      border-radius: 6px;
      background: $color-blue-dark;
      height: 42px;
      padding: 0 1vw;
    }
    p {
      color: $color-white;
      font-size: 1.25rem;
      line-height: 42px;

      &.bold {
        font-weight: 700;
      }
      &:not(:first-child) {
        border-left: 1px solid $color-white;
        padding-left: 30px;
        margin: 0 0 0 30px;
      }
    }
    .icon-refresh-white {
      margin-left: 10px;
    }
  }
}
