@import "../../../../base";

.client-forms-object-container {
  > .row {
    flex-wrap: wrap;
    margin: 15px 0;
    align-items: flex-end;
  }
  .row {
    width: 100%;
    .drag-and-drop .drag-and-drop-overlay {
      border-radius: 6px;
      .drag-and-drop-overlay-inner {
        font-size: 8px;
        transform: translateY(-50%);
      }
    }
    &.title {
      align-items: center;
      margin-top: 30px;
      font-size: 1.12rem;
      font-weight: 500;
      .icon-container {
        transform: rotate(45deg);
      }
    }
    > .col {
      width: calc(20% - 15px);
      margin: 15px;
      margin-left: 0;
      &.col-double {
        width: calc(40% - 15px);
      }
      .raised-button-container {
        flex: none;
      }
      .placeholder {
        height: 38px;
        margin: 10px 0;
      }
      &.col-switch.disabled .particles-switch-button {
        cursor: default;
        .icon-add:active {
          transform: none;
        }
      }
      .particles-switch-button {
        height: 38px;
        margin: 10px 0;
        justify-content: flex-start;
        .switch-label {
          display: none;
        }
      }
      .image-upload-input {
        box-shadow: none;
        color: grey;
        text-decoration: underline;
        font-size: 0.875rem;
        line-height: 1.125rem;
        padding: 0;
        margin: 10px 0;
        justify-content: flex-start;
        height: 38px;
        .icon {
          margin-left: 10px;
          width: 16px;
          height: 16px;
        }
      }
      .invoice-container,
      .other-documents-container {
        align-items: center;
        .row {
          align-items: center;
        }
        height: 36px;
        margin: 10px 0;
        a {
          font-weight: 500;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
            color: #330033;
            &:active {
              transform: scale(0.98);
            }
          }
        }
        .icon-container {
          transform: rotate(45deg);
          min-width: 40px;
        }
      }
      .other-documents-container {
        overflow: scroll;
        span {
          white-space: nowrap;
          padding-right: 10px;
        }
      }
    }
  }
}
