@import "../../base";

.screen-objects-create-container {
  height: calc(100% - 53px);
  padding: 1vw 2vw;
  .screen-objects-create-header {
    @include card($border-radius: 6px);
    height: 52px;
    padding: 0 2vw;
    margin-bottom: 2vw;
    justify-content: space-between;
    h2 {
      font-size: 1.37rem;
      line-height: 2rem;
      font-weight: 700;
      margin: 0;
      margin-right: 15px;
    }
    p span {
      font-weight: 700;
    }
    .row-buttons {
      width: 40%;
      justify-content: flex-end;
      .image-upload-input {
        box-shadow: none;
        padding: 0;
        .icon-add {
          border-radius: 6px;
          background-color: $color-green;
          height: 38px;
          width: 38px;
        }
      }
      .raised-button-container {
        flex: none;
        margin-left: 15px;
        &.mass-add {
          background-color: #efeeed;
          color: $color-text;
        }
      }
    }
  }
  .screen-objects-create-content {
    height: calc(100% - 52px - 2vw);
    @include card;
    padding: 1vw 0 0;
    .screen-objects-create-inner-content {
      max-height: calc(100% - 2vw - 30px);
      height: 100%;
      overflow-x: scroll;
      overflow-y: hidden;
      .row-header {
        margin-left: 2vw;
        > span {
          padding-left: 15px;
        }
      }
      .scroll-container {
        max-height: calc(100% - 44px);
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        &.showPlaceholderSTP .client-forms-object-container .col-placeholder-STP,
        &.showPlaceholderITNMeasure .client-forms-object-container .col-placeholder-itnMeasure,
        &.showPlaceholderActiveFrom .client-forms-object-container .col-placeholder-activeFrom {
          align-self: center;
          padding: 0 15px;
          width: 285px;
          height: 56px;
          border-right: 1px solid $color-text;
        }

        .client-forms-object-container {
          animation: expand 0.3s ease;
          transform-origin: left center;
          overflow: hidden;
          padding: 0 2vw;
          position: relative;
          min-width: 100%;
          span.invalid {
            position: absolute;
            text-align: center;
            color: $color-red;
            box-shadow: 0 0 3px $color-red;
            border-radius: 50%;
            font-size: 1vw;
            line-height: 1.25vw;
            width: 1.25vw;
            left: 0.375vw;
            top: calc(50%);
            transform: translateY(-50%);
            cursor: pointer;
          }
          .required {
            border-color: $color-white;
            box-shadow: 0 0 3px $color-red;
          }
          > .row {
            border-radius: 6px;
            margin: 5px 0;
            padding: 10px 0;
          }
          > .row .col {
            margin: 0;
            padding: 0 15px;
            width: 285px;
            &.col-existingObject {
              width: 125px;
            }
            &.col-invoice {
              width: 165px;
            }
            &.col-trash {
              align-self: center;
              width: 40px;
            }
            &:not(:last-child) {
              border-right: 1px solid $color-text;
            }
            > span {
              display: none;
            }
            .other-documents-container,
            .other-documents-container .row {
              margin: 0;
              height: 56px;
            }
          }
        }
      }
    }
    .row-add {
      padding: 1vw 0;
      margin-left: 2vw;
      .icon {
        margin-right: 10px;
      }
    }
    .number-of-rows {
      margin-left: 5px;
      padding-left: 5px;
      border-left: 1px solid $color-text;
    }
  }
}

@keyframes expand {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
