@import "base";

.screen-commissions-container {
    height: calc(100% - 53px - 2vw);
    margin: 1vw 2vw;
    @include card;
    .screen-commissions-header {
        @include card($background: $color-cream);
        padding: 0 24px;
        height: 60px;
        h2 {
            margin: 0;
            font-size: 1.25rem;
            line-height: 1.25rem;
            font-weight: 700;
        }
        .inputs-datepicker-container {
            flex: none;
            > .icon.icon-calendar-custom {
                display: none;
            }
            .react-datepicker-wrapper .icon-calendar-custom {
                margin: 0 15px;
                height: 25px;
                width: 25px;
                position: relative;
            }
        }
        .inputs-search-multi-container {
            flex: 1;
            max-width: 30%;
        }
        > .row-buttons {
            margin-left: auto;
        }
        .raised-button-container {
            flex: none;
            font-size: 0.81rem;
            &.btn-other-actions {
                background-color: #efeeed;
                color: $color-text;
                margin-right: 15px;
            }
            &.btn-confirm {
                border: 1px solid $color-text;
                color: $color-text;
                background-color: $color-white;
                margin-right: 15px;
            }
        }
    }
    .screen-commissions-content {
        height: calc(100% - 60px - 42px);
        min-width: 100%;
        align-items: flex-start;
        .table-container .table-row-cell.comment,
        .table-container .table-row-cell.commission {
            .inputs-text-edit-container {
                .text-input-container {
                    max-width: calc(100% - 70px);
                }
                .text-input {
                    max-height: 25px;
                }
            }
            .icon-container {
                height: 20px;
                width: 20px;
                .icon-plus {
                    font-size: 20px !important;
                }
            }
            .icon.icon-check {
                height: 17px;
                width: 17px;
            }
        }
        .table-container .table-row-cell:last-child {
            .inputs-text-edit-container {
                max-width: calc(100% - 30px);
            }
        }
    }
    .screen-commissions-footer {
        @include card($background: #f7f7f7);
        height: 42px;
        overflow: hidden;
        .screen-commissions-inner-footer {
            border-radius: 6px;
            background: $color-blue-dark;
            padding: 0 1vw;
            p {
                color: $color-white;
                font-size: 1.125rem;
                line-height: 32px;
                margin: 5px 1vw 5px 0;
                &:not(:last-child) {
                    padding-right: 1vw;
                    border-right: 1px solid $color-white;
                }
            }
        }
    }
}

.screen-commissions-other-actions-popup-container {
    padding: 8px;
    .row-header {
        justify-content: space-between;
        .icon.icon-close {
            height: 20px;
            width: 20px;
        }
    }
    p:not(.disabled) {
        cursor: pointer;
        &:active {
            transform: scale(0.98);
        }
    }
    p.disabled {
        opacity: 0.5;
    }
}
