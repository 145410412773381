@import '../../../base';

.card-consumption-calculator-container {
  @include card;
  padding-top: 10px;
  h2 {
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 500;
    margin: 0;
    padding: 0 24px;
  }
  .card-consumption-calculator-header {
    padding-right: 24px;
    .drop-down-main {
      flex: none;
      margin: 0 0 0 auto;
    }
  }
  .card-consumption-calculator-graph {
    height: 300px;
    width: calc(100% - 48px);
    border: 1px solid $color-text;
    margin: 30px auto;
  }

  .card-consumption-calculator-table {
    padding: 30px 24px;
    .fields {
      margin-right: 10px;
      p.placeholder {
        color: $color-white;
      }
      > div {
        height: 58px;
        justify-content: flex-end;
      }
    }
    .row-data {
      overflow: scroll;
      > .col {
        // margin-left: 10px;
        &:not(:last-child) {
          > div {
            border-right: 1px solid $color-text;
          }
        }
        > div,
        > p {
          padding: 0 20px;
        }
      }
    }
  }

  .card-consumption-calculator-total {
    @include card;
    padding: 10px 0;

    .row {
      padding: 0 24px;

      align-items: flex-end;
      justify-content: space-between;
      // overflow: scroll;
      > div {
        width: calc(100% / 6 - 10px);
        p {
          margin-bottom: 0;
        }
        .inputs-text-labeled-container {
          max-width: 100%;
        }
        &.raised-button-container {
          flex: none;
        }

        &:last-child {
          background: $color-blue;
          padding: 0 5px;
        }
      }
    }
  }
}
