@import '../../../base';
.inputs-select-container {
  cursor: pointer;
  position: relative;
  > div {
    width: 100% !important;
  }
  input {
    text-overflow: ellipsis;
  }
  .activity-icon-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    justify-content: flex-end;
    padding-right: 10px;
    .icon {
      height: 20px;
      width: 20px;
    }
  }
  .text-input {
    padding-right: 30px;
  }
}
