@import "base";

.invoices-forms-leaving-container {
    @include card;
    .invoices-forms-leaving-header {
        padding: 5px 24px;
        h2 {
            font-size: 1.25rem;
            line-height: 2rem;
            font-weight: 500;
            margin: 5px 0;
        }
    }
    .invoices-forms-leaving-result {
        margin-bottom: 40px;
        .required {
            border-color: $color-white;
            box-shadow: 0 0 3px $color-red;
        }
        .invoices-forms-leaving-result-header,
        .invoices-forms-leaving-result-content {
            padding: 0 24px;
            &:nth-child(odd) {
                background-color: #00347011;
            }
        }
        .invoices-forms-leaving-result-content .col,
        .invoices-forms-leaving-result-header span {
            width: calc(100% / 4 - 15px);
            margin-right: 15px;
        }
        .invoices-forms-leaving-result-header {
            padding: 5px 24px;
        }
    }
}
