.shared-collapseable-row-container {
  .collapseable-row-header {
    position: relative;
    > * {
      flex: 1;
    }
    .icon-arrow-right {
      position: absolute;
      right: 1vw;
      top: 50%;
      height: 20px;
      width: 20px;
      transition: all 0.5s ease-in-out;
      transform: translateY(-50%) rotate(90deg);
      &.active {
        transform: translateY(-50%) rotate(270deg);
      }
    }
  }
  .collapseable-row-inner-content {
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    height: 0;
    &.active {
      height: auto;
    }
  }
}
