@import '../../../base';

.references-market-container {
  height: 100%;
  width: 100%;
  padding-top: 2vw;
  justify-content: space-between;
  &.row {
    align-items: flex-start;
  }
  // .references-market-navigation {
  //   @include card;
  //   width: calc(95vw * 0.25);
  //   > div {
  //     padding: 20px 24px;
  //   }
  //   span {
  //     font-size: 1.12rem;
  //     line-height: 1.375rem;
  //     font-weight: 500;
  //   }
  //   .upload {
  //     @include card($background: $color-cream);
  //     z-index: 1;
  //     > div {
  //       background-color: transparent;
  //       border: 1px $color-orange dashed;
  //       text-align: center;
  //       box-shadow: none;
  //       margin-top: 15px;
  //       &:active {
  //         transform: scale(0.98);
  //       }
  //     }
  //   }
  // }
  .references-market-content {
    @include card;
    // width: calc(95vw * 0.75);
    flex: 1;
    max-height: 100%;
    overflow: scroll;
    > .card {
      padding: 10px 24px;
      .shared-collapseable-row-container .collapseable-row-header {
        font-size: 1.12rem;
        font-weight: 500;
        padding: 5px 0;
        // .icon-arrow-right {
        //   position: relative;
        //   flex: none;
        //   right: auto;
        //   top: auto;
        //   transform: rotate(90deg);
        //   margin: 0 30px;
        //   &.active {
        //     transform: rotate(270deg);
        //   }
        // }
      }
      .card-table {
        align-items: stretch;
        .fields,
        .hours {
          height: 100%;
          min-width: 77px;
          p.placeholder {
            height: 40px;
            margin: 0 0 15px;
            visibility: hidden;
          }
          .row {
            height: 60px;
            margin-bottom: 10px;
            justify-content: flex-end;
            P {
              padding-right: 15px;
            }
          }
        }
        .hours {
          .row {
            position: relative;
            height: 130px;
            // margin-bottom: 8px;
            p {
              font-weight: 500;
            }
            .bracket {
              height: 100%;
              width: 100%;
              border: 1px solid $color-blue-dark;
              border-right: none;
              position: absolute;
              left: 100%;
              top: 0%;
            }
          }
        }
        .data {
          overflow: hidden;
          width: 100%;
          .shared-list-content {
            overflow: hidden;
          }
        }
        &.card-table-hours {
          .fields .row {
            padding-right: 0;
          }
          .data .shared-list-single-row-container,
          .data .shared-list-header {
            margin-left: 5px;
          }
        }
      }
    }
    .row-header {
      span {
        font-size: 1.12rem;
        line-height: 2.125rem;
        font-weight: 500;
        margin-right: 15px;
        padding-right: 15px;
        &:first-child {
          border-right: 1px solid $color-gray;
        }
      }
      .icon-export {
        height: 35px;
        width: 35px;
        border: 2px solid $color-blue-dark;
        background-color: $color-white;
        border-radius: 6px;
        background-size: 70%;
        margin-left: auto;
      }
    }
    .graph {
      margin: 30px 0;
      max-height: 400px;
    }
    .row-cards {
      width: 100%;
      justify-content: space-between;
      margin: 30px 0;
      > .card {
        padding: 5px 12px;
        min-width: 300px;
        > span {
          font-weight: 500;
          padding-bottom: 15px;
        }
        > .row {
          justify-content: space-between;
          > span {
            font-size: 0.875rem;
          }
        }
      }
    }
  }
}
