@import "../../base";
.screens-file-preview-container {
  height: calc(100% - 53px);
  padding: 1vw 2vw;
  .screens-file-preview-header {
    @include card($border-radius: 6px);
    height: 52px;
    padding: 0 2vw;
    margin-bottom: 2vw;
    h2 {
      font-size: 1.37rem;
      line-height: 2rem;
      font-weight: 700;
      margin: 0;
      margin-right: 60px;
    }
    p span {
      font-weight: 700;
    }
    p.month {
      margin-left: 30px;
      text-transform: capitalize;
    }
    .row-is-final-leaving .checkboxes-container {
      margin-left: 5px;
      .single-checkbox.selected .checkbox-inner {
        height: 16px;
        width: 16px;
      }
      .checkbox-outer {
        height: 25px;
        width: 25px;
      }
      .label-container {
        display: none;
      }
    }
    .inputs-datepicker-container {
      margin: 0 15px;
    }
    .row-buttons {
      margin-left: auto;
      .icon-export {
        height: 35px;
        width: 35px;
        border: 2px solid $color-blue-dark;
        background-color: $color-white;
        border-radius: 6px;
        background-size: 70%;
        margin-right: 15px;
      }
      .raised-button-container {
        flex: none;
      }
    }
  }
  // .table-container {
  //   max-height: calc(100% - 104px - 2vw);
  //   @include card;
  //   .table-row-container {
  //     margin-bottom: 0;
  //     .table-row-inner-container {
  //       padding: 0;
  //       border-radius: 0;
  //       box-shadow: none;
  //       border-bottom: 1px solid #00347026;
  //     }
  //   }
  // }

  .screens-file-preview-content {
    height: calc(100% - 104px - 2vw);
    @include card;
    overflow: hidden;

    &.market,
    &.stp,
    &.nomenclatures,
    &.deposits,
    &.interests {
      height: calc(100% - 52px - 2vw);
    }

    .table-container {
      &.progress .table-row-inner-container,
      &.invoices .table-row-inner-container {
        padding: 0;
      }
      .table-row-container {
        margin-bottom: 0;
        .table-row-inner-container {
          border-radius: 0;
          box-shadow: none;
          border-bottom: 1px solid gray;
          > span {
            overflow-x: scroll;
            overflow-y: hidden;
            white-space: nowrap;
            position: relative;
          }
          span.orange {
            // position: absolute;
            height: 100%;
            width: calc(100% + 14px);
            margin-left: -14px;
            padding-left: 14px;
            background: $color-orange;
            color: $color-white;
            overflow: hidden;
          }
        }
      }
    }
  }

  .screens-file-preview-footer {
    @include card($background: #f7f7f7);
    height: 52px;
    overflow: hidden;
    p {
      font-size: 1.125rem;
      padding: 0.5vw 1vw;
      font-weight: 700;
      margin: 0;
      transition: 0.4s;
      &:first-child,
      &.active {
        font-size: 1.25rem;
        line-height: 52px;
        background-color: $color-blue-dark;
        color: $color-white;
        &.active {
          background-color: $color-white;
          color: $color-orange;
        }
      }
      &.false {
        cursor: pointer;
      }
      &:not(:last-child):not(:first-child) {
        border-right: 1px solid gray;
      }
    }
  }
}
