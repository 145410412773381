@import "../../../base";

.modal-container.producersReferencesGenerate {
    position: relative !important;
    width: 60%;
    .modal-producers-references-generate-container {
        .modal-producers-references-generate-header {
            position: relative;
            h2 {
                font-size: 1.37rem;
                line-height: 1.625rem;
                font-weight: 500;
                text-align: center;
            }
            .icon.icon-close {
                position: absolute;
                top: -10px;
                right: 0;
            }
        }
        .modal-producers-references-generate-content {
            margin: 30px 0;
            width: 100%;
            .col {
                flex: 1;
            }
        }
    }
}
