@import "base";
.invoices-forms-interests-container {
  @include card;
  z-index: 3;
  .placeholder {
    width: 40px;
  }
  .invoices-forms-interests-header {
    padding: 5px 24px;
    justify-content: space-between;
    .icon-add {
      width: 30px;
      height: 30px;
    }
    h2 {
      font-size: 1.25rem;
      line-height: 2rem;
      font-weight: 500;
      margin: 5px 0;
    }
  }
  .invoices-forms-interests-form-header,
  .invoices-forms-interests-single {
    padding: 0 24px;
    > span,
    > .col {
      width: calc(100% / 6 - 15px);
      margin-right: 15px;

      &:nth-child(1) {
        width: calc(100% / 6 * 5 - 15px);
      }
      &.unit .text-input {
        padding-right: 11px;
      }
    }
    .icon-container {
      transform: rotate(45deg);
    }
    .required {
      border-color: $color-white;
      box-shadow: 0 0 3px $color-red;
    }
  }
  .invoices-forms-interests-form-header {
    padding: 5px 24px;
    background-color: #00347011;
  }
  .invoices-forms-interests-single:nth-child(odd) {
    background-color: #00347011;
  }

  .invoices-forms-interests-footer {
    padding: 0 24px;
    background-color: $color-cream;
    margin-bottom: 40px;
    p {
      font-weight: 700;
    }
    .inputs-text-labeled-container {
      width: calc(100% / 6 - 21px);
      flex: none;
      margin-left: auto;
      margin-right: 14px;
    }
  }
}
