@import 'base';

.screen-resources-container {
  height: calc(100% - 53px);
  padding: 1vw 2vw;
  .screen-resources-header {
    @include card($border-radius: 6px);
    height: 52px;
    padding: 0 2vw;
    margin-bottom: 2vw;
    h2 {
      font-size: 1.37rem;
      line-height: 2rem;
      font-weight: 700;
      margin: 0;
      margin-right: 60px;
    }
  }
  .screen-resources-content {
    @include card;
    height: calc(100% - 104px - 2vw);
    overflow: hidden;
  }
}
