@import "base";

.invoices-forms-object-container {
    @include card;

    .invoices-forms-object-header {
        padding: 5px 24px;

        background-color: $color-cream;
        .text-input-container {
            flex: none;
            margin-left: 15px;
            min-width: 600px;
        }
        h2 {
            font-size: 1.25rem;
            line-height: 2rem;
            font-weight: 500;
            margin: 5px 0;
        }
    }

    .invoices-forms-object-results,
    .invoices-forms-object-tech {
        margin-bottom: 40px;
        .row-total {
            padding: 0 24px;
            background-color: $color-cream;
            margin-bottom: 40px;
            p {
                font-weight: 700;
            }
            .inputs-text-labeled-container {
                width: calc((100% - 40px) / 6 - 15px);
                flex: none;
                margin-left: auto;
                margin-right: 54px;
            }
        }
        .required {
            border-color: $color-white;
            box-shadow: 0 0 3px $color-red;
        }
    }

    .invoices-forms-object-tech,
    .invoices-forms-object-results {
        .invoices-forms-object-tech-header,
        .invoices-forms-object-results-header,
        .invoices-forms-object-single-tech,
        .invoices-forms-object-single-results {
            padding: 0 24px;
            &:nth-child(odd) {
                background-color: #00347011;
            }

            .placeholder {
                width: 40px;
            }
            .icon-add {
                width: 30px;
                height: 30px;
                margin: 5px;
                min-width: 0;
            }
            .icon-container {
                width: 40px;
                height: 40px;
                transform: rotate(45deg);
            }
            .required {
                border-color: $color-white;
                box-shadow: 0 0 3px $color-red;
            }
        }

        .invoices-forms-object-single-tech .col,
        .invoices-forms-object-tech-header span {
            width: calc(100% / 7 - 15px);
            margin-right: 15px;
        }
        .invoices-forms-object-single-results .col,
        .invoices-forms-object-results-header span {
            width: calc(100% / 6 - 15px);
            margin-right: 15px;
            &:nth-child(1),
            &:nth-child(3) {
                width: calc(100% / 12 - 15px);
            }
            &:nth-child(2) {
                width: calc(100% / 3 - 15px);
            }
        }

        .invoices-forms-object-tech-header,
        .invoices-forms-object-results-header {
            padding: 5px 24px;
        }
    }
}
