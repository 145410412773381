@import "../../../base";

.modal-container.mergedObjects {
    position: relative !important;
    width: 30%;
    padding: 20px 0;
    .modal-merged-objects-container {
        .modal-merged-objects-header {
            padding: 0 20px;
            justify-content: space-between;
            h2 {
                font-size: 1.37rem;
                line-height: 1.625rem;
                font-weight: 500;
                text-align: center;
            }
        }
        .modal-merged-objects-content {
            padding: 15px 0;
            > * {
                padding: 0 20px;
            }
            -group-container {
                background-color: $color-cream;
                margin: 10px 0;
                padding-bottom: 1px;
                > p {
                    font-weight: 300;
                }
            }
            .checkboxes-container {
                max-height: 300px;
                overflow: auto;
                .single-checkbox p {
                    margin: 10px 0;
                }
            }
        }
        .modal-merged-objects-footer {
            padding: 0 20px;
            .btn-delete {
                border: 1px solid $color-red;
                color: $color-red;
                background: $color-white;
            }
        }
    }
}
