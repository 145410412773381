@import "../../base";

.screen-references-producers-container {
    height: calc(100% - 53px - 2vw);
    margin: 1vw 2vw;
    @include card;
    > div {
        height: 100%;
        > div:nth-child(1) {
            @include card($background: $color-cream);
            padding: 0 24px;
            height: 60px;
            h2 {
                margin: 0;
                font-size: 1rem;
                line-height: 1.25rem;
                font-weight: 500;
                margin-right: 15px;
            }
            .inputs-dropdown-container {
                flex: 1;
                max-width: 16%;
            }
            .inputs-datepicker-container {
                margin-left: 15px;
                flex: none;
                > .icon.icon-calendar-custom {
                    display: none;
                }
                .react-datepicker-wrapper .icon-calendar-custom {
                    margin: 0 15px;
                    height: 25px;
                    width: 25px;
                    position: relative;
                }
            }
        }
        > div:nth-child(2) {
            height: calc(100% - 60px - 42px);
            width: 100%;
            justify-content: center;
            > p.empty {
                font-size: 1.12rem;
                font-weight: 300;
            }
        }
    }
}
