@import "base";

.reference-objects-ignored-container {
    width: 100%;
    height: 100%;
    align-self: flex-start;
    overflow: hidden;
    .table-row-cell.button .raised-button-container {
        margin: 0;
        background-color: $color-white;
        color: $color-text;
        border: 1px solid $color-text;
        height: 24px;
    }
}
