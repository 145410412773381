@import "../../../base";

.modal-container.invoiceGroups {
  position: relative !important;
  width: 30%;
  padding: 20px 0;
  .modal-invoice-group-container {
    .modal-invoice-group-header {
      padding: 0 20px;
      justify-content: space-between;
      h2 {
        font-size: 1.37rem;
        line-height: 1.625rem;
        font-weight: 500;
        text-align: center;
      }
      .icon-container {
        transform: rotate(45deg);
      }
    }
    .modal-invoice-group-content {
      padding: 15px 0;
      > * {
        padding: 0 20px;
      }
      .checkboxes-container {
        max-height: 300px;
        overflow: auto;
        .single-checkbox p {
          margin: 10px 0;
        }
      }
      .row-client-number .raised-button-container {
        flex: 0.5;
        height: 36px;
        margin-left: 15px;
      }
      .additional-emails-container {
        max-height: 200px;
        overflow: auto;
        .row-additional-email {
          .icon-add {
            margin-left: 8px;
          }
          .icon-container {
            transform: rotate(45deg);
            width: auto;
            margin-left: 5px;
          }
        }
      }
      .tripartite-agreement-container,
      .default-group-container {
        background-color: $color-cream;
        margin: 10px 0;
        > p {
          font-weight: 300;
          margin-top: 0;
          display: inline-block;
        }
      }
      .tripartite-agreement-container {
        > .row > .col {
          width: calc(100% / 3 - 15px);
          margin-right: 15px;
          &.col-double {
            width: calc(100% / 3 * 2 - 15px);
          }
        }
      }
    }
    .modal-invoice-group-footer {
      padding: 0 20px;
      .btn-delete {
        border: 1px solid $color-red;
        color: $color-red;
        background: $color-white;
      }
    }
  }
}
