@import '../../../base';

.inputs-password-container {
  position: relative;
  .icon {
    height: 26px;
    width: 26px;
    margin: 6px;
    position: absolute;
    right: 0;
    bottom: 10px;
    &.active::before {
      transform: rotate(45deg) scale(1);
    }
    &::before {
      transition: transform 0.3s ease-out;
      content: '';
      height: 26px;
      width: 2px;
      background: $color-blue-dark;
      position: absolute;
      bottom: 0px;
      right: 9px;
      transform: rotate(45deg) scale(0);
    }
  }
}
