@import "../../../base";

.modal-container.inputText {
    position: relative !important;
    width: 40%;
    .modal-input-text-container {
        .modal-input-text-header {
            justify-content: space-between;
            h2 {
                font-size: 1.37rem;
                line-height: 1.625rem;
                font-weight: 500;
                text-align: center;
            }
        }
        .modal-input-text-content {
            margin: 30px 0;
        }
        .modal-input-text-footer {
            .raised-button-container {
                margin-left: auto;
                flex: none;
            }
        }
    }
}
