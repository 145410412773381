@import '../../../base';
.radio-buttons-container {
  .single-radio-button {
    margin-right: 15px;
    cursor: pointer;
    &.reversed {
      flex-direction: row-reverse;
    }
    &.disabled{
      cursor: default;
    }
    &.selected {
      .radio-button-outer .radio-button-inner {
        background-color: $color-orange;
        min-width: 10px;
        min-height: 10px;
        border-radius: 50%;
        transition: all 150ms linear;
      }
      p {
        font-weight: 700;
        transition: all 150ms linear;
      }
    }

    p {
      white-space: nowrap;
      line-height: 1.5rem;
      margin: 10px 0;
    }

    .radio-button-outer {
      border: solid 1px black;
      border-radius: 50%;
      min-width: 18px;
      min-height: 18px;
      margin-right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      .radio-button-inner {
        width: 0px;
        height: 0px;
      }
    }
    .button-image {
      width: 26px;
      height: 26px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
