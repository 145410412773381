$color-white: #ffffff;
$color-whiteish: #fcfcfc;
$color-cream: #fdf9f5;
$color-gray-light: #f2f2f2;
$color-gray: #8e8e8e;
$color-gray-dark: #464646;

$color-orange: #e87a1e;
$color-blue: #7cb4ff;
$color-blue-dark: #002157;
$color-green: #00cb5e;
$color-yellow: #ffb612;
$color-red: #fa4444;

$color-text: #000b1d;
$colors-shadow: #00347026;

$cell-height: 60px;

@mixin shadow-small {
  box-shadow: 0px 1px 4px 0px $colors-shadow;
}
@mixin shadow-large {
  box-shadow: 0px 5px 15px 0px $colors-shadow;
}

%flex {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
} //  @extend %flex;

@mixin card($border-radius: 0 0 6px 6px, $background: $color-white) {
  @include shadow-large;
  background: $background;
  border-radius: $border-radius;
} // @include card; @include card($border-radius: 6px)

@mixin remove-scrollbars {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

@font-face {
  font-family: 'CorsaGrotesk';
  src: url('assets/fonts/CorsaGrotesk-Thin.ttf') format('truetype');
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: 'CorsaGrotesk';
  src: url('assets/fonts/CorsaGrotesk-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: 'CorsaGrotesk';
  src: url('assets/fonts/CorsaGrotesk-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'CorsaGrotesk';
  src: url('assets/fonts/CorsaGrotesk-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: 'CorsaGrotesk';
  src: url('assets/fonts/CorsaGrotesk-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}
