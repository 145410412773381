@import '../../../base';

.inputs-dropdown-container {
  margin: 10px 0;
  > div {
    background-color: $color-white;
    &.required {
      box-shadow: none;
      .react-popper-dropdown .react-popper-dropdown__select {
        border: none !important;
        box-shadow: 0 0 3px $color-red !important;
      }
    }
    .react-popper-dropdown {
      flex: 1;
      .react-popper-dropdown__select {
        border-radius: 5px;
        height: 36px;
        border: 1px solid rgba(0, 52, 112, 0.3) !important;
        span {
          font-size: 0.81rem;
        }
        padding: 0px 10px;
      }
      &.react-popper-dropdown--disabled {
        background: #fafafa;
        .react-popper-dropdown__select {
          cursor: default;
        }
      }
    }
  }
}

.react-popper-dropdown__popper {
  z-index: 11;
  .react-popper-popup__dropdown {
    border-width: 1px;
    border-color: rgba(0, 52, 112, 0.3);
    border-radius: 5px;
    font-size: 0.81rem;
    .react-popper-popup__choices__choice {
      padding: 6px 10px;
    }
  }
}
