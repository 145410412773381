@import '../../base';

.screen-client-create-container {
  height: calc(100% - 53px);
  padding: 1vw 2vw;
  .screen-client-create-header {
    @include card($border-radius: 6px);
    height: 52px;
    padding: 0 2vw;
    margin-bottom: 1vw;
    h2 {
      font-size: 1.37rem;
      line-height: 2rem;
      font-weight: 700;
      margin: 0;
      margin-right: 150px;
    }
    p span {
      font-weight: 700;
      margin-right: 150px;
    }
  }
  > .row:not(.screen-client-create-header) {
    align-items: flex-start;
    justify-content: space-between;
    max-height: calc(100% - 1vw - 52px);
  }
  .screen-client-create-navigation {
    width: calc(95vw * 0.25);
    height: 100%;
    .single-tab {
      @include card($border-radius: 6px);
      line-height: 1.375rem;
      text-transform: uppercase;
      margin-bottom: 15px;
      padding: 15px;
      transition: 0.3s ease;
      justify-content: space-between;
      .icon-arrow-right,
      .icon-arrow-right-white {
        height: 20px;
        width: 20px;
      }
      &.selected {
        background-color: $color-blue-dark;
        color: $color-white;
        font-weight: 700;
      }
    }
  }
  .screen-client-create-content {
    @include card;
    align-self: flex-start;
    width: calc(95vw * 0.75);
    max-height: calc(100vh - 3vw - 53px - 52px);
    overflow: scroll;

    .col > span,
    .radio-buttons-container p,
    .radio-buttons-container {
      font-size: 0.875rem;
    }
  }
}
