@import "../../../base";

.modal-container.massEditClientsPrice {
    position: relative !important;
    // width: 40%;
    .modal-mass-edit-clients-price-container {
        .modal-mass-edit-clients-price-header {
            justify-content: space-between;
            h2 {
                font-size: 1.37rem;
                line-height: 1.625rem;
                font-weight: 500;
                text-align: center;
            }
        }
        .modal-mass-edit-clients-price-content {
            > p {
                text-align: center;
            }
            > .row > .col {
                flex: 1;
                margin-right: 15px;
                .required {
                    border-color: $color-white;
                    box-shadow: 0 0 3px $color-red;
                }
            }
        }
        margin-left: auto;
        .modal-mass-edit-clients-price-footer .raised-button-container {
            flex: none;
            margin-left: auto;
            margin-right: 15px;
        }
    }
}
