@import '../../../base';
.card-client-comments-container {
  height: 100%;
  padding: 15px 20px;
  .card-client-comments-header {
    margin-bottom: 50px;
    h3 {
      font-size: 1.5rem;
      font-weight: 500;
      margin: 0;
    }
    .icon-container {
      margin-left: auto;
      transform: rotate(45deg);
    }
  }
  .card-client-comments-content {
    height: calc(100% - 115px);
    .underlined-main {
      .underlined-container {
        max-width: 100%;
      }
      .active-underlined-selector {
        background-color: $color-orange;
        &.selector-add-height {
          height: 2px;
        }
      }
    }
  }
}
