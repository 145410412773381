@import "../../../base";

.client-documents-container {
  padding: 10px 0;
  .client-documents-header {
    padding: 0 24px;
    h2 {
      font-size: 1.25rem;
      line-height: 2rem;
      font-weight: 500;
    }
    .row-buttons {
      margin-left: auto;
      .btn-green {
        background-color: $color-white;
        border: 1px solid $color-green;
        color: $color-green;
      }
      .btn-blue {
        background-color: $color-white;
        border: 1px solid $color-blue-dark;
        color: $color-blue-dark;
        margin-left: 30px;
      }
    }
    .drag-and-drop .drag-and-drop-overlay {
      border-radius: 6px;
      .drag-and-drop-overlay-inner {
        font-size: 14px;
        transform: translateY(-50%);
      }
    }
  }
  .client-documents-content {
    .table-container {
      .row.comments > .row {
        width: 100%;
        justify-content: space-between;
      }
      .row.type .inputs-dropdown-container {
        width: calc(100% - 14px);
      }
      .row.delete {
        justify-content: center;
        padding-right: 14px;
        .icon-container {
          transform: rotate(45deg);
        }
      }
    }
  }
}

.popup-client-documents-container {
  padding: 5px 10px;
  .icon-container {
    margin-left: 30px;
    transform: rotate(45deg);
  }
  h2,
  p {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  > p {
    cursor: pointer;
    &:active {
      transform: scale(0.98);
    }
  }

  h2 {
    font-weight: 700;
    margin-bottom: 10px;
  }
}
