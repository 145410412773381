.task-management-task-container {
    padding: 15px;
    height: 100%;
    width: 100%;
    .task-management-task-header {
        border-radius: 6px;
        height: 50px;
        border-top: 5px solid #ffffff;
        margin-bottom: 1vw;
        padding: 0 16px;
        h2 {
            font-size: 22px;
            line-height: 26px;
            font-weight: 700;
        }
        .row-buttons {
            margin-left: auto;
            .raised-button-container {
                margin-left: 16px;
                &.completed {
                    background-color: #ffffff;
                    color: var(--text-color);
                    border: 1px solid var(--text-color);
                }
            }
        }
    }
    .task-management-task-content {
        height: calc(100% - 1vw - 60px);
        justify-content: space-between;
        .task-management-task-main {
            border-radius: 6px;
            width: calc(65% - 0.5vw);
            height: 100%;
            .task-management-task-general-information {
                z-index: 1;
                border-radius: 6px;
                h3 {
                    margin: 0;
                    padding: 18px 16px 0;
                    font-weight: 700;
                }
                > .row {
                    &.completed {
                        background-color: #efeeed;
                        margin: 0;
                        padding: 0 16px;
                    }
                    margin: 0 16px;
                    &:not(:last-of-type) {
                        border-bottom: 1px solid gray;
                    }
                    p {
                        font-size: 14px;
                        margin: 16px 0;
                        span {
                            font-weight: 500;
                        }
                    }
                    .dot {
                        height: 4px;
                        width: 4px;
                        border-radius: 50%;
                        background: var(--main-color);
                        box-shadow: 0px 0px 2px 2px var(--main-color);
                        margin: 0 12px;
                    }
                }
                .assignees-container {
                    margin-left: 15px;
                    overflow: auto;
                    .single-assignee {
                        margin-right: 15px;
                        .profile-image {
                            margin-right: 5px;
                        }
                    }
                }
            }
            .task-management-task-description {
                padding-top: 16px;
                flex: 1;
                overflow: hidden;
                .task-management-task-inner-description {
                    padding: 0 16px;
                    height: calc(100% - 42px);
                    .description-container {
                        height: 100%;
                        overflow: auto;
                    }
                }
            }
        }
    }
    .task-management-task-details {
        border-radius: 6px;
        width: calc(35% - 0.5vw);
        height: 100%;
        .task-management-task-inner-details {
            height: calc(100% - 42px);
        }
    }

    .task-management-files-container {
        height: 100%;
        .task-management-files-content {
            flex: 1;
            overflow: auto;
            padding: 8px 16px;
            .task-management-single-file {
                &:not(:last-child) {
                    border-bottom: 1px solid gray;
                }
                > .col {
                    flex: 1;
                    p {
                        font-weight: 700;
                        margin: 4px 0;
                        font-size: 14px;
                        &.details {
                            font-size: 12px;
                            font-weight: 400;
                        }
                    }
                }
                padding-bottom: 8px;
                margin-bottom: 8px;
                .tasks-icon {
                    margin-left: 16px;
                    height: 20px;
                    width: 20px;
                }
            }
        }

        .task-management-files-footer .upload-button-container {
            margin-left: auto;
            padding: 4px 16px;
            p {
                cursor: pointer;
                font-size: 14px;
                font-weight: 500;
                border: 2px dashed var(--main-color);
                margin: 0;
                padding: 4px 12px;
                border-radius: 6px;
                &:active {
                    transform: scale(0.98);
                }
            }
        }
    }

    .task-management-activity-container {
        height: 100%;
        padding: 32px 40px 0;
        overflow: auto;
        > div:last-child {
            flex: 1;
        }
        .task-management-activity-single-container {
            position: relative;
            padding-left: 8px;
            padding-bottom: 50px;
            p.time {
                font-size: 12px;
                line-height: 16px;
                margin: 0px;
            }
            p.activity {
                font-weight: 700;
                margin: 12px 0;
            }
            p.author {
                font-size: 14px;
                margin: 0;
                span {
                    font-weight: 700;
                }
            }

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: -12px;
                transform: translate(-50%);
                height: 16px;
                width: 16px;
                border-radius: 50%;
                background-color: var(--main-color);
            }
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: -13px;
                height: calc(100% - 3px);
                width: 2px;
                background-color: var(--text-color);
            }
        }
    }

    .task-management-comments-container {
        height: 100%;
        .comments-scroll-container {
            flex: 1;
            flex-direction: column-reverse;
            // justify-content: flex-end;
            overflow: auto;
            .single-comment-container {
                padding-bottom: 8px;
                &.isMe {
                    .row-details,
                    .single-member {
                        flex-direction: row-reverse;
                    }
                    .row-text {
                        background-color: #fdf9f5;
                    }
                }
                p {
                    font-size: 14px;
                    line-height: 18px;
                }
                margin-top: 40px;
                &:last-child {
                    margin-bottom: 20px;
                }
                .row-details {
                    justify-content: space-between;
                    padding: 0 16px;
                }
                .row-text {
                    margin: 0 16px;
                    padding: 5px 10px;
                    box-shadow: 0px 1px 4px 0px #00347026;
                    border-radius: 6px;
                    p {
                        margin: 0;
                    }
                }
                p.time {
                    font-size: 12px;
                }

                .profile-image,
                .icon {
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }
                .member-fullName {
                    font-size: 12px;
                    font-weight: 700;
                    margin: 0 10px;
                }
            }
        }
        .task-management-comments-input {
            border-top: 1px solid grey;
            margin-top: 10px;
            position: relative;
            padding: 0 16px;
            .tasks-icon-arrow {
                background-color: var(--main-color);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                border-radius: 6px;
                background-size: 40%;
                right: 16px;
                height: 37px;
                width: 37px;
                &:active {
                    transform: translateY(-50%) scale(0.95);
                }
            }
        }

        .empty-state {
            flex: 1;
            align-items: center;
            justify-content: center;
            .image {
                height: 60%;
                width: 80%;
                background-image: url("../assets/empty-state-comments.png");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }

    .task-management-checklist-container {
        height: 100%;
        .task-management-checklist-header {
            margin: 15px 0;
            padding-left: 16px;
            position: relative;
            background-color: #efeeed;
            border-radius: 6px;
            p {
                margin: 8px 0;
                z-index: 2;
                font-weight: 500;
            }
            .tasks-icon-plus-white-container {
                z-index: 2;
                margin-left: auto;
                height: 35px;
                width: 35px;
                background-color: var(--main-color);
                justify-content: center;
                border-radius: 6px;
                cursor: pointer;
                // &:active {
                // transform: scale(0.98);
                // }
                .tasks-icon-plus-white {
                    transition: 0.4s;
                    height: 26px;
                    width: 26px;
                    &.rotate {
                        transform: rotate(45deg);
                    }
                    &:active {
                        transform: scale(1);
                    }
                }
            }
            .task-management-checklist-progress {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 1;

                border-radius: 5px;
                transition: width 1s linear;
                background-color: var(--main-color);
            }
        }
        .task-management-checklist-content {
            flex: 1;
            overflow: auto;
            .single-checklist-item {
                padding: 8px 0;
                > span {
                    line-height: 30px;
                }
                &:not(:last-child) {
                    border-bottom: 1px solid gray;
                }
                &:hover {
                    .tasks-icon-delete {
                        display: block;
                    }
                }
                .single-checkbox {
                    margin-right: 0;
                    p {
                        display: none;
                    }
                }
                .tasks-icon-delete {
                    display: none;
                    margin-left: auto;
                    height: 27px;
                    width: 27px;
                }
            }
        }
        .task-management-checklist-footer {
            overflow: hidden;
            transition: all 0.4s ease-in;
            height: 0;
            &.active {
                height: auto;
            }
            .raised-button-container {
                margin-left: 16px;
                &.clear {
                    background-color: #ffffff;
                    color: var(--text-color);
                    border: 1px solid var(--text-color);
                }
            }
        }
    }
}
