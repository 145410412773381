@import '../../../base';
.inputs-text-labeled-container {
  position: relative;

  span {
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-weight: 300;
    position: absolute;
    top: 19px;
    right: 5px;
    padding-left: 5px;
    border-left: 1px solid $color-text;
  }
  .text-input {
    max-width: 100%;
    padding-right: 70px;
  }
}
