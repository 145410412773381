@import "../../base";

.screen-notices-container {
  height: calc(100% - 53px - 2vw);
  margin: 1vw 2vw;
  @include card;
  .screen-notices-header {
    @include card($background: $color-cream);
    padding: 0 24px;
    height: 60px;
    .inputs-search-multi-container {
      flex: 1;
      max-width: 30%;
      .row-buttons {
        margin-left: unset;
        background-color: $color-white;
      }
    }
    .row-buttons {
      margin-left: auto;
    }
    .raised-button-container {
      flex: none;
      font-size: 0.81rem;
      &.btn-other-actions {
        background-color: #efeeed;
        color: $color-text;
        margin-right: 15px;
      }
    }
  }
  .screen-notices-content {
    height: calc(100% - 60px);
    min-width: 100%;
    .table-container {
      .row.fullName .row {
        width: calc(100% - 15px);
        justify-content: space-between;
      }
    }
  }
}
