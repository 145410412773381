@import "base";

.client-forms-producer-settings-container {
    @include card;
    z-index: 2;
    .client-forms-producer-settings-inner-container {
        flex-wrap: wrap;
        padding: 10px 24px;
        margin: 0 2px 2px;
        .client-forms-producer-settings-header {
            justify-content: space-between;
            flex-basis: 100%;
            > .row > span {
                margin-right: 15px;
            }
        }
        h2 {
            font-size: 1.25rem;
            line-height: 2rem;
            font-weight: 500;
        }
        .required {
            border-color: $color-white;
            box-shadow: 0 0 3px $color-red;
        }
        > .row {
            width: 100%;
            margin: 15px 0;
            align-items: flex-end;
            .drag-and-drop .drag-and-drop-overlay {
                border-radius: 6px;
                .drag-and-drop-overlay-inner {
                    font-size: 8px;
                    transform: translateY(-50%);
                }
            }
            > .col {
                width: calc(20% - 15px);
                margin-right: 15px;
                &.col-small {
                    width: calc(10% - 15px);
                }
                &.col-double {
                    width: calc(30% - 15px);
                }
                .text-input {
                    max-width: 100%;
                }
            }
            &.row-files {
                .row-file {
                    span {
                        line-height: 3.625rem;
                        white-space: nowrap;
                        overflow: scroll;
                        @include remove-scrollbars;
                        max-width: 50%;
                    }
                    .icon-container {
                        transform: rotate(45deg);
                        width: auto;
                    }
                }
                .image-upload-input {
                    box-shadow: none;
                    color: grey;
                    text-decoration: underline;
                    font-size: 0.875rem;
                    line-height: 1.125rem;
                    padding: 0;
                    margin: 10px 0;
                    justify-content: flex-start;
                    .icon {
                        margin-left: 10px;
                        width: 16px;
                        height: 16px;
                    }
                    &.required {
                        color: $color-red;
                    }
                    &.disabled {
                        cursor: default;
                        .ripple::after {
                            display: none;
                        }
                    }
                }
            }
            .row-auto-sign .inputs-text-labeled-container {
                margin-left: 30px;
                max-width: calc(100% - 56px - 30px);
                .text-input {
                    padding-right: 35px;
                }
            }

            .particles-switch-button {
                height: 36px;
                margin: 10px 0;
                justify-content: flex-start;
                .switch-label {
                    display: none;
                }
            }
            .icon-container {
                height: 38px;
                margin: 10px 0;
            }
        }
        .row-buttons {
            justify-content: flex-end;
            margin: 80px 0 0;

            .raised-button-container {
                flex: none;
                margin-left: 15px;
                &.btn-cancel {
                    background: $color-white;
                    color: $color-text;
                    border: 1px solid $color-text;
                }
            }
        }
    }
}
