@import '../../../base';
.modal-container.clientsColumns {
  overflow: hidden;
  height: 500px;
  .modal-clients-columns-container {
    overflow: hidden;
    width: 100%;
    height: 100%;
    .modal-clients-columns-header {
      padding: 0 20px;
      h2 {
        font-size: 1.5rem;
        line-height: 1.8rem;
        font-weight: 500;
      }
      .icon-container {
        margin-left: auto;
        transform: rotate(45deg);
      }
    }
    .modal-clients-columns-content {
      overflow: hidden;
      height: calc(100% - 133px);
      width: 100%;
      > h3 {
        padding: 0 20px;
        font-size: 1rem;
        line-height: 1.375rem;
        font-weight: 700;
      }
      > div {
        border-radius: 6px;
        width: 100%;
        height: 100px;
        overflow: scroll;
        padding: 0 20px;
        .clients-columns-dragable-element {
          @include card;
          padding: 15px 20px;
          white-space: nowrap;
          p {
            line-height: 2rem;
            white-space: nowrap;
            margin: 0;
          }
          &:not(:last-child) {
            margin-right: 30px;
          }
          &.hidden {
            opacity: 0.5;
          }
        }
      }
    }
    .raised-button-container {
      max-width: 30%;
      margin-left: auto;
    }
  }
}

body .clients-columns-dragable-element {
  @include card;
  padding: 15px 20px;
  white-space: nowrap;
  p {
    line-height: 2rem;
    white-space: nowrap;
    margin: 0;
  }
  &:not(:last-child) {
    margin-right: 30px;
  }
  &.hidden {
    opacity: 0.5;
  }
}
