@import 'base';

.screen-objects-transfer-container {
  height: calc(100% - 53px);
  padding: 1vw 2vw;
  .screen-objects-transfer-header {
    @include card($border-radius: 6px);
    height: 52px;
    padding: 0 2vw;
    margin-bottom: 2vw;
    h2 {
      font-size: 1.37rem;
      line-height: 2rem;
      font-weight: 700;
      margin: 0;
      margin-right: 60px;
    }
    .raised-button-container {
      flex: none;
      margin-left: auto;
    }
  }
  .screen-objects-transfer-content {
    height: calc(100% - 104px - 2vw);
    @include card;
    overflow: auto;
    @include remove-scrollbars;
    .client-container {
      padding: 1vw;
      .client-header {
        @include card($background: $color-cream);
        padding: 0 1vw;
        margin-bottom: 10px;
        p {
          padding-right: 15px;
          font-weight: 500;
          span {
            font-weight: 700;
          }
          &:not(:first-child) {
            border-left: 1px solid $color-gray;
            padding-left: 15px;
          }
        }
      }
    }
  }
}
