@import "../../../base";

.modal-container.objectStatus {
    position: relative !important;
    width: 40%;
    .modal-object-status-container {
        .modal-object-status-header {
            position: relative;
            h2 {
                font-size: 1.37rem;
                line-height: 1.625rem;
                font-weight: 500;
                text-align: center;
            }
            .icon-container {
                position: absolute;
                top: -10px;
                right: 0;
                height: auto;
                width: auto;
                transform: rotate(45deg);
            }
        }
        .modal-object-status-content {
            margin: 30px 0;
            width: 100%;
            .col {
                flex: 1;
            }
        }
        .spacer {
            width: 30px;
        }
        .raised-button-container.no-flex {
            flex: none;
        }
    }
}
