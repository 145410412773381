@import "base";
.invoices-forms-invoice-group-container {
    @include card;
    padding: 5px 24px;
    h2 {
        font-size: 1.25rem;
        line-height: 2rem;
        font-weight: 500;
        margin: 5px 0;
    }
    .inputs-dropdown-container {
        margin-left: 30px;
        width: calc((100% - 40px) / 6 - 15px);
        &.required {
            border-color: $color-white;
            box-shadow: 0 0 3px $color-red;
        }
    }
}
