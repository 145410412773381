@import "base";

.modal-container.exportCustoms {
    position: relative !important;
    width: 40%;
    .modal-export-customs-container {
        .modal-export-customs-header {
            position: relative;
            h2 {
                font-size: 1.37rem;
                line-height: 1.625rem;
                font-weight: 500;
                text-align: center;
            }
            .icon-container {
                position: absolute;
                top: -10px;
                right: 0;
                height: auto;
                width: auto;
                transform: rotate(45deg);
            }
        }
        .modal-export-customs-content {
            margin: 30px 0;
            > div.col {
                flex: 1;
            }
        }
    }
}
