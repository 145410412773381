@import "base";

.modal-container.emailStats {
    position: relative !important;
    height: 80vh;
    width: 80vw;
    .modal-email-stats-container {
       height: 100%;
        .modal-email-stats-header {
            justify-content: space-between;
        }
        .modal-email-stats-content {
            height: calc(100% - 64px);
        }
    }
}
