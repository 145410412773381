.client-forms-intermediate-payments-container {
    &.row {
        align-items: flex-start;
    }
    > div {
        flex: 1;
    }
    .col-switch.disabled .single-radio-button {
        cursor: default;
    }
}
