@import '../../../base';

.card-client-file-system-container {
  height: 100%;
  padding: 15px 20px 0;
  .card-client-file-system-header {
    margin-bottom: 50px;
    h3 {
      font-size: 1.5rem;
      font-weight: 500;
      margin: 0;
    }
    .icon-add {
      height: 40px;
      width: 40px;
      margin-left: 20px;
    }
    .icon-container {
      margin-left: auto;
      transform: rotate(45deg);
    }
  }
  .card-client-file-system-content {
    height: calc(100% - 105px);
  }
}
