@import "base";
.modal-container.compensationSettings {
    position: relative !important;
    width: 50%;
    .modal-compensation-settings-container {
        .modal-compensation-settings-header {
            h2 {
                font-size: 1.37rem;
                line-height: 1.625rem;
                font-weight: 500;
                margin: 0;
            }
            .icon.icon-close {
                margin-left: auto;
            }
        }
        .modal-compensation-settings-content {
            margin: 15px 0;
            .required {
                border-color: $color-white;
                box-shadow: 0 0 3px $color-red;
            }
            > .row {
                align-items: flex-end;
                .col {
                    width: calc(50% - 30px);
                    margin-right: 30px;
                    &.col-small {
                        width: calc(25% - 30px);
                    }
                    &.col-large {
                        width: calc(75% - 30px);
                    }
                    .particles-switch-button {
                        justify-content: flex-start;
                        margin: 10px 0;
                        > span {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
