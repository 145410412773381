@import '../../../base';
.modal-container.changePassword {
  position: relative !important;
  width: 30%;
  .modal-change-password-container {
    h2 {
      font-size: 1.37rem;
      line-height: 1.625rem;
      font-weight: 500;
      margin-bottom: 50px;
    }
    .modal-change-password-content {
      P {
        margin-bottom: 0;
        font-weight: 400;
      }
      .password-input {
        position: relative;
        .icon {
          height: 22px;
          width: 22px;
          margin: 6px;
          position: absolute;
          right: 0;
          bottom: 12px;
          &.active::before {
            opacity: 0;
          }
          &::before {
            transition: opacity 0.3s ease-out;
            content: '';
            height: 22px;
            width: 2px;
            background: $color-blue-dark;
            position: absolute;
            bottom: 0px;
            right: 10px;
            transform: rotate(45deg);
            opacity: 1;
          }
        }
      }
    }
    .modal-change-password-footer{
      .btn-cancel {
        flex: none;
        background: $color-white;
        color: $color-text;
      }
      .btn-save {
        margin-left: auto;
        flex: none;
      }
    }
  }
}
