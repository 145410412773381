@import "../../../base";

.references-nomenclatures-container {
  width: 100%;
  height: 100%;
  align-self: flex-start;
  overflow: hidden;
  .row-date {
    @include card($background: #f7f7f7);
    height: 52px;
    overflow: hidden;
    > p {
      border-radius: 6px;
      background: $color-blue-dark;
      padding: 0 1vw;
      color: $color-white;
      font-size: 1.25rem;
      line-height: 52px;
      font-weight: 700;
      margin: 0;
    }
  }
  .references-nomenclatures-content {
    height: calc(100% - 52px);
  }
}
