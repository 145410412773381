@import "base";
.client-cells-object-execution-date-container {
    width: 100%;
    justify-content: space-between;
    padding-right: 15px;
    overflow: hidden;
    .raised-button-container {
        margin: 0;
        background-color: $color-white;
        color: $color-text;
        border: 1px solid $color-text;
        height: 24px;
        &.no-flex {
            flex: 0;
        }
    }
    input {
        height: 25px;
    }
}
