@import "../../../base";
.inputs-text-labeled-edit-container {
    position: relative;
    &.edit .row-buttons {
        top: 17px;
    }
    .row-buttons {
        position: absolute;
        top: 19px;
        right: 5px;
        padding-left: 10px;
        span {
            font-size: 0.75rem;
            line-height: 1.25rem;
            font-weight: 300;
            border-left: 1px solid $color-text;
            padding-left: 5px;
        }
        .icon-edit {
            height: 15px;
            width: 15px;
        }
        .icon-container {
            height: auto;
            width: auto;
            transform: rotate(45deg);
        }
        .icon-check {
            height: 18px;
            width: 18px;
            margin-right: 5px;
            min-width: auto;
        }
    }
    .text-input {
        max-width: 100%;
        padding-right: 90px;
    }
}
