.modal-loader-container {
    svg {
        width: 480px;
        height: 240px;
    }

    svg #plug,
    svg #socket {
        fill: #e87a1e;
    }

    svg #loop-normal {
        fill: none;
        stroke: #e87a1e;
        stroke-width: 12;
    }

    svg #loop-offset {
        display: none;
    }

    .text {
        text-align: center;
    }

    .text h2 {
        color: #e87a1e;
        font-size: 28px;
    }
}
