@import '../../../base';

.card-client-document-preview-container {
  height: 100%;
  padding: 15px 20px 0;
  .card-client-document-preview-header {
    h3 {
      font-size: 1.5rem;
      font-weight: 500;
      margin: 0 15px 0 0;
    }
    .icon-left {
      transform: rotate(90deg);
      margin-left: 15px;
      &.disabled:active {
        transform: rotate(90deg) scale(1);
      }
      &:active {
        transform: rotate(90deg) scale(0.9);
      }
    }
    .icon-right.disabled:active {
      transform: rotate(-90deg) scale(1);
    }

    .icon-arrow-right-2 {
      height: 20px;
      width: 20px;
    }
    .icon-edit,
    .icon-open {
      height: 20px;
      width: 20px;
      margin: 0 15px;
    }

    .icon-container {
      margin-left: auto;
      transform: rotate(45deg);
    }
  }
  .card-client-document-preview-content {
    height: calc(100% - 35px);
    > .row {
      // padding-bottom: 20px;
      .raised-button-container {
        flex: none;
        margin-left: 40px;
      }
    }
    .underlined-main {
      .underlined-container {
        max-width: 100%;
        .underlined-tab {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .active-underlined-selector {
        background-color: $color-orange;
        &.selector-add-height {
          height: 2px;
        }
      }
    }
    .document {
      height: calc(100% - 80px);
      border: 1px solid $color-text;
      iframe {
        height: 100%;
        width: 100%;
      }
    }
  }
  &.erps .card-client-document-preview-content .document {
    height: calc(100% - 120px);
  }
}
