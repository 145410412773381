@import '../../base.scss';

.screen-home-container {
  height: calc(100% - 53px);
  justify-content: space-between;
  padding: 1vw 2vw;
  .screen-home-notifications-container {
    @include card;
    width: calc(95vw / 3);
    height: 100%;
    padding: 0.5vw;
  }
  .screen-home-right-container {
    width: calc(95vw / 3 * 2);
    height: 100%;
  }
}
