@import '../../../base';
.card-comments-container {
  height: 100%;
  padding: 15px 20px;
  .card-comments-header {
    margin-bottom: 25px;
    h3 {
      font-size: 1.5rem;
      font-weight: 500;
      margin: 0;
    }
    .icon-container {
      margin-left: auto;
      transform: rotate(45deg);
    }
  }
  .card-comments-content {
    height: calc(100% - 40px);
  }
}
