@import "../../../base";

.modal-container.invoicesFileUpload {
  position: relative !important;
  width: 800px;
}
.modal-invoices-file-upload-container {
  .modal-invoices-file-upload-header {
    justify-content: space-between;
    h2 {
      font-size: 1.37rem;
      line-height: 1.625rem;
      font-weight: 500;
      margin: 0;
    }
    .icon-container {
      transform: rotate(45deg);
    }
  }
  .modal-invoices-file-upload-content {
    margin: 15px 0;
    .radio-buttons-container {
      margin: 15px 0;
    }
    .row .col {
      flex: 1;
      margin-right: 15px;
      .image-upload-input {
        box-shadow: none;
        color: grey;
        text-decoration: underline;
        font-size: 0.875rem;
        line-height: 1.125rem;
        padding: 0;
        margin: 10px 0;
        justify-content: flex-start;
        .icon {
          margin-left: 10px;
          width: 16px;
          height: 16px;
        }
      }
    }
    .col-files {
      width: 100%;
      max-height: 600px;
      overflow: auto;
    }
    .row-file {
      > .row {
        .icon-container {
          transform: rotate(45deg);
          width: auto;
        }
        span {
          white-space: nowrap;
          text-decoration: underline;
        }
      }
      > span {
        margin-left: 28px;
      }
      &.exist span {
        color: red;
      }
    }
  }
  .modal-invoices-file-upload-footer {
    .raised-button-container {
      flex: none;
      margin-left: auto;
      max-width: 20%;
    }
  }
}
