.single-notification-container {
  padding: 16px 0;
  p {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin: 0;
  }
  .single-notification-inner-container {
    width: 100%;
    padding-top: 10px;
    .profile-image {
      min-width: 40px;
      min-height: 40px;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .text p {
      padding: 0 15px;
    }
    .text .bold {
      font-weight: 700;
    }
  }
}
