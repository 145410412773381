@import '../../base';

.overlay-container {
  position: absolute;
  background: transparentize(#000, 0.5);
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  right: 0;
  transition: 0.2s linear;
  opacity: 0;
  z-index: -1;
  .overlay-inner-container {
    background: $color-white;
    width: 45vw;
    height: 95vh;
    margin-top: 2.5vh;
    margin-left: auto;
    transform: translateX(20vw);
    transition: 0.3s ease-in;
    border-radius: 6px;
  }
  &.open {
    opacity: 1;
    z-index: 10;
    .overlay-inner-container {
      transform: translateX(-5vw);
    }
  }
}
