@import '../../../base';

.modal-container.clientsProduct {
  position: relative !important;
  min-width: 50%;
  max-width: 80%;
  .modal-clients-product-container {
    .modal-clients-product-header {
      justify-content: space-between;
      h2 {
        font-size: 1.37rem;
        line-height: 1.625rem;
        font-weight: 500;
        margin: 0;
      }
      .icon-container {
        transform: rotate(45deg);
      }
    }
  }
  .modal-clients-product-content {
    margin: 30px 0;
    > .col {
      width: calc(25% - 15px);
      margin-right: 15px;
    }
  }
}
