@import "../../../../base";

.invoices-forms-client-container {
  @include card;
  padding: 5px 24px;
  &.required {
    border: 1px solid $color-red !important;
  }
  .invoices-forms-client-header {
    h2 {
      font-size: 1.25rem;
      line-height: 2rem;
      font-weight: 500;
      margin: 5px 15px 5px 0px;
    }
  }
  .invoices-forms-client-content {
    > .row {
      width: 100%;
      align-items: flex-end;
      margin: 15px 0;
      .col {
        width: calc(25% - 15px);
        margin-right: 15px;
        &.col-double {
          width: calc(50% - 15px);
        }
        .required {
          border-color: $color-white;
          box-shadow: 0 0 3px $color-red;
        }
      }
    }
  }
}
