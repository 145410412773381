@import '../../../../base';

.client-forms-contract-container {
  @include card;
  padding: 10px 24px;
  max-height: 100%;
  height: 100%;
  .required {
    border-color: $color-white;
    box-shadow: 0 0 3px $color-red;
  }
  h2 {
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 500;
    &:not(:first-child) {
      margin-top: 80px;
    }
  }
  > .row {
    width: 100%;
    margin: 15px 0;
    align-items: flex-end;
    > .col {
      width: calc(25% - 15px);
      margin-right: 15px;
      &.col-double {
        width: calc(50% - 15px);
      }
      &.col-radio-buttons {
        width: calc(30% - 15px);
      }
      .text-input {
        max-width: 100%;
      }
      .particles-switch-button {
        height: 38px;
        margin: 10px 0;
        justify-content: flex-start;
        .switch-label {
          display: none;
        }
      }
    }
    &.row-contract-type > .col:not(.col.col-radio-buttons),
    &.row-contract-length > .col:not(.col.col-radio-buttons) {
      width: calc(70% / 4 - 15px);
    }
    &.row-contract-expire > .col:not(.col.col-radio-buttons) {
      width: calc(70% / 6 - 15px);
    }
  }

  .row-buttons {
    align-items: center;
    margin: 80px 0 0;
    .icon-add {
      margin-right: 15px;
    }
    .buttons {
      width: 50%;
      margin-left: auto;
      justify-content: flex-end;
      .raised-button-container {
        flex: none;
        margin-left: 15px;
        &.btn-cancel {
          background: $color-white;
          color: $color-text;
          border: 1px solid $color-text;
        }
      }
    }
  }
  .client-forms-additional-contact-container,
  .client-forms-documents-and-contract-container {
    display: none;
  }
  .client-forms-main-container {
    padding: 0;
    box-shadow: none;
    > h2,
    .col-activity,
    .col-connected-client {
      display: none;
    }
    > .row:last-child {
      display: none;
    }
  }
}
