@import "base";

.screen-interests-container {
    height: calc(100% - 53px - 2vw);
    margin: 1vw 2vw;
    @include card;
    .screen-interests-header {
        @include card($background: $color-cream);
        padding: 0 24px;
        height: 60px;
        h2 {
            margin: 0;
            font-size: 1.25rem;
            line-height: 1.25rem;
            font-weight: 700;
        }
        .inputs-datepicker-container {
            flex: none;
            margin-left: 15px;
            > .icon.icon-calendar-custom {
                display: none;
            }
            .react-datepicker-wrapper {
                p {
                    font-weight: 500;
                }
                .icon-calendar-custom {
                    margin: 0 10px;
                    height: 25px;
                    width: 25px;
                    position: relative;
                }
            }
        }
        .row-buttons {
            margin-left: auto;
            .btn-generate {
                flex: none;
                font-size: 0.81rem;
                background-color: $color-orange;
                color: $color-white;
            }
        }
        .raised-button-container {
            flex: none;
            font-size: 0.81rem;
            &.btn-other-actions {
                background-color: #efeeed;
                color: $color-text;
                margin-right: 15px;
            }
        }
    }
    .screen-interests-content {
        height: calc(100% - 60px - 42px);
        min-width: 100%;
        .table-container {
            .icon.icon-arrow-down {
                transition: 0.3s;
                &.rotate {
                    transform: rotate(180deg);
                }
            }
            .table-row-cell.interest {
                .inputs-text-labeled-edit-container {
                    max-width: calc(100% - 14px);
                    .text-input-container {
                        max-width: 100%;
                        .text-input {
                            height: 25px;
                        }
                    }
                    .row.row-buttons {
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
    .screen-interests-footer {
        @include card($background: #f7f7f7);
        height: 42px;
        overflow: hidden;
        .screen-interests-inner-footer {
            border-radius: 6px;
            background: $color-blue-dark;
            padding: 0 1vw;
            p {
                color: $color-white;
                font-size: 1.125rem;
                line-height: 32px;
                margin: 5px 1vw 5px 0;
                &:not(:last-child) {
                    padding-right: 1vw;
                    border-right: 1px solid $color-white;
                }
            }
        }
    }
}

.popup-client-documents-container {
    .collapsable-exports {
        overflow: hidden;
        transition: all 0.4s ease-in;
        height: 0;
        & + p {
            margin-top: 0;
        }
        p {
            cursor: pointer;
            margin-left: 15px;
            &:active {
                transform: scale(0.98);
            }
            &:first-child {
                margin-top: 0;
            }
        }
        &.active {
            height: auto;
        }
    }
    .btn-interests-payments {
        padding: 0;
        box-shadow: none;
        justify-content: flex-start;
        height: auto;
    }
}
