@import "../../../base";
.inputs-search-multi-container {
  position: relative;
  margin-right: 100px;
  flex: 1;
  .inputs-search-multi-container-row-buttons {
    border: 1px solid rgba(0, 52, 112, 0.3);
    border-left: none;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    height: 38px;
    background-color: $color-white;
    .icon-search-custom {
      width: 15px;
      height: 15px;
      min-width: 0;
      margin-right: 10px;
    }
    .dropdown-container {
      border-left: 1px solid rgba(0, 52, 112, 0.3);
      padding: 0 10px;
      font-size: 0.81rem;
      .icon-arrow-down {
        width: 17px;
        height: 17px;
        min-width: 0;
        margin-left: 10px;
      }
    }
  }

  .text-input-container {
    max-width: 50%;
    .text-input {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
