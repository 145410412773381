@import '../../../base';

.card-shortcuts-container {
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  .card-shortcuts-header {
    height: 45px;
    padding: 0 1vw;
    align-items: flex-start;
    h3 {
      font-size: 1.675rem;
      line-height: 2rem;
      font-weight: 500;
      margin: 0;
      margin-right: 15px;
    }
    .icon-add {
      margin-left: auto;
    }
  }
  .card-shortcuts-content {
    height: calc(100% - 45px);
    overflow: scroll;
    flex-wrap: wrap;
    padding: 0 1vw;
    justify-content: space-between;
  }
}
