@import "base";

.references-clients-groups-container {
    height: 100%;
    width: 100%;
    .references-clients-groups-content {
        height: calc(100% - 42px);
        // .table-container {
        //     .icon.icon-arrow-down {
        //         transition: 0.3s;
        //         &.rotate {
        //             transform: rotate(180deg);
        //         }
        //     }
        // }
    }
    .references-clients-groups-footer {
        @include card($background: #f7f7f7);
        height: 42px;
        overflow: hidden;
        .references-clients-groups-inner-footer {
            border-radius: 6px;
            background: $color-blue-dark;
            padding: 0 1vw;
            p {
                color: $color-white;
                font-size: 1.125rem;
                line-height: 32px;
                margin: 5px 1vw 5px 0;
                &:not(:last-child) {
                    padding-right: 1vw;
                    border-right: 1px solid $color-white;
                }
            }
        }
    }
}
