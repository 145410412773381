@import "../../../base";

.card-invoice-container {
  height: 100%;
  padding: 15px 0;
  .card-invoice-header {
    padding: 0 1vw;
    height: 40px;
    h3 {
      font-size: 1.25rem;
      font-weight: 500;
      margin: 0;
    }
    .icon-edit,
    .icon-open {
      background-size: 70%;
      margin-left: 15px;
    }
    .raised-button-container {
      flex: none;
      margin-left: 30px;
      &.cancel {
        background: $color-white;
        border: 1px solid $color-blue-dark;
        color: $color-blue-dark;
      }
    }
    .icon-container {
      margin-left: auto;
      transform: rotate(45deg);
    }
  }
  .card-invoice-content {
    position: relative;
    height: calc(100% - 40px);
    overflow: scroll;
    .row-interest-payments {
      padding: 0 1vw;
      margin: 20px 0;
      .icon.icon-open {
        background-size: 70%;
        margin-left: 15px;
      }
    }
    p.generated {
      padding: 0 1vw;
      margin: 20px 0;
    }
    .underlined-main {
      margin: 20px 0;
      .underlined-container {
        justify-content: flex-start;
      }
      .active-underlined-selector {
        background-color: $color-orange;
        &.selector-add-height {
          height: 2px;
        }
      }
    }
    .row-invoice {
      background: $color-cream;
      justify-content: space-between;
      padding: 10px calc(1vw + 40px) 10px 1vw;
      margin: 15px 0;
      font-weight: 700;
      text-transform: uppercase;
      height: 35px;
      &:first-child {
        margin: 0;
      }
      .inputs-text-labeled-container {
        > span {
          top: 4px;
        }
        .text-input {
          margin: 0;
          max-height: 30px;
        }
      }
    }
    .shared-collapseable-row-container {
      margin: 15px 0;
    }
  }
}
