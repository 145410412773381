@import "base";

.modal-container.generateNotificationsAnnexes {
    position: relative !important;
    .modal-generate-notifications-annexes-container {
        .modal-generate-notifications-annexes-header {
            justify-content: space-between;
        }
        .modal-generate-notifications-annexes-content {
            span {
                font-weight: 700;
            }
        }
        .raised-button-container {
            flex: none;
            margin-left: auto;
        }
    }
}
