@import "../../base";

.screen-references-container {
  height: calc(100% - 53px - 2vw);
  margin: 1vw 2vw;
  &:not(.market) {
    @include card;
  }
  &.invoiceNumber .screen-references-header {
    .inputs-dropdown-container {
      margin-right: 15px;
    }
    .row .image-upload-input {
      background-color: $color-orange;
      color: $color-white;
      margin-left: 15px;
    }
  }

  &.erp-measures .screen-references-header {
    .row-export {
      margin-left: auto;
      span {
        font-weight: 500;
      }
    }
  }
  &.commissions .screen-references-header {
    .inputs-datepicker-container {
      margin-left: 15px;
    }
    .raised-button-container.btn-cancel {
      background: $color-white;
      color: $color-text;
      border: 1px solid $color-blue-dark;
      margin-right: 15px;
    }
    > .row-buttons {
      .icon.icon-export,
      .raised-button-container {
        margin-left: 15px;
        margin-right: 0;
      }
    }
    > .row-options {
      flex: 1;
      justify-content: space-between;
      .raised-button-container {
        flex: none;
      }
    }
  }
  &.nomenclatures .screen-references-header,
  &.market .screen-references-header {
    .radio-buttons-container {
      margin-left: 15px;
    }
    .image-upload-input {
      margin-left: auto;
      background: $color-orange;
      color: $color-white;
    }
  }
  &.clientsGroups .screen-references-header .inputs-search-multi-container {
    width: 300px;
    flex: none;
    margin-left: 14px;
  }
  &.clientsGroups .screen-references-header .icon.icon-add,
  &.compensationSettings .screen-references-header .icon.icon-add {
    border-radius: 6px;
    background-size: 70%;
    margin-left: auto;
    height: 34px;
    width: 34px;
  }
  &.imports .screen-references-header {
    .radio-buttons-container,
    .inputs-datepicker-container {
      margin-left: 15px;
    }
  }
  .screen-references-header {
    @include card($background: $color-cream);
    padding: 0 24px;
    height: 60px;
    h2 {
      margin: 0;
      font-size: 1rem;
      line-height: 1.25rem;
      font-weight: 500;
      margin-right: 15px;
    }
    .inputs-dropdown-container {
      flex: 1;
      max-width: 16%;
    }
    .row-buttons,
    .btn-add-employee,
    .btn-invoices-generate {
      flex: none;
      margin-left: auto;
    }
    .row-buttons .icon-export {
      margin-right: 15px;
    }

    .inputs-rounded-buttons-container {
      margin-left: 15px;
    }
    .icon-export {
      height: 35px;
      width: 35px;
      border: 2px solid $color-blue-dark;
      background-color: $color-white;
      border-radius: 6px;
      background-size: 70%;
      margin-left: auto;
    }
  }
  .screen-references-content {
    height: calc(100% - 60px);
    width: 100%;
    justify-content: center;
    p.empty {
      font-size: 1.12rem;
      font-weight: 300;
    }
  }
}
