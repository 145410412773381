@import "../../../../base";

.client-cells-documents-erp-container {
  width: 100%;
  justify-content: space-between;
  padding-right: 15px;
  .raised-button-container {
    background-color: $color-white;
    color: $color-text;
    border: 1px solid $color-text;
    height: 24px;
  }
}
