@import "../../../base";
.modal-container.clientEditMOL {
  position: relative !important;
  width: 40%;
  .modal-client-edit-MOL-container {
    overflow: hidden;
    width: 100%;
    height: 100%;
    .icon-container {
      transform: rotate(45deg);
    }
    .modal-client-edit-MOL-header {
      h2 {
        font-size: 1.5rem;
        line-height: 1.8rem;
        font-weight: 500;
      }
      .icon-container {
        margin-left: auto;
      }
    }
    .modal-client-edit-MOL-content {
      margin: 15px 0;
      max-height: 400px;
      overflow-y: auto;
      overflow-x: hidden;
      .single-mol {
        margin-bottom: 10px;
        .col {
          width: calc(100% / 3);
          margin-right: 15px;
          &.col-double {
            width: calc(100% / 3 * 2);
          }
          .required {
            border-color: $color-white;
            box-shadow: 0 0 3px $color-red;
          }
        }
      }
    }
    .raised-button-container {
      margin-left: auto;
      flex: none;
    }
  }
}
