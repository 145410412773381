.task-management-underlined-tab-container {
    position: relative;
    .underlined-tab {
        cursor: pointer;
        padding: 10px 16px;
        font-size: 14px;
    }
    .active-underlined-selector-container {
        background-color: #efeeed;
        width: 100%;
        height: 5px;
    }
    .active-underlined-selector {
        position: absolute;
        height: 0px;
        width: 150px;
        bottom: 0px;
        left: 0px;
        transition: all 0.3s ease-in-out;
        background-color: var(--main-color);
        &.selector-add-height {
            height: 5px;
        }
    }
}
