@import '../../../base';

.popup-settings-container {
  padding: 0 15px;
  p {
    cursor: pointer;
    font-weight: 300;
    &:active {
      transform: scale(0.95);
    }
  }
  .icon {
    margin-left: 15px;
    height: 20px;
    width: 20px;
  }
}
