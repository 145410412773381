@import "base";

.references-producers-invoices-container {
    .references-producers-invoices-header {
        .inputs-search-multi-container {
            flex: none;
        }
        > .row-buttons {
            margin-left: auto;
            > div {
                margin-left: 15px;
            }
            .raised-button-container {
                flex: none;
                font-size: 0.81rem;
                &.btn-other-actions {
                    background-color: #efeeed;
                    color: $color-text;
                }
                &.btn-import {
                    border: 1px solid $color-orange;
                    background: $color-white;
                    color: $color-orange;
                }
            }
        }
    }
    .references-producers-invoices-content {
        .row-invoiceSend {
            width: calc(100% - 14px);
            .icon.icon-info-custom {
                margin-left: auto;
                margin-right: 14px;
                height: 20px;
                width: 20px;
            }
            .raised-button-container {
                margin: 0;
                background-color: $color-white;
                color: $color-text;
                border: 1px solid $color-text;
                height: 24px;
                padding: 0 5px;
                margin-left: 10px;
            }
        }
    }
    .references-producers-invoices-footer {
        @include card($background: #f7f7f7);
        height: 42px;
        overflow: hidden;
        .references-producers-invoices-inner-footer {
            border-radius: 6px;
            background: $color-blue-dark;
            padding: 0 1vw;
            p {
                color: $color-white;
                font-size: 1.125rem;
                line-height: 32px;
                margin: 5px 1vw 5px 0;
                &:not(:last-child) {
                    padding-right: 1vw;
                    border-right: 1px solid $color-white;
                }
            }
        }
    }
}
