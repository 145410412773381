@import "../../../../base";

.client-forms-documents-and-contract-container {
  @include card;
  padding: 10px 24px;
  z-index: 3;
  h2 {
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 500;
  }
  .required {
    border-color: $color-white;
    box-shadow: 0 0 3px $color-red;
  }
  > .row {
    width: 100%;
    margin: 15px 0;
    align-items: flex-end;
    .drag-and-drop .drag-and-drop-overlay {
      border-radius: 6px;
      .drag-and-drop-overlay-inner {
        font-size: 8px;
        transform: translateY(-50%);
      }
    }
    > .col {
      width: calc(20% - 15px);
      margin-right: 15px;
      &.col-double {
        width: calc(40% - 15px);
      }
      &.col-triple {
        width: calc(60% - 15px);
      }
      &.col-radio-buttons {
        width: calc(35% - 15px);
      }
      .text-input {
        max-width: 100%;
      }
      &.col-switch.disabled .particles-switch-button,
      &.col-merchant.disabled .icon {
        cursor: default;
        .icon-add:active {
          transform: none;
        }
      }
      &.col-merchant > .row {
        margin: 10px 0;
        height: 36px;
        .icon-add {
          margin-right: 6px;
        }
        > .row {
          flex: 1;
          overflow: auto;
          .single-person-container {
            margin-right: 12px;
            .profile-image-container {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              margin-right: 6px;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }
            p {
              white-space: nowrap;
              font-weight: 700;
              margin: 0;
            }
          }
        }
      }
      .radio-buttons-container {
        // flex-wrap: wrap;
        height: 36px;
        margin: 10px 0;
      }
    }
    &.row-contract-type,
    &.row-contract-length,
    &.row-contract-expire {
      > .row {
        width: 70%;
        align-items: flex-end;
        > .col {
          &:not(.col-declaration-dealer) {
            flex: 1;
          }
          margin-right: 15px;
        }
      }
    }

    &.row-files {
      .row-file {
        span {
          line-height: 3.625rem;
          white-space: nowrap;
          overflow: scroll;
          @include remove-scrollbars;
          max-width: 50%;
        }
        .icon-container {
          transform: rotate(45deg);
          width: auto;
        }
      }
      .image-upload-input {
        box-shadow: none;
        color: grey;
        text-decoration: underline;
        font-size: 0.875rem;
        line-height: 1.125rem;
        padding: 0;
        margin: 10px 0;
        justify-content: flex-start;
        .icon {
          margin-left: 10px;
          width: 16px;
          height: 16px;
        }
        &.required {
          color: $color-red;
        }
        &.disabled {
          cursor: default;
          .ripple::after {
            display: none;
          }
        }
      }
    }
    .row-auto-sign .inputs-text-labeled-container {
      margin-left: 30px;
      max-width: 30%;
      .text-input {
        padding-right: 35px;
      }
    }

    .particles-switch-button {
      height: 36px;
      margin: 10px 0;
      justify-content: flex-start;
      .switch-label {
        display: none;
      }
    }
    .icon-container {
      height: 38px;
      margin: 10px 0;
    }
  }
  .row-buttons {
    justify-content: flex-end;
    margin: 80px 0 0;

    .raised-button-container {
      flex: none;
      margin-left: 15px;
      &.btn-cancel {
        background: $color-white;
        color: $color-text;
        border: 1px solid $color-text;
      }
    }
  }
}
