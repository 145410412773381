@import '../../../base';
.modal-container.task {
  position: relative !important;
  overflow: hidden;
  max-height: 95%;
  .modal-task-container {
    position: relative;
    padding: 0 60px;
    max-height: calc(95vh - 120px);
    .modal-task-status {
      position: absolute;
      height: calc(100% + 40px);
      width: 20px;
      left: -20px;
      top: -20px;
    }
    > .icon-container {
      position: absolute;
      top: 0;
      right: 0;
      transform: rotate(45deg);
    }

    P {
      &.bold,
      span {
        font-weight: 700;
      }
    }
    > .row {
      &:not(.row-assignees) {
        justify-content: space-between;
        > div {
          width: calc(50% - 30px);
        }
      }
      &:not(.row-client) {
        border-bottom: 1px solid $color-gray;
      }
    }
    .row-assignees,
    .row-assigner {
      .single-assignee {
        margin-right: 20px;
        .profile-image {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          margin-right: 5px;
        }
        p {
          font-weight: 700;
        }
      }
    }
    .row-assignees {
      position: relative;
      > p {
        margin-right: 15px;
      }
      .settings-container {
        position: absolute;
        left: -40px;
        height: 30px;
        width: 30px;
        justify-content: space-evenly;
        align-items: center;
        cursor: pointer;
        .settings-circle {
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: $color-text;
        }
      }
      .assignees-container {
        max-width: calc(100% - 120px);
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
    .row-assigner {
      > .row:first-child p {
        margin-right: 15px;
      }
      .icon-proprity {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        margin: 0 5px 0 15px;
      }
    }
    .task-description-container {
      position: relative;
      border: 2px solid $color-blue-dark;
      border-radius: 0 0 6px 6px;
      padding: 10px 15px;
      margin: 10px 0;
      .icon {
        position: absolute;
        top: 10px;
        right: 15px;
      }
      h2 {
        padding-bottom: 10px;
        margin-top: 0;
        width: calc(100% - 60px);
        border-bottom: 1px solid $color-gray;
      }
    }
    .row-buttons .raised-button-container {
      max-width: calc(50% - 30px);
      &.btn-complete {
        background-color: #00cb5e;
      }
      &.btn-work {
        background-color: $color-white;
        color: $color-text;
      }
    }
    .modal-task-dynamic-container {
      flex: 1;
      height: calc((95vh - 120px) * 0.3);
      min-height: 220px;
      .underlined-main {
        flex: none;
        .active-underlined-selector {
          background-color: $color-orange;
          &.selector-add-height {
            height: 3px;
          }
        }
      }
      .modal-task-dynamic-content {
        flex: 1;
        overflow: scroll;
      }
    }
  }
}
