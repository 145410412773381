@import "base";
.card-client-history-single-container {
  position: relative;
  padding: 0 20px 80px;

  .row-main .icon-arrow-down {
    height: 20px;
    width: 20px;
    min-width: auto;
    margin-left: 15px;
    transition: 0.4s;
    &.rotate {
      transform: rotate(180deg);
    }
  }
  .changes-container {
    overflow: hidden;
    transition: all 0.4s ease-in;
    height: 0;
    &.active {
      height: auto;
    }
    ul {
      margin: 10px 0 0;
      li {
        margin-bottom: 8px;
        p {
          margin: 0;
          span {
            font-weight: 500;
          }
        }
      }
    }
  }

  p.time {
    margin: 0 0 10px;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.375rem;
  }
  p.name {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 700;
    margin: 0;
  }
  div.values p,
  p.from {
    font-size: 1.12rem;
    line-height: 1.375rem;
    font-weight: 300;
    margin: 10px 0 0;

    span {
      font-weight: 500;
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: -12px;
    transform: translate(-50%);
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: $color-text;
  }
}
