@import "../../../base";

.card-consumption-summary-container {
  @include card;
  padding: 20px 0;
  z-index: 2;
  h2 {
    padding: 0 24px;
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 500;
    margin: 0;
  }
}
