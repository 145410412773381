@import '../../../base';
.card-checklist-container {
  height: calc(100% - 10px);

  .card-checklist-header {
    height: 15%;
    .row {
      justify-content: space-between;
      .icon-container {
        // border: 1px dashed $color-green;
        border-radius: 10px;
        width: auto;
        height: auto;
      }
    }
    .progress {
      border-radius: 5px;
      margin: 10px 0;
      height: 5px;
      width: 100%;
      background-color: #edf8e7;
      .progress-inner {
        border-radius: 5px;
        transition: width 1s linear;
        height: 5px;
        background-color: $color-green;
      }
    }
  }
  .card-checklist-content {
    height: 85%;
    &.collapse {
      height: calc(85% - 65px);
      transition: height 0.3s linear;
    }
    overflow: scroll;
    padding-top: 10px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .single-item {
      margin: 5px 0;
      padding: 5px 0;
      p {
        margin: 5px 0;
      }
    }
  }
  .card-checklist-input {
    padding-bottom: 5px;
    .raised-button-container {
      flex: none;
      min-width: 30%;
      margin-left: 30px;
    }
  }
}
