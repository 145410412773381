@import "../../base";

.screen-producers-invoice-form-container {
    height: calc(100% - 53px - 2vw);
    margin: 1vw 2vw;
    @include card;
    .screen-producers-invoice-form-header {
        @include card($background: $color-cream);
        padding: 0 24px;
        height: 60px;
        h2 {
            font-size: 1.25rem;
            line-height: 2rem;
            font-weight: 500;
            margin-right: 15px;
        }
        .row-buttons {
            margin-left: auto;
            .btn-clear {
                background-color: $color-white;
                border: 1px solid $color-blue-dark;
                color: $color-blue-dark;
                // margin: 0 15px;
            }
            .raised-button-container {
                margin-right: 15px;
                white-space: nowrap;
                flex: none;
            }
        }
    }
    .screen-producers-invoice-form-content {
        height: calc(100% - 60px);
        overflow: auto;
        > div {
            border: 1px solid #00347036;
        }
        .screen-producers-invoice-form-object-container,
        .screen-producers-invoice-form-pricing-container {
            @include card;
            padding: 5px 24px;
            &.required {
                border: 1px solid $color-red;
            }
            .screen-producers-invoice-form-object-header,
            .screen-producers-invoice-form-pricing-header {
                h2 {
                    font-size: 1.25rem;
                    line-height: 2rem;
                    font-weight: 500;
                    margin: 5px 0;
                }
            }
            .screen-producers-invoice-form-object-content,
            .screen-producers-invoice-form-pricing-content {
                width: 100%;
                align-items: flex-end;
                margin: 15px 0;
                .col {
                    width: calc(20% - 15px);
                    margin-right: 15px;
                    .required {
                        border-color: $color-white;
                        box-shadow: 0 0 3px $color-red;
                    }
                }
            }
        }
    }
}
