.modal-container {
  width: 70%;
  position: absolute;
  inset: 30px 50px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: none;
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}
