@import "base";

.invoices-forms-total-container {
    @include card;
    z-index: 5;
    .invoices-forms-total-header {
        padding: 5px 24px;
        h2 {
            font-size: 1.25rem;
            line-height: 2rem;
            font-weight: 500;
            margin: 5px 0;
        }
    }
    // .invoices-forms-total-consumption,
    // .invoices-forms-total-delivery {
    //     .invoices-forms-total-single-consumption
    // }

    .row-total {
        padding: 0 24px;
        background-color: $color-cream;
        margin-bottom: 40px;
        p {
            font-weight: 700;
        }
        .inputs-text-labeled-container {
            width: calc(100% / 6 - 15px);
            flex: none;
            margin-left: auto;
            margin-right: 15px;
        }
    }

    .invoices-forms-total-single-consumption,
    .invoices-forms-total-consumption-header,
    .invoices-forms-total-single-delivery,
    .invoices-forms-total-delivery-header {
        padding: 0 24px;
        > span,
        > .col {
            width: calc(100% / 6 - 15px);
            margin-right: 15px;
            &:nth-child(1),
            &:nth-child(3) {
                width: calc(100% / 12 - 15px);
            }
            &:nth-child(2) {
                width: calc(100% / 3 - 15px);
            }
            &.unit .text-input {
                padding-right: 11px;
            }
        }
    }

    .invoices-forms-total-consumption-header,
    .invoices-forms-total-delivery-header {
        padding: 5px 24px;
        background-color: #00347011;
    }

    .invoices-forms-total-single-consumption,
    .erp-container .invoices-forms-total-single-delivery {
        &:nth-child(odd) {
            background-color: #00347011;
        }
    }
}
