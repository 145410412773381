@import "base";

.screen-tutorials-container {
    height: calc(100% - 53px);
    padding: 1vw 2vw;
    .screen-tutorials-inner-container {
        height: 100%;
        justify-content: space-between;
        .screen-tutorials-navigation {
            width: calc(95vw * 0.25);
            height: 100%;
            .single-tab {
                @include card($border-radius: 6px);
                line-height: 1.375rem;
                text-transform: uppercase;
                margin-bottom: 15px;
                padding: 15px;
                transition: 0.3s ease;
                transition: margin 0s ease;
                justify-content: space-between;
                .icon-arrow-right,
                .icon-arrow-right-white {
                    transition: 0.3s ease;
                    height: 20px;
                    width: 20px;
                }
                &.selected {
                    background-color: $color-blue-dark;
                    color: $color-white;
                    font-weight: 700;
                }
                &.no-margin {
                    margin-bottom: 0px;
                    &.selected .icon-arrow-right-white {
                        transform: rotate(90deg);
                    }
                }
            }
            .sections-container {
                @include card;
                margin-bottom: 15px;
                padding: 15px 0 5px;
                width: 100%;

                max-height: 400px;
                overflow: auto;
                .sections-single-container {
                    line-height: 1.375rem;
                    margin-bottom: 10px;
                    justify-content: space-between;
                    padding: 5px 15px;
                    transition: 0.3s ease;
                    cursor: pointer;
                    .icon-arrow-right {
                        transition: 0.3s ease;
                        height: 20px;
                        width: 20px;
                    }
                    &.selected {
                        background-color: $color-cream;
                    }
                }
            }
        }
        .screen-tutorials-content {
            @include card;
            align-self: flex-start;
            width: calc(95vw * 0.75);
            max-height: 100%;
            overflow: auto;
            padding: 0 24px 20px;
            &.empty {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            h2 {
                margin: 20px 0;
                font-weight: 700;
            }
            video {
                width: 100%;
                object-fit: contain;
            }
        }
    }
}
