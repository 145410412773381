@import "../../../base";
.modal-container.newEmployee {
  position: relative !important;
  max-height: 90vh;
  width: 40%;
  .modal-new-employee-container {
    .modal-new-employee-header {
      margin-bottom: 30px;
      h2 {
        font-size: 1.37rem;
        line-height: 1.625rem;
        font-weight: 500;
        margin: 0;
      }
      .icon-container {
        margin-left: auto;
        transform: rotate(45deg);
      }
    }
    .modal-new-employee-content {
      .required {
        border-color: $color-white;
        box-shadow: 0 0 3px $color-red;
      }
      > .row .col {
        width: 50%;
        &:not(:last-child) {
          width: calc(50% - 30px);
          margin-right: 30px;
        }
      }
      .disabled {
        position: relative;
        > div,
        > span {
          opacity: 0.5;
        }
        .overlay {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 1;
          cursor: not-allowed;
        }
      }
    }
  }
}
