@import '../../../base';

.modal-container.invoiceComment {
  position: relative !important;
  width: 40%;
  .modal-invoice-comment-container {
    .modal-invoice-comment-header {
      position: relative;
      h2 {
        font-size: 1.37rem;
        line-height: 1.625rem;
        font-weight: 500;
        text-align: center;
      }
      .icon-container {
        position: absolute;
        top: -10px;
        right: 0;
        height: auto;
        width: auto;
        transform: rotate(45deg);
      }
    }
    .modal-invoice-comment-content {
      margin: 30px 0;
    }
    .raised-button-container.no-flex {
      flex: none;
    }
  }
}
