@import "../../../../base";

.client-forms-additional-contact-container {
  @include card;
  padding: 10px 24px;
  z-index: 4;
  .icon-add {
    margin: 14px 0 14px auto;
  }
  h2 {
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 500;
  }
  .required {
    border-color: $color-white;
    box-shadow: 0 0 3px $color-red;
  }
  > .row {
    width: 100%;
    margin: 15px 0;
    align-items: flex-end;
    &.row-header {
      align-items: center;
      .row-add {
        margin-left: auto;
        .icon-add {
          margin-left: 10px;
        }
      }
      h2 {
        margin-right: 30px;
      }
    }
    > .col {
      width: calc(100% / 6 - 15px);
      margin-right: 15px;
      &.col-switch {
        width: calc(100% / 8 - 15px);
      }
      &.col-switch.disabled .particles-switch-button {
        cursor: default;
      }
      .particles-switch-button {
        height: 36px;
        margin: 10px 0;
        align-self: flex-start;
        .switch-label {
          display: none;
        }
      }
    }
    .icon-container {
      transform: rotate(45deg);
      margin: 14px 0;
    }
  }
}
