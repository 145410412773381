.shared-back-button-container {
    flex: 1;
    height: 100%;
    max-width: 263px;
    cursor: pointer;
    > img {
        width: 80%;
        height: auto;
        object-fit: contain;
    }
    p {
        font-size: 1.12rem;
        line-height: 2.125rem;
    }
    .icon {
        height: 34px;
        width: 34px;
    }
    &:active {
        transform: scale(0.95);
    }
}
