@import "base";
.references-intermediate-invoices-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .references-intermediate-invoices-content {
        height: calc(100% - 42px);
    }
    .references-intermediate-invoices-footer {
        @include card($background: #f7f7f7);
        height: 42px;
        overflow: hidden;
        > .row {
            border-radius: 6px;
            background: $color-blue-dark;
            height: 42px;
            padding: 0 1vw;
        }
        p {
            color: $color-white;
            font-size: 1.25rem;
            line-height: 42px;

            &.bold {
                font-weight: 700;
            }
            &:not(:first-child) {
                border-left: 1px solid $color-white;
                padding-left: 30px;
                margin: 0 0 0 30px;
            }
        }
    }
}
