@import '../../../base';

.employee-leave-container {
  height: 100%;
  .employee-leave-header {
    justify-content: space-between;
    > p {
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 300;
      span {
        font-weight: 700;
      }
      &:not(:nth-last-child(2)) {
        border-right: 1px solid $color-text;
        padding-right: 5px;
      }
    }
    .raised-button-container {
      flex: none;
    }
  }
  .employee-leave-container {
    height: calc(100% - 60px);
    overflow: scroll;
    padding-top: 10px;
    .single-leave {
      p {
        font-size: 1rem;
        line-height: 1.375rem;
        span,
        &.assignee-fullName {
          font-weight: 700;
        }
        &.date {
          font-size: 0.875rem;
        }
        &.no-margin-bottom {
          margin-bottom: 0;
        }
      }
      .single-assignee .profile-image {
        margin: 0 5px 0 15px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $color-text;
      }
    }
  }
}
