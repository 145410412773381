@import '../../../base';

.employee-main-info-container {
  padding: 10px 12px;
  @include card;
  z-index: 1;
  .employee-main-info-header {
    .profile-image {
      height: 50px;
      width: 50px;
      margin-right: 10px;
    }
    h2 {
      font-size: 1.37rem;
      line-height: 1.8rem;
      font-weight: 500;
    }

    .raised-button-container {
      flex: none;
      margin-left: auto;
    }
  }
  .employee-main-info-content {
    justify-content: space-between;
    border-bottom: 1px solid $color-text;
    p {
      font-size: 1.12rem;
      font-weight: 500;
      line-height: 1.375rem;
    }
  }
  .employee-main-info-footer {
    justify-content: space-between;
    p {
      width: 48%;
      &:first-child {
        border-right: 1px solid $color-text;
      }
      span{
        font-weight: 500;
      }
    }
  }
}
