@import "../../base.scss";

.header-container {
  position: relative;
  @include card($border-radius: 0);
  width: 100%;
  height: 53px;
  padding: 0 2vw;
  justify-content: space-between;
  .header-logo,
  .header-links {
    height: 100%;
    justify-content: center;
    position: relative;
    a,
    p {
      @extend %flex;
      align-items: center;
      text-decoration: none;
      font-size: 1rem;
      height: 100%;
      padding: 0 5px;
      transition: font-weight 0.3s linear;
      cursor: pointer;
      &:not(:first-child) {
        margin-left: 50px;
      }
      &.active {
        font-weight: 700;
      }
    }
    p .icon {
      margin-top: 5px;
      margin-left: 5px;
      height: 10px;
      width: 10px;
    }
    .active-route-selector {
      position: absolute;
      height: 0px;
      width: 150px;
      bottom: 0px;
      left: 0px;
      transition: all 0.3s ease-in-out;
      background-color: $color-orange;
      height: 5px;
    }
  }
  .header-links {
    flex: 2;
  }
  .header-logo {
    flex: 1;
    height: 90%;
    justify-content: flex-start;
    img {
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .header-buttons {
    flex: 1;
    height: 100%;
    justify-content: flex-end;
    .icon-support-chat {
      position: relative;
      height: 30px;
      width: 30px;
      .support-status {
        position: absolute;
        top: -1px;
        right: -3px;
        width: 16px;
        height: 16px;
        background-color: #00cb5e;
        border-radius: 50%;
      }
    }
    .settings-container {
      height: 40px;
      width: 40px;
      justify-content: space-evenly;
      align-items: center;
      cursor: pointer;
      .settings-circle {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: $color-text;
      }
    }
  }
  .subheader {
    z-index: 3;
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    padding: 0 2vw;
    justify-content: center;
    @include card($border-radius: 0 0 6px 6px, $background: $color-cream);
    width: 100%;

    visibility: hidden;
    transition: 0.3s ease-in-out;
    height: 0;
    opacity: 0;
    &.active {
      height: 45px;
      visibility: visible;
      opacity: 1;
      .active-route-selector {
        height: 45px;
      }
    }
    a {
      padding: 0 10px;
      transition: 0.2s ease-in-out;
      // &.active {
      //   // color: $color-white;
      // }
    }
    .active-route-selector {
      height: 0px;
      top: 0;
      z-index: -1;
    }
  }
}
