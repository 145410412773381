@import "base";

.screen-annex-create-container {
  height: calc(100% - 53px);
  padding: 1vw 2vw;
  .screen-annex-create-header {
    @include card($border-radius: 6px);
    height: 52px;
    padding: 0 2vw;
    margin-bottom: 1vw;
    h2 {
      font-size: 1.37rem;
      line-height: 2rem;
      font-weight: 700;
    }
    h3 {
      font-size: 1.25rem;
      line-height: 2rem;
      margin-left: 40px;
      span {
        font-weight: 700;
      }
    }
    .row-buttons {
      margin-left: auto;
      .raised-button-container {
        margin-left: 30px;
        &.btn-cancel {
          background: $color-white;
          color: $color-text;
          border: 1px solid $color-text;
        }
      }
    }
  }
  .row.row-main {
    align-items: flex-start;
    justify-content: space-between;
    max-height: calc(100% - 1vw - 52px);
  }
  .screen-annex-create-navigation {
    width: calc(95vw * 0.25);
    height: 100%;
    .single-tab {
      @include card($border-radius: 6px);
      line-height: 1.375rem;
      text-transform: uppercase;
      margin-bottom: 15px;
      padding: 15px;
      transition: 0.3s ease;
      justify-content: space-between;
      .icon-arrow-right,
      .icon-arrow-right-white {
        height: 20px;
        width: 20px;
      }
      &.selected {
        background-color: $color-blue-dark;
        color: $color-white;
        font-weight: 700;
      }
    }
  }
  .screen-annex-create-content {
    @include card;
    width: calc(95vw * 0.75);
    max-height: calc(100vh - 3vw - 53px - 52px);
    overflow: scroll;
    @include remove-scrollbars;
    .upcoming-annexes {
      @include card($border-radius: 6px);
      padding: 10px 24px;
      box-shadow: 0 0 5px $color-orange;
      margin: 1px;
      h2 {
        font-size: 1.37rem;
        line-height: 2rem;
        font-weight: 700;
      }
      .upcoming-annexes-single {
        margin: 10px 0;
        span {
          margin-right: 30px;
          &:first-child {
            font-weight: 700;
            text-transform: capitalize;
          }
        }
      }
    }
  }
}
