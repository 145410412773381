@import "../../../base";
.checkboxes-container {
  .single-checkbox {
    margin-right: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    p {
      white-space: nowrap;
    }
    .checkbox-outer {
      border: solid 1px black;
      border-radius: 6px;
      width: 18px;
      min-width: 18px;
      height: 18px;
      margin-right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 150ms linear;

      .checkbox-inner {
        width: 0px;
        height: 0px;
        transition: all 150ms linear;
        background-image: url(../../../assets/icons/check.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    &.reversed {
      margin-right: 0;
      width: 100%;
      flex-direction: row-reverse;
      .checkbox-outer {
        margin-left: auto;
      }
    }
    &.selected {
      .checkbox-outer {
        border: solid 1px $color-text;
      }
      .checkbox-inner {
        width: 10px;
        height: 10px;
      }
      p {
        transition: all 150ms linear;
        font-weight: 700;
      }
    }
    .label-container {
      .button-image {
        width: 26px;
        height: 26px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}
