@import "../../../base";

.references-imports-container {
  width: 100%;
  height: calc(100% - 30px);
  overflow: hidden;
  .table-container .data {
    justify-content: space-between;
    .icon-container {
      transform: rotate(45deg);
      margin-left: auto;
      margin-right: 40px;
    }
  }
}
