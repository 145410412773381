@import '../../../base';
.modal-container.clientsLeaving {
  position: relative !important;
  width: 40%;
  .modal-clients-leaving-container {
    padding: 10px 20px;
    .modal-clients-leaving-header {
      h2 {
        font-size: 1.5rem;
        line-height: 1.8rem;
        font-weight: 500;
      }
      .icon-container {
        margin-left: auto;
        transform: rotate(45deg);
      }
    }
    .modal-clients-leaving-content {
      padding: 20px 0;
    }
    .raised-button-container {
      flex: none;
      margin-left: auto;
    }
  }
}
