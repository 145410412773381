@import "../../../base";

.modal-container.intermediateInvoicesPrice {
    position: relative !important;
    width: 60%;
    .modal-intermediate-invoices-price-container {
        .modal-intermediate-invoices-price-header {
            position: relative;
            h2 {
                font-size: 1.37rem;
                line-height: 1.625rem;
                font-weight: 500;
                text-align: center;
            }
            .icon-container {
                position: absolute;
                top: -10px;
                right: 0;
                height: auto;
                width: auto;
                transform: rotate(45deg);
            }
        }
        .row-percentLastMonth {
            margin: 30px 0;
            .particles-switch-button {
                height: 36px;
                margin: 10px 0;
                justify-content: flex-start;
                .switch-label {
                    display: none;
                }
            }
        }
        .modal-intermediate-invoices-price-content {
            margin: 30px 0;
            width: 100%;
            .col {
                flex: 1;
            }
        }
        .row-options {
            align-items: flex-start;
            > div {
                flex: 1;
            }
        }
        .spacer {
            width: 30px;
        }
        .raised-button-container.no-flex {
            flex: none;
        }
    }
}
