@import "base";
.screen-deposits-create-container {
    height: calc(100% - 53px);
    padding: 1vw 2vw;
    .screen-deposits-create-header {
        @include card($border-radius: 6px);
        height: 52px;
        padding: 0 2vw;
        margin-bottom: 2vw;
        h2 {
            font-size: 1.37rem;
            line-height: 2rem;
            font-weight: 700;
            margin: 0;
            margin-right: 60px;
        }
        .row-buttons {
            margin-left: auto;
            .inputs-datepicker-container {
                margin: 0 10px;
            }
        }
    }
    .screen-deposits-create-content {
        @include card($border-radius: 6px);
        height: calc(100% - 53px - 2vw);
        .screen-deposits-create-inner-content {
            height: calc(100% - 42px);
            overflow: hidden;

            .table-container .table-row-container .table-row-inner-container .table-row-cell {
                overflow-y: hidden;
                &.percent,
                &.value,
                &.additionalCharges {
                    .text-input {
                        padding-right: 65px;
                    }
                }
                .inputs-text-labeled-edit-container,
                .inputs-text-labeled-container {
                    max-width: calc(100% - 14px);
                    .text-input-container {
                        max-width: 100%;
                    }
                }
                &:last-child .inputs-text-labeled-edit-container {
                    max-width: calc(100% - 44px);
                }
            }
        }
        .screen-deposits-create-footer {
            @include card($background: #f7f7f7);
            height: 42px;
            background: $color-blue-dark;
            border-radius: 6px;
            width: min-content;
            p {
                white-space: nowrap;
                color: $color-white;
                font-size: 1.25rem;
                line-height: 42px;
                margin: 0;
                padding: 0 1vw;
                &:not(:last-child) {
                    border-left: 1px solid $color-white;
                }
            }
        }
    }
}
