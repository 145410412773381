@import '../../../base';

.popup-new-task-client-container {
  padding: 5px 10px;
  min-width: 300px;

  .icon-container {
    margin-left: auto;
    transform: rotate(45deg);
  }
  h4,
  p {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  h4 {
    font-weight: 700;
    margin-bottom: 10px;
    &.add-margin {
      margin-top: 20px;
    }
  }

  .scroll-container {
    max-height: 300px;
    overflow: scroll;
  }
  .text-input-container .text-input {
    margin: 0;
  }
  .raised-button-container {
    margin-top: 20px;
  }

  .label-container p {
    margin: 6px 0;
  }
}
