.task-management-inputs-datepicker-container {
    position: relative;
    .react-datepicker-wrapper {
        width: 100%;
    }
    .react-datepicker-wrapper input {
        border: 1px solid rgba(0, 52, 112, 0.3);
        display: block;
        width: 100%;
        border-radius: 5px;
        padding: 9px 11px;
        margin: 10px 0px;
        &:active,
        &:focus {
            outline: none;
        }
    }
    .tasks-icon-calendar {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        height: 18px;
        width: 18px;
    }
}
