.client-cells-invoice-groups-container {
  width: 100%;
  justify-content: space-between;
  padding-right: 15px;
  .icon {
    transition: 0.4s ease;
    &.rotate {
      transform: rotate(-180deg);
    }
  }
}
