@import '../../../base';

.card-activity-container {
  height: 100%;
  .card-activity-header {
    padding: 15px 30px;
    @include card($background: $color-blue-dark);
    .profile-image {
      width: 66px;
      height: 66px;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .text {
      margin: 0 16px;
      p,
      h2 {
        color: $color-white;
        line-height: 1.5rem;
        margin: 0;
      }
      h2 {
        font-size: 1rem;
        font-weight: 700;
      }
      p {
        font-size: 1rem;
        font-weight: 300;
      }
    }
  }
  .card-activity-content {
    flex: 1;
    overflow: hidden;
    padding-top: 20px;
    h3 {
      font-size: 1.37rem;
      line-height: 1.625rem;
      font-weight: 500;
      margin: 0.5rem 0;
    }
    .card-activity-content-scroll-container {
      flex: 1;
      padding-right: 20px;
      overflow: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      > div:not(:last-child) {
        border-bottom: 1px solid #c8c9cc;
      }
    }
  }
}
