@import "../../../../base";

.invoices-forms-main-container {
  @include card;
  padding: 5px 24px;
  .invoices-forms-main-header {
    h2 {
      font-size: 1.25rem;
      line-height: 2rem;
      font-weight: 500;
      margin: 5px 0;
    }
  }
  .invoices-forms-main-content {
    > .row {
      width: 100%;
      align-items: flex-end;
      margin: 15px 0;
      .col {
        width: calc(20% - 15px);
        margin-right: 15px;
        .required {
          border-color: $color-white;
          box-shadow: 0 0 3px $color-red;
        }
      }
    }
  }
}
