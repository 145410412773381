@import '../../../base';
.popup-new-task-assignees-container {
  padding: 5px 10px;
  width: 400px;
  .icon-container {
    margin-left: auto;
    transform: rotate(45deg);
  }

  h4,
  p {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  h4 {
    font-weight: 700;
    margin-bottom: 10px;
  }

  .label-container {
    p {
      margin: 10px 0;
    }
    .button-image {
      margin: 0 10px;
    }
  }
  .scroll-container {
    height: auto;
    max-height: 200px;
    overflow: scroll;
  }
  .row-assignees {
    margin-bottom: 15px;
    .single-assignee p {
      margin: 10px;
    }
  }

  .row-footer {
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    height: 60px;
    max-height: 60px;
    transition: 0.4s ease;
    transform-origin: bottom center;

    .raised-button-container {
      flex: none;

      &.btn-create-team,
      &.btn-cancel {
        background: $color-white;
        color: $color-text;
        border: 1px solid $color-text;
      }
    }

    .new-team-container {
      background: $color-white;
      position: absolute;
      height: 146px;
      bottom: 0;
      width: 100%;
      opacity: 0;
      z-index: -1;
      transition: 0.2s ease;
      .row-new-team-buttons {
        justify-content: space-between;
      }
      .text-input-container .text-input {
        margin: 0;
      }
    }
    &.show-new-team {
      height: 146px;
      max-height: 146px;
      .new-team-container {
        opacity: 1;
        z-index: auto;
      }
    }
  }
}
