@import "../../../base";
.references-reports-container {
  width: 100%;
  height: 100%;
  > .row {
    align-items: flex-start;
    max-height: 100%;
    overflow-x: scroll;
  }
}
