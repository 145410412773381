@import "../../../base";

.modal-container.connectedClientGroup {
  position: relative !important;
  width: 40%;
  .modal-connected-client-group-container {
    .modal-connected-client-group-header {
      justify-content: space-between;
      h2 {
        font-size: 1.37rem;
        line-height: 1.625rem;
        font-weight: 500;
        margin: 0;
      }
      .icon-container {
        transform: rotate(45deg);
      }
    }
  }
  .modal-connected-client-group-content {
    margin: 30px 0;
    .raised-button-container {
      height: 36px;
      margin-left: 30px;
      max-width: 30%;
    }
  }
}
