@import '../../../../base';

.client-forms-offer-container {
  @include card;
  padding: 10px 24px;
  h2 {
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 500;
  }
  .required {
    border-color: $color-white;
    box-shadow: 0 0 3px $color-red;
  }
  > .row {
    width: 100%;
    margin: 15px 0;
    align-items: flex-end;

    > .col {
      width: calc(20% - 15px);
      margin-right: 15px;
      &.col-double {
        width: calc(40% - 15px);
      }
      &.col-small {
        width: calc(60% / 4 - 15px);
      }
      .particles-switch-button {
        height: 38px;
        margin: 10px 0;
        justify-content: flex-start;
        .switch-label {
          display: none;
        }
      }
    }
    .text-input {
      max-width: 100%;
    }
    &.row-expire {
      .col {
        width: calc(70% / 4 - 15px);
        &.col-double {
          width: calc(30% - 15px);
        }
      }
    }
  }
  .row-buttons {
    justify-content: flex-end;
    margin: 80px 0 0;
    .raised-button-container {
      flex: none;
      margin-left: 15px;
      &.btn-cancel {
        background: $color-white;
        color: $color-text;
        border: 1px solid $color-text;
      }
    }
  }
}
