@import '../../../base';

.employee-documents-container {
  flex: 1;
  @include card;
  padding: 10px 12px;
  .employee-documents-header h2 {
    font-size: 1.37rem;
    line-height: 1.8rem;
    font-weight: 500;
  }

  .employee-documents-contract,
  .employee-documents-agrrement,
  .employee-documents-others {
    justify-content: space-between;
    padding: 10px 0;
    p {
      font-size: 1rem;
      line-height: 1.375rem;
      font-weight: 700;
    }
    .image-upload-input{
      border: 1px solid $color-blue-dark;
      color: $color-blue-dark;
    }
    &:not(:last-child){
      border-bottom: 1px solid $color-text;
    }
  }
}
