@import '../../../../base';

.single-shortcut-container {
  @include card;
  width: calc(50% - 15px);
  margin-bottom: 15px;
  justify-content: space-between;
  h3 {
    font-size: 1.12rem;
    line-height: 1.5rem;
    font-weight: 700;
    margin: 1rem 1vw;
  }
  p {
    font-size: 0.8755rem;
    span {
      font-weight: 700;
    }
  }
  .single-shortcut-details,
  .single-shortcut-footer {
    padding: 0 1vw;
    justify-content: space-between;
  }

  .single-shortcut-footer {
    @include card($background: $color-cream);
    .icon-arrow-right-2-white {
      background-color: $color-orange;
      border-radius: 50%;
      background-size: 60%;
      background-position: 5px 9px;
      &:active {
        transform: scale(0.95) rotate(-90deg);
      }
    }
  }
}
