.client-cells-edit-cell-container {
  width: 100%;
  justify-content: space-between;
  .icon-edit.add-margin {
    margin-right: 15px;
  }
  .inputs-text-edit-container {
    input,
    .react-popper-dropdown {
      height: 25px;
    }
  }
}
