.inputs-number-range-container {
    .input-range {
        margin: auto;
        width: 80%;
        padding:  20px 0;
        .input-range__label {
            color: #000;
        }
    }
}
