@import "../../../../base";

.client-forms-client-pricing-container {
    @include card;
    padding: 10px 24px;
    h2 {
        font-size: 1.25rem;
        line-height: 2rem;
        font-weight: 500;
    }
    .single-pricing {
        > .row {
            width: 100%;
            margin: 15px 0;
            align-items: flex-end;
            > .col {
                width: calc(20% - 15px);
                margin-right: 15px;
                .row-paymentDays > div:nth-child(1) {
                    width: calc(70% - 15px);
                    margin-right: 15px;
                }
                .row-paymentDays > div:nth-child(2) {
                    width: calc(30% - 15px);
                    margin-right: 15px;
                }
                .particles-switch-button {
                    height: 38px;
                    margin: 10px 0;
                    justify-content: flex-start;
                    .switch-label {
                        display: none;
                    }
                }
            }
        }
        &:not(:last-child) .divider {
            height: 1px;
            width: 100%;
            background-color: $color-text;
        }
    }
}
