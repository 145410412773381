@import "../../base";
.screen-intermediate-invoices-generate-container {
    height: calc(100% - 53px);
    padding: 1vw 2vw;
    .screen-intermediate-invoices-generate-header {
        @include card($border-radius: 6px);
        height: 52px;
        padding: 0 2vw;
        h2 {
            font-size: 1.37rem;
            line-height: 2rem;
            font-weight: 700;
            margin: 0;
            margin-right: 60px;
        }
        p {
            margin-right: 30px;
            span {
                font-weight: 700;
            }
            .icon {
                display: block;
                height: 15px;
                width: 15px;
                margin-left: 5px;
            }
        }
        .row-buttons {
            margin-left: auto;
            .icon-export {
                height: 35px;
                width: 35px;
                border: 2px solid $color-blue-dark;
                background-color: $color-white;
                border-radius: 6px;
                background-size: 70%;
                margin-right: 15px;
            }
            .raised-button-container {
                flex: none;
            }
        }
    }
    .screen-intermediate-invoices-generate-second-header {
        margin: 1vw 0;
        .inputs-rounded-buttons-container > .btn-outlined {
            margin: 0 15px 0 0;
        }
        .row-filter-client {
            margin-left: 50px;
            .inputs-text-labeled-container {
                margin: 0 15px;
                width: 250px;
                .text-input {
                    padding-right: 25px;
                }
            }
            .icon.icon-close {
                height: 15px;
                width: 15px;
                transform: translate(-40px);
            }
        }
    }
    .screen-intermediate-invoices-generate-content {
        height: calc(100% - 52px - 40px - 2vw - 52px);
        @include card;
        overflow: hidden;

        .table-container {
            .table-cell:not(.table-cell.table-row-cell) {
                width: 100%;
                justify-content: space-between;
                padding-right: 14px;
            }
            .icon.icon-eye-custom {
                position: relative;
                &::before {
                    content: "";
                    height: 26px;
                    width: 2px;
                    background: $color-blue-dark;
                    position: absolute;
                    bottom: 0px;
                    right: 9px;
                    transform: rotate(45deg) scale(1);
                }
            }
            .table-row-container .table-row-inner-container {
                .table-row-cell {
                    .inputs-text-labeled-edit-container {
                        max-width: calc(100% - 14px);
                        .text-input-container {
                            max-width: 100%;
                        }
                    }
                    .inputs-text-labeled-container {
                        max-width: calc(100% - 14px);
                    }

                    &.energy .text-input {
                        padding-right: 85px;
                    }
                    &.energyPrice .text-input,
                    &.energyPricePercent .text-input {
                        padding-right: 65px;
                    }
                    &.paymentDays .text-input {
                        padding-right: 70px;
                    }
                    &.invoiceLastMonthTotalWithoutVat .text-input,
                    &.energyTotal .text-input,
                    &.tzoPrice .text-input,
                    &.excisePrice .text-input,
                    &.delivery .text-input,
                    &.totalWithoutVat .text-input,
                    &.total .text-input {
                        padding-right: 30px;
                    }
                }

                .table-row-cell.fullName {
                    .icon-folder-custom {
                        margin-right: 10px;
                    }
                }

                .table-row-cell.energy,
                .table-row-cell.energyPrice,
                .table-row-cell.energyTotal,
                .table-row-cell.tzoPrice,
                .table-row-cell.excisePrice,
                .table-row-cell.delivery,
                .table-row-cell.totalWithoutVat,
                .table-row-cell.total,
                .table-row-cell.paymentDays {
                    overflow: hidden;
                }

                .table-row-cell.invoiceLastYear,
                .table-row-cell.invoiceLastMonth {
                    height: calc(100% + 20px);
                    transform: translateY(-10px);
                    padding-left: 0;
                    > .row {
                        cursor: pointer;
                        height: 100%;
                        width: 100%;
                        border-radius: 6px;
                        border: 1px solid transparent;
                        transition: border 0.4s;
                        padding: 0 5px;
                        .single-radio-button {
                            margin-right: 5px;
                        }
                        &.disabled,
                        &.disabled .single-radio-button {
                            cursor: default;
                        }
                        &.active {
                            border-color: $color-orange;
                            div:not(:last-child) p {
                                color: $color-orange;
                            }
                        }
                        > div {
                            &:not(:first-child) {
                                width: calc((100% - 28px) / 2);
                            }
                            &:last-child {
                                border-left: 1px solid #00347026;
                                padding-left: 10px;
                            }
                        }

                        p {
                            font-weight: 700;
                            margin: 0;
                        }
                        span {
                            font-size: 0.875rem;
                            color: gray;
                        }
                    }
                }
            }
        }
    }

    .screen-intermediate-invoices-generate-footer {
        @include card($background: #f7f7f7);
        height: 52px;
        overflow: hidden;
        > div {
            height: 100%;
            padding: 10px 15px;
            background-color: $color-blue-dark;
            &:not(:last-child) {
                border-right: 1px solid gray;
            }
        }
        p {
            font-size: 1.125rem;
            margin: 0;
            color: $color-white;
            line-height: 32px;
            span {
                font-weight: 700;
            }
        }
        .tab-errors {
            cursor: pointer;
        }
        .icon-arrow-right-2-white {
            height: 20px;
            width: 20px;
            min-width: 0;
            margin-left: 5px;
            cursor: default;
        }
    }
}
