@import '../../../base';

.modal-container.existingFileWarning {
  position: relative !important;
  width: 40%;
  .modal-existing-file-warning-container {
    .modal-existing-file-warning-header {
      position: relative;
      h2 {
        font-size: 1.37rem;
        line-height: 1.625rem;
        font-weight: 500;
        text-align: center;
      }
      .icon-container {
        position: absolute;
        top: -10px;
        right: 0;
        height: auto;
        width: auto;
        transform: rotate(45deg);
      }
    }
  }
  .modal-existing-file-warning-content {
    margin: 30px 0;
    align-items: center;
    text-align: center;
  }
}
