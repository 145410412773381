@import "base";
.references-invoice-number-container {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .inputs-text-edit-container {
        .text-input {
            max-height: 25px;
        }
    }

    .references-invoice-number-table-container {
        height: 110px;
    }
    .references-invoice-number-ajur-container {
        height: calc(100% - 110px - 42px);
        &.loading {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .references-invoice-number-ajur-footer {
        @include card($background: #f7f7f7);
        height: 42px;
        overflow: hidden;
        .references-invoice-number-ajur-inner-footer {
            border-radius: 6px;
            background: $color-blue-dark;
            padding: 0 1vw;
            p {
                color: $color-white;
                font-size: 1.125rem;
                line-height: 32px;
                margin: 5px 1vw 5px 0;
                &:not(:last-child) {
                    padding-right: 1vw;
                    border-right: 1px solid $color-white;
                }
            }
        }
    }
}
