@import "../../base";

.screen-invoices-container {
  height: calc(100% - 53px - 2vw);
  margin: 1vw 2vw;
  @include card;
  .screen-invoices-header {
    @include card($background: $color-cream);
    padding: 0 24px;
    height: 60px;
    h2 {
      margin: 0;
      font-size: 1.25rem;
      line-height: 1.25rem;
      font-weight: 700;
    }
    .inputs-datepicker-container {
      flex: none;
      > .icon.icon-calendar-custom {
        display: none;
      }
      .react-datepicker-wrapper .icon-calendar-custom {
        margin: 0 15px;
        height: 25px;
        width: 25px;
        position: relative;
      }
    }
    .inputs-search-multi-container {
      flex: 1;
      max-width: 20%;
    }
    > .row-buttons {
      margin-left: auto;
    }
    .raised-button-container {
      flex: none;
      font-size: 0.81rem;

      &.btn-other-filters,
      &.btn-other-actions,
      &.btn-generate {
        background: $color-white;
        color: $color-text;
        margin-right: 15px;
      }
      &.btn-other-actions {
        background-color: #efeeed;
      }
      &.btn-generate {
        border: 1px solid $color-text;
      }
    }
  }
  .screen-invoices-content {
    height: calc(100% - 60px - 42px);
    min-width: 100%;
    align-items: flex-start;
    .table-checkboxes-container {
      max-height: calc(100%) !important;
      .checkboxes-container .scroll-container {
        min-height: auto !important;
      }
    }
    .table-container {
      .table-header .icon-arrow-down {
        height: 15px;
        width: 15px;
        margin-left: 15px;
        min-width: auto;
      }
      .row.type .icon-folder-custom,
      .row.fullName .icon-folder-custom {
        margin-right: 15px;
      }
      .row.pdfFile .row,
      .row.invoiceNumber .row,
      .row.comments .row {
        width: 100%;
        padding-right: 15px;
        justify-content: space-between;
      }
      .row.pdfFile .row .icon-open {
        width: 20px;
        height: 20px;
      }
      .row-invoiceSend {
        width: 100%;
        .icon.icon-info-custom {
          margin-left: auto;
          margin-right: 14px;
          height: 20px;
          width: 20px;
        }
        .raised-button-container {
          margin: 0;
          background-color: $color-white;
          color: $color-text;
          border: 1px solid $color-text;
          height: 24px;
          padding: 0 5px;
          margin-left: 10px;
        }
      }
    }
  }
  .screen-invoices-footer {
    @include card($background: #f7f7f7);
    height: 42px;
    overflow: hidden;
    .screen-invoices-inner-footer {
      border-radius: 6px;
      background: $color-blue-dark;
      padding: 0 1vw;
      p {
        color: $color-white;
        font-size: 1.125rem;
        line-height: 32px;
        margin: 5px 1vw 5px 0;
        &:not(:last-child) {
          padding-right: 1vw;
          border-right: 1px solid $color-white;
        }
      }
    }
  }
}
.popup-send-container {
  min-width: 300px;
  padding: 5px 10px;
  .icon-container {
    margin-left: auto;
    transform: rotate(45deg);
  }
  h4,
  p {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  > p.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  > p:not(.disabled) {
    cursor: pointer;
    &:active {
      transform: scale(0.98);
    }
  }

  h4 {
    font-weight: 700;
    margin-bottom: 10px;
  }

  .collapsable-exports {
    overflow: hidden;
    transition: all 0.4s ease-in;
    height: 0;
    & + p {
      margin-top: 0;
    }
    p {
      cursor: pointer;
      margin-left: 15px;
      &:active {
        transform: scale(0.98);
      }
      &:first-child {
        margin-top: 0;
      }
    }
    &.active {
      height: auto;
    }
  }
  .image-upload-input{
    height: auto;
    padding: 0;
    display: block;
    box-shadow: none;
    margin-left: 15px;
    margin-bottom: 10px;
  }
}

.popup-invoices-table-filters-container {
  min-width: 200px;
  padding: 5px 10px;
  position: relative;
  .checkboxes-container {
    max-height: 200px;
    overflow-y: auto;
  }
  .raised-button-container {
    margin-left: 15px;
  }

  .label-container p {
    margin: 6px 0;
    line-height: 1.25rem;
  }

  h3 {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 700;
    margin: 10px 0;
  }
  p {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin: 10px 0;
    &.btn-clear {
      cursor: pointer;
      &:active {
        transform: scale(0.95);
      }
    }
  }
  > div:not(:last-child) {
    margin-bottom: 30px;
  }
}
