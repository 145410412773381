@import "base";

.modal-container.producerSettingsFromObject {
    position: relative !important;
    width: 60%;
    // height: 900px;
    padding: 0;
    .modal-producer-settings-from-object-container {
        height: 100%;
        padding: 20px 0;
        > div {
            padding: 0 20px;
        }
        .modal-producer-settings-from-object-header {
            justify-content: space-between;
            h2 {
                font-size: 1.37rem;
                line-height: 1.625rem;
                font-weight: 500;
                text-align: center;
            }
        }
        .modal-producer-settings-from-object-content {
            padding: 30px 20px;
            max-height: 800px;
            overflow: auto;
            .single-radio-button {
                .label-container {
                    .single-object-container {
                        @include card;
                        .single-object-header {
                            @include card;
                            z-index: 3;
                            font-weight: 700;
                            padding: 10px 20px;
                        }
                    }
                }
            }
        }
    }
}
