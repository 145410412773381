@import '../../../';

.inputs-percentage-split-container {
  max-width: 100%;
  .inputs-text-labeled-container {
    width: 50%;
    input {
      padding-right: 40px;
    }
    span{
      width: 20px;
      right: 10px;
      padding-left: 10px;
    }
    &:first-child input {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    &:last-child input {
      border-left: 0;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
}
