@import '../../../base';

.popup-filter-tasks-list-container {
  padding: 5px 10px;
  .icon-container {
    margin-left: auto;
    transform: rotate(45deg);
  }
  h4,
  p {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  > p {
    cursor: pointer;
    &:active {
      transform: scale(0.98);
    }
  }

  h4 {
    font-weight: 700;
    margin-bottom: 10px;
    &.add-margin {
      margin-top: 20px;
    }
  }

  .label-container p {
    margin: 6px 0;
  }
}
