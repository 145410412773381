@import "../../../base";

.card-client-groups-container {
  height: 100%;
  padding: 15px 20px 0;
  .card-client-groups-header {
    margin-bottom: 50px;
    h3 {
      font-size: 1.5rem;
      font-weight: 500;
      margin: 0;
    }
    .icon-container {
      margin-left: auto;
      transform: rotate(45deg);
    }
  }
  .card-client-groups-content {
    position: relative;
    height: calc(100% - 90px);
    overflow: scroll;
    padding: 0 24px;
    .border {
      position: fixed;
      top: 105px;
      left: 32px;
      height: calc(95vh - 105px);
      width: 1px;
      background-color: $color-text;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        transform: translate(-50%);
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background-color: $color-text;
      }
    }

    .client-container {
      padding: 0 20px 20px;
      p {
        margin: 0;
        font-size: 1.12rem;
        font-weight: 300;
        line-height: 1.375rem;
      }
      .icon-folder-custom {
        height: 25px;
        width: 25px;
        margin-right: 15px;
      }
    }
  }
}
