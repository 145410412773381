.popup-clients-objects-field-filter-container {
  min-width: 200px;
  padding: 5px 10px;
  position: relative;
  > .icon-container {
    position: absolute;
    top: 0;
    right: 0;
    transform: rotate(45deg);
  }

  .scroll-container {
    max-height: 200px;
    overflow: scroll;
  }
  .raised-button-container {
    margin-left: 15px;
  }

  h3 {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 700;
    margin: 15px 0;
  }
  p {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin: 10px 0;
    &.btn-clear {
      cursor: pointer;
      &:active {
        transform: scale(0.95);
      }
    }
  }
  > div:not(:last-child) {
    margin-bottom: 30px;
  }
}
