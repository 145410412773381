@import "base";
.references-erp-measures-container {
    height: 100%;
    width: 100%;
    .references-erp-measures-statistics {
        height: 105px;
    }
    .references-erp-measures-files-header {
        @include card($background: $color-cream);
        padding: 0 24px;
        height: 60px;
        margin-top: 20px;
        span {
            font-weight: 500;
            margin-right: 15px;
            &:not(:first-child) {
                margin-left: 15px;
            }
            &.totalDocs {
                margin-left: 0;
            }
        }
        .text-input-container {
            flex: none;
        }
        .inputs-datepicker-container {
            margin-right: 30px;
            button.react-datepicker__close-icon {
                transform: translate(25px);
            }
        }
        .row-exports {
            margin-left: auto;
            span {
                margin: 0 15px;
            }
            .icon.icon-export {
                height: 35px;
                width: 35px;
                border: 2px solid $color-blue-dark;
                background-color: $color-white;
                border-radius: 6px;
                background-size: 70%;
            }
        }
    }
    .references-erp-measures-files {
        height: calc(100% - 105px - 80px);
    }
    .references-erp-measures-statistics {
        justify-content: center;
    }
    .inline-loader {
        font-size: 10px;
    }
    .inline-loader:after {
        top: 0;
    }
}
